.header-top {padding:0 34px 0 24px; display:flex; align-items:center; position:relative; background-color:#1d2026;}
.responsive-logo {display:none;}
.header-top .s-main-menu {flex:0 0 70%;}
.header-top .s-main-menu ul {margin:0; padding:0; list-style:none;}
.header-top .s-main-menu li {display:inline-block;}
.header-top .s-main-menu ul li a {display:block; font-weight:500; font-size:14px; letter-spacing:-0.01em; color:#8c94a3; padding:20px 20px; position:relative; transition:all 0.3s; text-decoration:blink;}
.header-top .s-main-menu ul li a.active {color:white;}
.header-top .header-right {flex:0 0 30%; display:flex; justify-content:flex-end;}
.menu-bar {display:none; position:absolute; top:50%; right:30px; transform:translateY(-50%); cursor:pointer; display:none;}
.header-bottom {padding:24px 32px; display:flex; align-items:center;}
.header-bottom .header-bottom-left {flex:0 0 70%;}
.header-bottom-right {flex:0 0 30%; display:flex; align-items:center; justify-content:flex-end;}
.header-bottom .header-bottom-left .header-bottom-left-wrap {display:flex; align-items:center;}
.header-bottom .header-bottom-left .header-bottom-left-wrap .main-logo {margin-right:50px;}
.search-field-wrap .form-searchbox {width:424px; margin-left:16px; position:relative;}
.form-searchbox .icon {top:50%; left:20px; position:absolute; transform:translateY(-50%);}
.form-searchbox input {padding-left:55px;}
.header-cart-wrap ul {margin:0; padding:0; list-style:none;}
.header-cart-wrap ul li {margin:0 10px; display:inline-block;}
.header-cart-wrap ul li a {position:relative;}
.borwser-dropdown select.form-select {border:1px solid #e9eaf0; min-width:200px; height:48px; padding:0 18px; font-size:16px; line-height:24px; color:#4e5566; display:flex; align-items:center; margin:0;}
.header-right select {background:transparent; background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e"); background-repeat:no-repeat; background-position:right 10px top 11px; background-size:12px; color:#fff; border:navajowhite; box-shadow:navajowhite;}
.student-top-head {border:1px solid #FFDDD1; padding:40px; align-items:center; margin-bottom:40px;}
.student_info {display:flex; align-items:center; gap:24px;}
.heading-box h2 {font-family:Inter; font-size:24px; font-weight:600; line-height:32px; letter-spacing:-0.01em; text-align:left; margin-bottom:0;}
.student_content h3 {font-family:Inter; font-size:24px; font-weight:600; line-height:32px; letter-spacing:-0.01em; text-align:left; margin-bottom:14px;}
.student_content p {font-family:Inter; font-size:16px; font-weight:400; line-height:24px; text-align:left; color:#6E7485; margin:0;}
.student_image img {width:110px; height:110px;}
.student-top-head {align-items:center;}
.student-top-head a.brows-btn {padding:16px 32px; display:flex; align-items:center; width:max-content; justify-content:center;}
.student-course-list {margin-top:40px !important; margin-bottom:40px;}
.heading-box h2 {font-family:Inter; font-size:24px; font-weight:600; line-height:32px; letter-spacing:-0.01em; text-align:left; margin-bottom:0;}
.best-course-card {border:1px solid #E9EAF0;}
.course-bottom {padding:16px; border-top:1px solid #E9EAF0; display:flex; justify-content:space-around; align-items:center; position:relative;}
.course-bottom:after {content:""; display:block; background:#122d4b; height:2px; width:0%; transition:0.5s; position:absolute; left:0; bottom:0}
.student-course-list .best-course-card:hover .course-bottom:after {width:60%; transition:0.5s;}
.student-course-list .course-body p {font-family:Inter; font-size:12px; font-weight:400; line-height:16px; text-align:left; color:#6E7485; margin-bottom:6px; display:-webkit-box; -webkit-line-clamp:1; -webkit-box-orient:vertical; overflow:hidden;}
.student-course-list .course-body h4 {font-family:Inter; font-size:14px; font-weight:500; line-height:20px; letter-spacing:-0.01em; margin:0; text-align:left; display:-webkit-box; -webkit-line-clamp:1; -webkit-box-orient:vertical; overflow:hidden;}
a.watch-btn {background:#FFEEE8; display:flex; flex:1; justify-content:center; font-family:Inter; font-size:14px; font-weight:600; line-height:40px; letter-spacing:-0.004em; text-align:left; color:#122d4b; text-decoration:none; transition:0.5s;}
.student-course-list .best-course-card:hover a.watch-btn {background:#122d4b; color:#fff; transition:0.5s;}
.course-bottom span.course-complete-value {flex:1; color:#122d4b; font-family:Inter; font-size:14px; font-weight:500; line-height:20px; letter-spacing:-0.01em; text-align:right;}
.ac-cantrol {display:flex; align-items:center; justify-content:flex-end; gap:12px;}
.ac-cantrol a {background:#122d4b; width:40px; height:40px; display:flex; align-items:center; justify-content:center;}
.ac-cantrol a img {width:16px; filter:brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7451%) hue-rotate(336deg) brightness(93%) contrast(110%);}
.form-searchbox {position:relative;}
.form-searchbox img.serch-icn {width:25px;}
.filter_form select.form-select {padding:10px 15px; border-radius:0; height:48px; border:1px solid #E9EAF0; font-family:Inter; font-size:16px; font-weight:400; line-height:24px; text-align:left;}
.heading-box {display:flex; align-items:center;}
form.filter_form label {font-family:Inter; font-size:12px; font-weight:400; line-height:16px; text-align:left; margin-bottom:8px;}
.student-course-list {row-gap:24px;}
.heading-box h2 span {font-weight:400;}
.best-course-list.instructor-list {gap:initial; row-gap:24px; margin-top:40px;}
.course-footer.ins-footer {flex-wrap:wrap; row-gap:16px;}
.course-footer.ins-footer a.message-btn {width:100%; background:#FFEEE8; display:flex; justify-content:center; font-family:Inter; font-size:14px; font-weight:600; line-height:40px; letter-spacing:-0.004em; text-align:left; color:#122d4b; text-decoration:none; transition:0.5s;}
.best-course-card:hover a.message-btn {background:#122d4b; color:#fff; transition:0.5s;}
.message-area {height:100vh; overflow:hidden; padding:30px 0; background:#f5f5f5;}
.Message-head h2 {font-family:Inter; font-size:20px; font-weight:600; line-height:26px; text-align:left; margin:0;}
.chat-area {position:relative; width:100%; background-color:#fff; border-radius:0.3rem; height:90vh; gap:24px; overflow:hidden; min-height:calc(100% - 1rem); display:flex;}
.chatlist {height:100%; overflow:hidden; width:424px; float:left; border:1px solid #E9EAF0;}
.chat-area .modal-content {border:none; border-radius:0; outline:0; height:100%;}
.chat-area .modal-dialog-scrollable {height:100% !important;}
.chatbox {width:auto; overflow:hidden; flex:1; height:100%; border-left:1px solid #E9EAF0; border:1px solid #E9EAF0;}
.chatbox .modal-dialog, .chatlist .modal-dialog {max-width:100%; margin:0;}
span.chat_user_img {width:48px; height:48px;}
span.chat_user_img img {width:48px; height:48px; border-radius:100%;}
.msg-search {display:flex; align-items:center; justify-content:space-between;}
.chat-area .form-control {display:block; width:100%; padding:14px 16px; font-weight:400; line-height:1.5; color:#222; background-color:#fff; background-clip:padding-box; border:1px solid #E9EAF0; appearance:none; border-radius:0.25rem; transition:border-color .15s ease-in-out, box-shadow .15s ease-in-out; font-family:Inter; font-size:16px; font-weight:400; line-height:24px; text-align:left; color:#8C94A3;}
.chat-area .form-control:focus {outline:0; box-shadow:inherit;}
a.add img {height:36px;}
.chat-area .nav-tabs {border-bottom:1px solid #dee2e6; align-items:center; justify-content:space-between; flex-wrap:inherit;}
.chat-area .nav-tabs .nav-item {width:100%;}
.chat-area .nav-tabs .nav-link {width:100%; color:#180660; font-size:14px; font-weight:500; line-height:1.5; text-transform:capitalize; margin-top:5px; margin-bottom:-1px; background:0 0; border:1px solid transparent; border-top-left-radius:0.25rem; border-top-right-radius:0.25rem;}
.chat-area .nav-tabs .nav-item.show .nav-link, .chat-area .nav-tabs .nav-link.active {color:#222; background-color:#fff; border-color:transparent transparent #000;}
.chat-area .nav-tabs .nav-link:focus, .chat-area .nav-tabs .nav-link:hover {border-color:transparent transparent #000; isolation:isolate;}
.chat-list h3 {color:#1D2026; text-transform:capitalize; margin-bottom:0; font-family:Inter; font-size:14px; font-weight:500; line-height:20px; letter-spacing:-0.01em; text-align:left; margin-bottom:4px;}
.chat-list p {color:#6E7485; text-transform:capitalize; margin-bottom:0; font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left;}
span.chat_user_img {position:relative;}
.chat-list a.d-flex {margin-bottom:15px; position:relative; text-decoration:none;}
span.chat_user_img span.active {display:block; content:''; clear:both; position:absolute; height:12px; width:12px; border-radius:50%; border:2px solid #F5F7FA; left:auto; right:0px; bottom:-13px; background:#23BD33;}
.msg-head h3 {color:#222; font-size:18px; font-weight:600; line-height:1.5; margin-bottom:0;}
.msg-head p {color:#343434; font-size:14px; font-weight:400; line-height:1.5; text-transform:capitalize; margin-bottom:0;}
.msg-head {padding:15px; border-bottom:1px solid #E9EAF0;}
.moreoption {display:flex; align-items:center; justify-content:end;}
.moreoption .navbar {padding:0;}
.moreoption li .nav-link {color:#222; font-size:16px;}
.moreoption .dropdown-toggle::after {display:none;}
.moreoption .dropdown-menu[data-bs-popper] {top:100%; left:auto; right:0; margin-top:0.125rem;}
.msg-body ul {overflow:hidden;}
.msg-body ul li {list-style:none; margin:15px 0;}
.msg-body ul li.sender {display:block; width:100%; position:relative;}
.msg-body ul li.sender:before {display:block; clear:both; content:''; position:absolute; top:-6px; left:-7px; width:0; height:0; border-style:solid; border-width:0 12px 15px 12px; border-color:transparent transparent #FFEEE8 transparent; -webkit-transform:rotate(-37deg); -ms-transform:rotate(-37deg); transform:rotate(-37deg);}
.msg-body ul li.sender p {color:#1D2026; font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left; padding:15px; background:#FFEEE8; display:inline-block; border-bottom-left-radius:10px; border-top-right-radius:10px; border-bottom-right-radius:10px; margin-bottom:0;}
.msg-body ul li.sender p b {display:block; color:#180660; font-size:14px; line-height:1.5; font-weight:500;}
.msg-body ul li.repaly {display:block; width:100%; text-align:right; position:relative;}
.msg-body ul li.repaly:before {display:block; clear:both; content:''; position:absolute; bottom:40px; right:13px; width:0; height:0; border-style:solid; border-width:0 12px 15px 12px; border-color:transparent transparent #122D4B transparent; transform:rotate(37deg);}
.msg-body ul li.repaly p {color:#fff; font-size:14px; line-height:1.5; font-weight:400; margin-right:20px; padding:15px; float:right; background:#122D4B; display:inline-block; border-top-left-radius:10px; border-top-right-radius:10px; border-bottom-left-radius:10px; margin-bottom:0;}
.msg-body ul li.repaly p b {display:block; color:#061061; font-size:14px; line-height:1.5; font-weight:500;}
.msg-body ul li.repaly:after {display:block; content:''; clear:both;}
.time {display:block; color:#000; font-size:12px; line-height:1.5; font-weight:400;}
li.repaly .time {margin-right:20px;}
.divider {position:relative; z-index:1; text-align:center;}
.msg-body h6 {text-align:center; font-weight:normal; font-size:14px; line-height:1.5; color:#222; background:#fff; display:inline-block; padding:0 5px; margin-bottom:0;}
.divider:after {display:block; content:''; clear:both; position:absolute; top:12px; left:0; border-top:1px solid #EBEBEB; width:100%; height:100%; z-index:-1;}
.send-box form span.penicon {position:absolute; left:16px;}
.send-box {padding:24px; border-top:1px solid #E9EAF0;}
.send-box form {display:flex; position:relative; align-items:center; justify-content:space-between; margin-bottom:15px;}
.send-box .form-control {display:block; width:85%; padding:13.5px 16px 13.5px 45px; color:#8C94A3; background-color:#fff; background-clip:padding-box; border:1px solid #E9EAF0; appearance:none; border-radius:0.25rem; transition:border-color .15s ease-in-out, box-shadow .15s ease-in-out; font-family:Inter; font-size:16px; font-weight:400; line-height:24px; text-align:left;}
.send-box button {border:none; background:#122D4B; padding:13.5px 16px; color:#fff; border-radius:0; font-size:14px; font-weight:400; width:13%; margin-left:1%;}
.send-box button i {margin-right:5px;}
.send-btns .button-wrapper {position:relative; width:125px; height:auto; text-align:left; margin:0 auto; display:block; background:#F6F7FA; border-radius:3px; padding:5px 15px; float:left; margin-right:5px; margin-bottom:5px; overflow:hidden;}
.send-btns .button-wrapper span.label {position:relative; z-index:1; display:-webkit-box; display:-ms-flexbox; display:flex; -webkit-box-align:center; -ms-flex-align:center; align-items:center; width:100%; cursor:pointer; color:#343945; font-weight:400; text-transform:capitalize; font-size:13px;}
#upload {display:inline-block; position:absolute; z-index:1; width:100%; height:100%; top:0; left:0; opacity:0; cursor:pointer;}
.send-btns .attach .form-control {display:inline-block; width:120px; height:auto; padding:5px 8px; font-size:13px; font-weight:400; line-height:1.5; color:#343945; background-color:#F6F7FA; background-clip:padding-box; border:1px solid #F6F7FA; border-radius:3px; margin-bottom:5px;}
.send-btns .button-wrapper span.label img {margin-right:5px;}
.button-wrapper {position:relative; width:100px; height:100px; text-align:center; margin:0 auto;}
button:focus {outline:0;}
.add-apoint {display:inline-block; margin-left:5px;}
.add-apoint a {text-decoration:none; background:#F6F7FA; border-radius:8px; padding:8px 8px; font-size:13px; font-weight:400; line-height:1.2; color:#343945;}
.add-apoint a svg {margin-right:5px;}
.chat-icon {display:none;}
.closess i {display:none;}
.chat-list a {padding:12px 24px; margin:0 !important;}
span.active_chat_user {width:64px; height:64px;}
span.active_chat_user img {width:64px; height:64px; border-radius:100%;}
.Message-head {padding:24px 24px 16px; display:flex; justify-content:space-between; align-items:center;}
.chat-header {margin-bottom:16px; padding:0 24px;}
a.compose-btn {background:#EBEBFF; text-decoration:none; font-family:Inter; font-size:14px; font-weight:600; letter-spacing:-0.004em; text-align:left; padding:10px 16px; display:flex; gap:12px;}
.chat-list a span.time-v {float:right; font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:right; color:#6E7485;}
.chat-list a.current {background:#FFDDD1;}

@media (max-width: 767px) {
.chat-icon {display:block; margin-right:5px;}
.chatlist {width:100%;}
.chatbox {width:100%; position:absolute; left:1000px; right:0; background:#fff; transition:all 0.5s ease; border-left:none;}
.showbox {left:0 !important; transition:all 0.5s ease;}
.msg-head h3 {font-size:14px;}
.msg-head p {font-size:12px;}
.msg-head .flex-shrink-0 img {height:30px;}
.send-box button {width:28%;}
.send-box .form-control {width:70%;}
.chat-list h3 {font-size:14px;}
.chat-list p {font-size:12px;}
.msg-body ul li.sender p {font-size:13px; padding:8px; border-bottom-left-radius:6px; border-top-right-radius:6px; border-bottom-right-radius:6px;}
.msg-body ul li.repaly p {font-size:13px; padding:8px; border-top-left-radius:6px; border-top-right-radius:6px; border-bottom-left-radius:6px;}
}

.wishlist_course-image img {width:160px; height:120px; gap:0px; opacity:0px;}
.wishlist_course {display:flex; align-items:center; gap:20px;}
.cr-rating {display:flex; align-items:center; gap:5px; margin-bottom:8px;}
.cr-name a {font-family:Inter; font-size:16px; font-weight:500; line-height:22px; text-align:left;}
.author_ct-name {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left; color:#A1A5B3; padding-top:45px;}
.author_ct-name span {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; color:#4E5566; text-align:left;}
.action_btn {display:flex; gap:12px; justify-content:center;}
.wishlist_table table.table td {padding-top:24px; padding-bottom:24px;}
.action_btn a {padding:12px 16px; background:#F5F7FA; text-decoration:none; border-radius:0; font-family:Inter; font-size:16px; font-weight:600; letter-spacing:-0.008em; text-align:left; color:#1D2026; text-transform:capitalize;}
.wishlist_table table td.action-td {width:440px;}
.wishlist_table table th {font-family:Inter; font-size:14px; font-weight:500; line-height:14px; text-align:left; padding:20px 10px;}
.wishlist_table table.table {vertical-align:middle;}
a.btn.addcart-btn {background:#122d4b; color:#fff; padding:12px 56px;}
a.btn.buynow-btn {padding:12px 56px;}
.sidbar_heading {font-family:Inter; font-size:18px; font-weight:500; line-height:24px; text-align:left; padding:24px 24px 16px;}
.student_profile {border:1px solid #E9EAF0; padding:44px;}
.image_upload-block {position:relative;}
.image_upload-block img {display:block; width:100%;}
.image_upload-block a.upload_btns {position:absolute; bottom:0; display:flex; align-items:center; justify-content:center; width:100%; gap:8px; background:#00000080; padding:14px; color:#fff; text-decoration:none; font-family:Inter; font-size:14px; font-weight:500; line-height:20px; letter-spacing:-0.01em; text-align:left;}
.image_upload-block a.upload_btns img {width:20px;}
.account_setting.profile-change-block.row {border-bottom:1px solid #E9EAF0; padding-bottom:40px;}
.upload-size-info {margin-top:22px; text-align:center; font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:center;}
.form_wrap {padding-left:60px;}
.submit_input {padding-top:20px;}
.profile_form label {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left; margin-bottom:6px;}
.profile_form input.form-control {border:1px solid #E9EAF0; border-radius:0; padding-left:18px; font-family:Inter; font-size:16px; font-weight:400; line-height:24px; text-align:left; height:48px;}
.profile_form .row>* {margin:0;}
.profile_form .row.g-3 {margin-bottom:26px;}
span.word_counter {font-family:Inter; font-size:16px; font-weight:400; line-height:24px; text-align:left; position:absolute; right:20px; bottom:10px;}
.password-block {position:relative;}
.password-block span.fa.fa-fw {position:absolute; right:23px; bottom:16px; font-size:20px;}
.title_input {position:relative;}
.profile_form input.submit_btns {padding:0 32px; display:flex; align-items:center; color:#fff; width:max-content; background:#122d4b; height:48px; justify-content:center;}