@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
*, ::after, ::before {
  box-sizing: border-box;
}

a, abbr, acronym, address, applet, big, blockquote, body, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, html, iframe, img, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, tt, ul, var {
  border: 0;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: none !important;
  padding: 0;
  vertical-align: baseline;
  font-family: 'Inter', sans-serif;
  }
  @import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;500;600;700;800&family=Corben&family=Elsie&family=Encode+Sans+Condensed:wght@400;500;600;700;800&family=Gloria+Hallelujah&family=Inter:wght@100;200;300;400;500;700;800;900&family=Kaushan+Script&family=Lobster&family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:wght@200;300;400;500;600;700&family=Nunito:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600&family=Quicksand:wght@400;500;600;700&family=Rajdhani:wght@300;400;500;600;700&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&family=Saira+Condensed:wght@300;400;500;600;700&family=Yeseva+One&display=swap');
  body{
   
  }
  .btn:focus{
    box-shadow: none !important;
  }
  img{
    padding: 0;
    margin: 0;
  }
  .form-control:focus{
    box-shadow: none !important;
  }
  .sidebar-menu{
    background: #fff;
  }
  #navAccordion {
    left: 0;
    transition: all .4s ease 0s;
  }
  #navAccordion.active {
    left: 0;
    width: 81px;
  }
  #navAccordion.active .logo-admin .big-logo{
    display: none;
  }
  #navAccordion.active .logo-admin .closap-logo{
    display: block;
  }
  #navAccordion.active .menu-text{
    display: none;
  }
  #navAccordion.active .menu-icon-new{
    margin: auto;
    justify-content: center;
  }
  .container-fluid{
    padding: 0 !important;
  }
  .w-100 {
    width: 97%!important;
  }
  .tog-btn{
    font-size: 18px;
    color: #000;
    cursor: pointer;
    transition: all 0.5s;
    display: grid;
    border-left: 1px solid #000000;
      font-size: 22px;
      padding: 0 15px;
  }
  .tog-btn:hover{
    color: #d10202;
  }
  .content-wrapper {
    padding-left: 230px;
    transition: all .4s ease 0s;
    min-height: 100vh;
    width: 100%;
    display: inline-block;
    background: #f2f2f2;
    overflow: hidden;
  }
  .right-menu-sec{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  #tabs1-sel-modal,
  #tabs2-sel-modal,
  #tabs3-sel-modal,
  #tabs4-sel-modal,
  #tabs5-sel-modal,
  #tabs6-sel-modal,
  #tabs7-sel-modal{
    display: none;
  }
  .special-offcanvas{
    z-index: 9999999999 !important;
  }
  .new-pos-1{
    top:0 !important;
    margin-bottom: 20px;
  }
  .new-pos-2{
    top:-52px !important;
  }
  .serch-sec-div .form-control{
    font-size: 14px;
  }
  .logo-admin {
    /* background: rgb(254,0,0); */
    /* background: linear-gradient(to right, rgb(243 237 237) 0%, rgb(239 235 235) 100%); */
    /* background: -webkit-linear-gradient(left,  rgba(254,0,0,1) 0%,rgba(209,2,2,1) 100%); */
    /* background: linear-gradient(to right,  rgba(254,0,0,1) 0%,rgba(209,2,2,1) 100%); */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fe0000', endColorstr='#d10202',GradientType=1 );
    width: 100%;
    display: flex;
    padding: 7px 0;
    text-align: center;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 63px;
  }
  .top-bar-menu{
    background: #fff;
  }
  #logindetails{
    display: none;
  }
  .tg-new-btn{
    width:41px;
    height: 20px;
    background: #E6E6E6;
    border-radius: 20px;
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .new-ds{
    background-color: #f8f8f8 !important;
  }
  .activeintro{
    background:#22C55E ;
  }
  .booking-btn,
  .register-btn-lb{
    color: #EA5455 !important;
    border: solid 1px #EA5455 !important;
  }
  .new-viw1{
    background: #F59E0B !important;
    color: #fff !important;
    font-size: 14px !important;
    padding: 3px !important;
    width: 62px ;
  }
  .new-book1{
    background: #22C55E !important;
    color: #fff !important;
    font-size: 14px !important;
    padding: 3px !important;
    width: 62px ;
  }
  .td .form-control{
    width: 83%;
    height: 30px;
    font-size: 16px;
    margin: auto;
  }
  #lab-register-div{
    display: none;
  }
  .registerd-2 h2{
    font-size: 25px;
    color: #000;
      font-family: 'Nunito', sans-serif;
      font-weight: 800;
      margin-bottom: 5px;
  }
  .registerd-2 p{
    font-size: 15px;
  }
  .v-btn{
    border: solid 1px #CBC9C9 !important;
    font-size: 14px !important;
    color: #333333 !important;
    font-family: 'Nunito', sans-serif;
    font-weight: 700 !important;
  }
  .sp-width-in{
    display: inline-block;
    width:40px;
  }
  .add-content-div{
    background: #FAFAFA;
    padding: 28px;
    display: table;
    width: 85%;
    margin: auto;
  }
  .add-content-div label{
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
  }
  .add-content-div h5{
    font-size: 16px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
  }
  .add-content-div .submit-btn{
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: 'Nunito', sans-serif;
    width: 100px;
  }
  #lab-documents-div{
    display: none;
  }
  .comon-file{
    background: #FBFBFB;
    border-radius: 10px;
    height: 188px;
    width: 168px;
    display: grid;
    align-content: center;
    justify-content: center;
  }
  .cm-links{
    color: #EA5455;
    cursor: pointer;
  }
  #backlab-btn-2{
    cursor: pointer;
  }
  .total-tabs-inventory{
    padding:30px;
    display: inline-block;
    width: 100%;
  }
  .paidtext{
    background: #22C55E;
    color: #fff;
    border-radius: 5px;
    width:60px;
    height: 24px;
    display:inline-block;
    text-align: center;
    font-size: 15px;
    line-height: 24px; 
    align-content: center;
    justify-content: center;
  }
  .new-btn-on{
    border: none;
    background: #EA5455 !important;
    color: #fff !important;
    margin: auto !important;
    display: table !important;
    height:44px !important;
  }
  .total-tabs-inventory .nav-tabs li:first-child.nav-item{
    margin-left: 0;
  }
  .total-tabs-inventory .nav-tabs .nav-item{
    margin: 0 10px;
  }
  .total-tabs-inventory .nav-tabs .nav-link{
    color: #4E4E4E !important;
    border-top: 0 !important;
    border-left: 0 !important;
    padding: 0 0 5px 0 !important;
    border-right: 0 !important;
  }
  .sp-back-btn{
    position: absolute;
    left: 0;
    text-decoration: none;
    color: #000;
  }
  #tabs2,
  #tabs3,
  #tabs4,
  #tabs5,
  #tabs6,
  #tabs7,
  #tabs8{
    display: none;
  }
  .file-img2{
    cursor: pointer;
  }
  .btn-close:focus{
    box-shadow: none !important;
  }
  #tes-documents{
    display: none;
  }
  .total-tabs-inventory .nav-tabs .nav-item.show .nav-link, 
  .total-tabs-inventory .nav-tabs .nav-link.active,
  .total-tabs-inventory .nav-tabs .nav-link:hover{
    color: #EA5455 !important;
    border-bottom: solid 2px #EA5455 !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .cld-icon{
    cursor: pointer;
  }
  .top-resister-1 label{
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .top-resister-1 .form-group{
    margin-bottom: 25px;
  }
  .lab-register-week h2 {
    color: #000;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 25px;
  }
  #cancel-admin-div{
    display: none;
  }
  .aro-btn{
    color: #000;
    cursor: pointer;
  }
  .activecheck{
    display: block !important;
  }
  .show-date-details{
    position: relative;
  }
  .ds-calender{
    position: relative;
  }
  #backlab-btn{
    cursor: pointer;
  }
  .off-green{
    background: none !important;
  }
  #div6{
    display: none;
  }
  #tabs1-test,
  #tabs2-test,
  #tabs3-test,
  #tabs4-test,
  #tabs5-test,
  #tabs6-test,
  #tabs7-test,
  #tabs8-test,
  #tabs9-test,
  #tabs10-test{
    display: none;
  }
  .bnt-new{
    cursor: pointer;
    color: #000 !important;
    text-decoration: none;
  }
  .comon-multi-btn{
    position: relative;
    display: inline-block;
  }
  .padin-bnt1{
    background: #22C55E !important;
    color: #fff !important;
    height: 27px;
    font-size: 13px !important;
    margin-bottom: 0 !important;
    padding-top: 4px !important;
    width: 80px;
  }
  .unpdt1{
    background: #F59E0B !important;
    color: #fff !important;
    height: 27px;
    font-size: 13px !important;
    margin-bottom: 0 !important;
    padding-top: 4px !important;
    width: 80px;
    
  }
  .comon-multi-btn{
    cursor: pointer;
  }
  .nw-line-hight{
    line-height: 27px !important;
  }
  #tabs-book1,
  #tabs-book2,
  #tabs-book3,
  #tabs-book4,
  #tabs-book5,
  #tabs-book6,
  #tabs-book7,
  #tabs-book8{
    display: none;
  }
  .show-date-details .e-input-group-icon{
    position: absolute !important;
    left: 0;
    right: 0;
    top:0;
    width: 100%;
  }
  .show-date-details .e-input-group {
    position: absolute !important;
    left: 0;
    right: 0;
    top:0;
    width: 100%;
    opacity: 0;
  }
  .e-daterangepicker{
    left: 315px !important;
  
  }
  .cm-t{
    font-size: 12px;
    display: none;
  }
  #new1{
    display: none;
  }
  #new2,
  #new3,
  #new4,
  #new5,
  #new6{
    display: none;
  }
  #tabs1{
    display: none;
  }
  #divn1{
  
    display: none;
  }
  #divn2{
  
    display: none;
  }
  #divn3{
  
    display: none;
  }
  #divn4{
  
    display: none;
  }
  #divn5{
  
    display: none;
  }
  #divn6{
  
    display: none;
  }
  .showSingle2{
    color: #000;
    float: right;
      position: relative;
      top: -19px;
      cursor: pointer;
  }
  .targetDiv2{
    display: none;
  }
  .sp-bookin-top > div:last-child .comon-user-ds{
    background: #FBD492 !important;
  }
  .sp-bookin-top > div:last-child .comon-user-ds:after{
   background: #f7cc85 !important;
  }
  .para-wd{
    text-align: left !important;
    width: 321px;
    padding-right: 25px !important;
    margin-left: auto;
  }
  .book-td{
    background: #22C55E !important;
    font-size: 13px !important;
    height: 30px ;
    margin-top: 3px;
    color: #fff !important;
  }
  .cancel-td{
    background: #EA5455 !important;
    font-size: 13px !important;
    height: 30px ;
    margin-top: 3px;
    color: #fff !important;
  }
  /* .activeintro:after{
    right: inherit;
    left: 5px;
  } */
  .cm-labelre label{
    font-weight: 600;
  }
  #tueday,
  #wedday,
  #thuday,
  #friday,
  #satday,
  #sunday{
    display: none;
  }
  .add-hrs-btn{
    color: #FE0000 !important;
    display: flex !important;
    align-items: center;
    margin-top: 11px;
  }
  .filter-modal{
    width: 480px !important;
  }
  .comon-fild label{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .filter-div-body{
    position: relative;
  }
  .filter-modal .offcanvas-body{
    padding-top: 0;
    padding-bottom: 0;
  }
  .filter-div-body:after{
    position: absolute;
    width: 1px;
    height: 100vh;
    background: #D2D2D2;
    top:0;
    left: 0;
    right: 0;
    content: "";
    margin: auto;
  }
  .comon-fild .form-control{
    height: 35px;
  }
  .filter-modal .offcanvas-header .btn-close{
    float:right;
    width: 10px;
  }
  .op-text{
    color: #FE0000;
    font-size: 16px;
    font-weight: 400;
    margin-left: 12px;
  }
  .claer-all-btn{
    color: #FE0000;
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
  }
  .filter-checks label{
    font-size: 15px;
    font-weight: 400;
  }
  .filter-checks .btn-close{
    position: absolute;
      top: 7px;
      background-image: none;
      right: 0;
      font-size: 15px;
  }
  .new-bg2 .tbl-re{
    background: #f8f8f8 !important;
    width: 100%;
    
  }
  .new-bg2 .tbl-re-dl{
    background: #fff !important;
    width: 100% !important;
  }
  .filter-checks  .form-check{
    margin-bottom: 15px;
  }
  .add-hrs-btn span{
    width: 25px;
    height: 25px;
    display: grid;
    justify-content: center;
    align-content: center;
    border: solid 1px #FE0000;
    border-radius: 50%;
    overflow: hidden;
    color: #FE0000;
    margin-right: 5px !important;
  }
  .tg-new-btn:after{
    position: absolute;
    width: 10px;
    content: "";
    right: 5px;
    top:5px;
    height: 10px;
    border-radius: 50%;
    overflow: hidden;
    background: #fff;
  }
  .top-bar-menu{
    padding: 0 15px 0 28px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;padding-left: 354px;
      right: 0;
      width: 100%;
      z-index:94;
      transition: all 0.5s;
  }
  .right-menu-sec ul{
    justify-content: space-between;
    align-items: center;    gap: 15px;
  }
  .right-menu-sec .nav-link{
     padding: 0;
  }
  .right-menu-sec ul li{
    margin: 0 8px;
  }
  .right-menu-sec ul li a{
    color: #000;
    font-size: 22px;
    position: relative;
  }
  .right-menu-sec ul li:nth-child(2) a{
    color: #FE0000;
    font-size: 25px;
  }
  .cart-dropdown-ne,
  .account-div {
    width: 310px;
    right: 0;
    left: inherit !important;
    border: none;
    visibility: hidden;
    top: 120%;
    display: block !important;
  }
  .cart-dropdown-ne.show,
  .account-div.show {
    visibility: visible;
    top: 100%;
  }
  .account-div.show{
    top:40% !important;
  }
  .account-div{
    width: 230px !important;
    border: none !important;
  }
  .account-div:before {
    position: absolute;
    top: -10px;
    width: 10px;
    right: 10px;
    height: 10px;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    border-bottom: solid 10px #fff;
    content: '';
  }
  .sp-addre .btn-remove-customer img{
    margin-top: 0;
    margin-left: 3px;
  }
  .right-menu-sec ul li:nth-child(2) .nav-link:after{
    position: absolute;
    right: 2px;
    top: 10px;
    font-size: 10px;
    width: 8px;
    height: 8px;
    background: #FE0000;
    overflow: hidden;
    border-radius: 50%;
    content: "";
  }
  .account-div .dropdown-item,
  .account-div li:nth-child(2) a.dropdown-item{
    font-size: 16px;
    color: #000;
    font-weight: 600;
  }
  .account-div .dropdown-item{
    border-bottom: solid 1px #dadada;
    padding-bottom: 8px;
  }
  .account-div li:last-child .dropdown-item{
    border-bottom: none;
  }
  .account-div .dropdown-item span{
    color: #383835 !important;
    font-weight: 400;
  }
  .account-div .dropdown-item:hover{
    background-color: inherit !important;
    color: #FE0000 !important;
  }.user-div-top figure {
    display: flex;
  }.user-div-top figure img {
    width: 38px;
    height: 39px;
  }
  .user-div-top h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.36px;
    text-align: center;
    font-family: "Kumbh Sans", sans-serif;
    margin: 0;
    position: relative;
    padding-right: 20px;
  }
  .user-div-top h6:after {
    border: solid #525050;
    border-width: 0px 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    content: "";
    position: absolute;
    right: 2px;
    top: 2px;
  }
  .user-div-top h6 span{
    color: #8F8F8F;
  }
  .main-menu  ul li a {
    padding: 12px 0 12px 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #676767;
    font-size: 16px;
    /* font-weight: 600; */
    transition: all 0.5s;
    margin-bottom: 1px;
    line-height: 32px;
    border-radius: 15px;
  }
  .main-menu  ul li:last-child{
    margin-bottom: 12px;
  }
  .main-menu  ul li a span.menu-text {
    font-family: Kumbh Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
  }
  
  .main-menu  ul li a .menu-icon-new{
    margin-right:12px;
    display: grid;
    width: 31px;
    
  }/*
  .field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -1px;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }*/
  .form-sec-login .lable-input {
    position: relative;
  }
  p.text-center.login-sign-text.loginbottomtxt {
    margin-top: 30px;
    font-family: Inter;
    font-size: 18px;position: relative;
    font-weight: 400;
    line-height: 21.78px;
    text-align: left;    margin-bottom: 24px;
  }
  p.text-center.login-sign-text.loginbottomtxt span {
    background: #fff;
    position: relative;
    z-index: 1;
    width: 40%;
    display: block;
    margin: auto;
  }
  .main-menu ul li a.active, .main-menu  ul li a:hover {
    background: #ffffff;
  }
  
  .notification-div .icon-nt{
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    display: grid;
    align-content: center;
    justify-content: center;
    color: #fff;
    background: #FE0000;
  }
  .notification-div h5{
     font-size: 16px;
  }
  .notification-div h5 span{
    margin-top: 5px;
  }
  .notification-div li{
    margin-bottom: 15px !important;
    display: inline-block;
    width: 100%;
  }
  .clear-bnt{
    color: #EA5455;
    text-decoration: none;
    font-size: 16px !important;
  }
  .notification-div li > div{
    align-items: center;
    border-bottom:solid 1px #f0f0f0;
    padding-bottom: 10px;
  }
  .notification-div .icon-nt svg{
    font-size: 22px;
  }
  .notificaion-dp h4{
    font-size: 20px;
    font-weight: 600;
  }
  .notificaion-dp{
    border: none !important;
    
  }
  .notificaion-dp hr{
    background-color: #bbb;
  }
  .notification-div li > div:last-child{
    border-bottom: none;
    padding-bottom: 0;
  }
  #payment-notification1{
    display: none;
  }
  #issue-notification1{
    display: none;
  }
  .notic-btn-cm{
    text-decoration: none;
    cursor: pointer;
  }
  .notic-btn-cm h4,
  .notic-btn-cm h4 span{
    color: #000;
  }
  .right-menu-sec .allnoti-btn{
    font-size: 14px !important;
    font-weight: 600;
    transition: all 0.5s;
    color: #1D1C1C !important;
  }
  .right-menu-sec .allnoti-btn:hover{
    color: #000 !important;
  }
  .dasb-div{
    display: inline-block;
  }
  .booking-chart,
  .revenu-div{
    display: inline-block;
    width: 100%;
    background: #fff;
    border: solid 1px #D2D2D2;
    border-radius: 8px;
    height: 431px;
  }
  .comon-chart-globe,
  .map-div-main{
    display: inline-block;
    width: 100%;
    background: #fff;
    border: solid 1px #D2D2D2;
    border-radius: 8px;
    height: 433px;
    position: relative;
    overflow: hidden;
  }
  .total-content-div{
    display: inline-block;
    width: 100%;
    background: #fff;
    border: solid 1px #D2D2D2;
    border-radius: 8px;
    height: 533px;
    position: relative;
  }
  .new-dit-add{
    height: 500px;
  }
  .booking-chart .recharts-wrapper,
  .revenu-div .recharts-wrapper,
  .comon-chart-globe .recharts-wrapper,
  .total-content-div .recharts-wrapper,
  .anal-div-md .recharts-wrapper{
    width: 100% !important;
  }
  .total-content-div .btn-secondary{
    background: #F5F5F5;
    border: none;
    font-weight: 600;
    color: #000;
    border-radius: 50px;
    width: 152px;
  }
  .comon-chart-globe .recharts-legend-wrapper{
    margin: auto;
    left: 0;
    right: 0;
  }
  .dasb-div .row{
  
  }
  .dasb-div{
    padding-left: 10px;
    padding-top: 62px;
    padding-right:10px;
  }/*
  .full-width .top-bar-menu{
    width: 94%;
  }*/
  .cm-heading{
    font-size: 18px;
    font-weight: 600;
    position: relative;
  }
  
  .dasb-div .nav-pills .nav-link{
    font-weight: 600;
    color: #4B4B4B;
    font-size: 14px;
    border: solid 1px #fff;
    border-radius: 50px;
  }
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
    border: solid 1px #EA5455;
    color: #EA5455 !important;
    background: none !important;
  }
  .recharts-surface{
    width:100% !important ;
  }
  .number-rev .dl-icon{
    background: #22C55E;
    width: 50px;
    height: 50px;
    display: grid;
    justify-content: center;
    align-content: center;
    font-size: 30px;
    color: #fff;
    border-radius: 50%;
    overflow: hidden;
    margin:0 8px 0 0;
  }
  .number-rev h3{
    color: #22C55E;
    font-weight: 800;
    font-size: 38px;
    margin-bottom: 0;
  }
  .map-sec-div iframe{
    height: 260px;
  }
  .ds-sec h5{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  .ds-sec h5 span{
    font-weight: 600;
    font-size: 22px;
  }
  .view-map-btn{
    background-color: #ea5455 !important;
    color: #fff !important;
    border-radius: 20px !important;
    padding: 8px 22px !important;
    transition: all 0.5s;
  }
  .view-map-btn:hover{
    background-color: #000 !important;
  }
  .show-date-details{
    background: #fff;
    border-radius:10px ;
    height:72px;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
  }
  .show-date-details .ds-calender{
    color: #EA5455 !important;
    border: solid 1px #EA5455 !important;
    margin: 0;
    display: flex;
    align-items: self-end;
  
  }
  .calender-modal .e-calendar{
    max-width: 100%;
  }
  .show-date-details .ds-calender span{
    position: relative;
    display: grid;
    margin-right: 5px;
  }/*
  .comon-overview{
    background: #fff;
    border-radius: 10px;
    height: 130px;
    display: grid;
    align-content: center;
    padding: 0 15px;
    position: relative;
  }*/
  .dasb-div .comon-overview:hover{
    box-shadow: 0px 4px 4px 0px #79797940!important;
    transition: 0.5s;
  
  }.chart-graph-line {
    margin-top: 6px;
  }
  .dasb-div .comon-overview {
    background: #fff;
    height: 153px;
    display: grid;
    align-content: center;
    position: relative;
    border: 0.5px solid #E6E6E6;
    box-shadow: none !important;
    padding: 20px 26px 20px 26px;
    gap: 7px;
    border-radius: 16px;
    position: relative;
    transition: 0.5s;
  }
  .sub-text{
    color: #353535;
    font-size: 14px;
    margin-top: 18px;
  }
  .comon-overview p{
    font-size: 16px;
    font-weight: 400;
    color: #333;
  }
  .icon-f{
    width: 53px;
    height: 53px;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: #FBFBFB;
    margin-left: -3px;
  }
  .date-tx{
    width:65%;
  }
  .list-count{
    width: 35%;
    display: grid;
    justify-content: flex-end;
  }
  .line-bar{
    width: 100%;
    margin: auto;
    height: 11px;
    display: inline-block;
    background: #C4C4C4;
    margin-top: 30px;
    position: relative;
  }
  .line-bar:after{
    position: absolute;
    left: 0;
    width: 45%;
    top:0;
    content: "";
    background-color: #d57c09;
    height: 11px;
    display: inline-block;
  }
  .md-overview .line-bar:after{
    background-color: #7a64c6;
  }
  .lst-overview .line-bar:after{
    background-color: #02b596;
  }
  .dasb-div .comon-overview h5 {
    font-family: Kumbh Sans;
    font-size: 16px;
    font-weight: 500;    margin: 0;
    line-height: 22.75px;
    text-align: left;
  }.progess_circle {
    position: relative;
    top: 30px;
  }
  .list-count li{
    font-size: 14px;
  }
  .date-tx h5 span{
     font-weight: 400;
     font-size: 13px;
     color: #333333;
  }
  .tab-table{
    background: #fff;
    border: solid 1px #D2D2D2;
    border-radius: 8px;
    display: inline-block;
    width: 100%;
  }
  .download-btn,
  .downlaod-file-btn{
    background-color: #ffdada !important;
    color: #E35051 !important;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 15px !important;
    border-radius: 30px !important;
    transition: all 0.5s;
    transform: scale(1);
  }
  .download-btn:hover,
  .edit-btn:hover,
  .filter-btn:hover,
  .downlaod-file-btn:hover{
    transform:scale(1.1);
  }
  .edit-btn{
    background-color: #e8fff3 !important;
    color: #0A6934 !important;
    font-size: 14px !important;
    font-weight: 600;
    padding: 5px 15px !important;
    border-radius: 30px !important;
    transition: all 0.5s;
    transform: scale(1);
    display: flex !important;
      justify-content: center;
      align-items: center;
  }
  .edit-btn span{
    display: grid;
    margin-right: 5px;
  }
  .gp-button button{
    margin: 0 8px;
    
      height: 37px;
  }
  .gp-button .download-btn{
    width: 133px;
    font-size: 15px;
  }
  .edit-btn{
    width: 91px;
    font-size: 14px;
  }
  
  .filter-btn{
    background-color: #f5f5f5 !important;
    color: #000 !important;
    font-size: 14px;
    font-weight: 600;
    width: 133px;
    border-radius: 30px !important;
    transition: all 0.5s;
    transform: scale(1);
  }
  .st-text-s{
    font-size: 14px;
    font-weight: 400;
    background-color: #02B596 !important;
    color: #fff !important;
    border-radius: 5px;
    display: grid;
    width:104px;
    height: 27px;
    margin: auto;
    text-align: center;
    align-content: center;
  }
  .st-text-c{
    font-size: 14px;
    font-weight: 400;
    background-color: #f59e0b !important;
    color: #fff !important;
    border-radius:5px;
    display: grid;
    width: 104px;
    height: 27px;
    margin: auto;
    align-content: center;
    text-align: center;
  }
  .st-text-cn{
    font-size: 14px;
    font-weight: 400;
    background-color: #EA5455 !important;
    color: #fff !important;
    border-radius:5px;
    display: grid;
    width: 104px;
    height: 27px;
    margin: auto;
    align-content: center;
    text-align: center;
  }
  .st-text-st{
    font-size: 14px;
    font-weight: 400;
    background-color: #02B596 !important;
    color: #fff !important;
    border-radius:5px;
    display: grid;
    width: 104px;
    height: 27px;
    margin: auto;
    align-content: center;
    text-align: center;
  }
  .st-text-st1{
    font-size: 14px;
    font-weight: 400;
    background-color: #22C55E !important;
    color: #fff !important;
    border-radius:5px;
    display: grid;
    width: 104px;
    height: 27px;
    margin: auto;
    align-content: center;
    text-align: center;
  }
  .p-btn{
    border: solid 1px #0A6934 !important;
    background-color: #E3F8EC !important;
    color: #0A6934 !important;
    font-size: 12px !important;
    width: 107px;
    text-align: left !important;
    position: relative;
  }
  .p-btn:after,
  .unp-btn:after{
    position: absolute;
    right: 5px;
    top: 13px;
  }
  .unp-btn{
    border: solid 1px #F59E0B !important;
    background-color: #F9EAD2 !important;
    color: #F59E0B !important;
    font-size: 12px !important;
    width: 107px;
    text-align: left !important;
    position: relative;
  }
  .pack-btn{
    border: solid 1px #EA5455 !important;
    color: #EA5455 !important;
  }
  .amount-text{
    border-left: solid 1px #C4C4C4;
    padding-left: 10px;
    margin-left: 10px;
    color: #5F5F5F;
    font-size: 16px;
  }
  .add-more-bntn{
    float: right;
      margin-left: auto;
      display: table;
      position: relative;
      top: -73px;
      color: #EA5455 !important;
      z-index: 3;
  }
  .top-graph-1{
    position: relative;
    top:0;
    left: 15px;
    font-size: 13px;
    color: #000;
  }
  .top-graph-1 .crl-div{
    width: 15px;
    height: 15px;
    background: #7A64C6;
  }
  .crl-div2{
    width: 15px;
    height: 15px;
    background: #D57C09;
  }
  .new-number-add{
    background: #F5F5F5;
    padding:5px 10px;
    display: inline-block;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
  }
  .new-size-1 .recharts-legend-item-text{
    font-size: 13px !important;
  }
  .amount-text big{
    font-weight: 700;
    color: #000;
    font-size: 18px;
    margin-left: 5px;
  }
  .sp-new-filter{
    width: 40px;
    height: 40px;
    border: solid 1px #C3C3C3 !important;
    background: none !important;
    display: grid !important;
    align-content: center;
    padding: 0 !important;
  }
  .st-text-sb{
    font-size: 14px;
    font-weight: 400;
    background-color: #649F19 !important;
    color: #fff !important;
    border-radius:5px;
    display: grid;
    
    margin: auto;
    text-align: center;
    width: 104px;
    height: 27px;
    align-content: center;
  }
  .st-text-cl{
    font-size: 14px;
    font-weight: 400;
    background-color: #EA5455!important;
    color: #fff !important;
    border-radius: 5px;
    display: grid;
    padding: 5px 10px;
    width: 104px;
    height: 27px;
    align-content: center;
    margin: auto;
    text-align: center;
    align-content: center;
  }
  .track-btn{
    background-color: #000 !important;
    color: #fff !important;
    font-size: 14px !important;
    width: 54px;
    height: 27px;
    display: grid;
    align-content: center;
    border: none;
    border-radius: 5px;
    line-height: 15px;
    margin: auto;
    justify-content: center;
  }
  .view1-btn{
    border:solid 1px #3A78F2 !important;
    color: #3A78F2 !important;
    font-size: 14px !important;
    width: 54px;
    height: 27px;
    display: grid;
    align-content: center;
    border-radius: 5px;
    line-height: 15px;
    background: #fff;
  }
  .table-sec-div .tbody, .table-sec-div td, .table-sec-div tfoot, .table-sec-div th, .table-sec-divthead, 
  .table-sec-div tr{
    text-align: center;
    vertical-align: middle;
  }
  .bg-row{
    background-color: #f8f8f8;
    border-bottom: none !important;
  }
  .table{
    border: none;
  }
  .table > :not(:first-child),
  .table > :not(caption) > * > *{
    border: none !important;
  }
  .table tr{
    border-bottom: solid 1px rgb(233, 233, 233);
  }
  .table tr:last-child{
    border-bottom: none;
  }
  .bar-icon1{
    cursor: pointer;
    color: #fff;
    font-size: 18px;
  }
  .tab-table td{
    font-size: 14px;
  }
  .tab-table th{
    font-size: 14px;
  }
  .nw-bbok-btn{
    width: 143px;
    height: 44px;
    background: #EA5455;
    border-radius: 50px;
    display: inline-block;
    align-content: center;
    line-height: 45px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    transition: all 0.5s;
  }
  .nw-bbok-btn:hover{
    background: #000;
    color: #fff;
  }
  .st-btn{
    width: 143px;
    height: 44px;
    background: #000;
    border-radius: 50px;
    display: inline-block;
    align-content: center;
    line-height: 45px;
    text-align: center;
    text-decoration: none;
    color: #fff !important;
    cursor: pointer;
    transition: all 0.5s;
  }
  .st-btn:hover{
    background: #EA5455;
  }
  .setting-md-div{
     border: solid 1px #E7E7E7;
  }
  .comon-div-sec-details h4{
    color: #EA5455;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
  .comon-div-sec-details .form-check-input:checked{
    background-color:#EA5455;
    color: #EA5455;
    border-color: #EA5455;
  }
  .comon-div-sec-details h5{
    font-weight: 600;
    color: #333333;
    font-size: 16px;
  }
  .comon-div-sec-details ul li{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .tl{
    width: 198px;
    display: inline-block;
  }
  .nadme-list{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
  }
  .cm-name{
    padding: 0 15px;
    height: 32px;
    border-radius: 50px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    background: #f8f8f8;
    font-size: 15px;
    margin-bottom: 10px;
    margin-right: 6px;
  }
  .bn-paid{
    width: 64px;
    height: 27px;
    background: #22C55E;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 25px;
    border-radius: 5px;
    font-size: 15px;
  }
  
  
  .ps-status-div{
    border: solid 1px #C2C2C2;
    border-radius: 8px;
    box-shadow: 0px 0px 50px 0px rgb(0 0 0 / 10%);
    border-radius: 15px;
    height: 122px;
    display: grid;
    align-content: center;
    padding-top: 6px;
    box-sizing: border-box;
  }
  .comon-status{
    text-align: center;
    position: relative;
    height: 100px;
    display: grid;
  }
  .comon-status:after{
    position: absolute;
    content: "";
    width: 87px;
    height: 1px;
    background: #454845;
    right: -57px;
    top: 25%;
  }
  .iocn-cm{
    width: 46px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
    background: #FE0000;
    display: grid;
    align-content: center;
    justify-content: center;
    margin: auto;
  }
  .comon-status h5{
    font-size: 16px;
    font-weight: 600;
    margin-top: 9px;
  }
  .cm-disable .iocn-cm{
    background: #F4F4F4;
  }
  .cm-disable h5{
    color: #A0A0A0;
  }
  .ps-status-div .row > div:last-child .comon-status:after{
    display: none;
  }
  .cm-disable::after{
    background: #D8E0D7;
  }
  
  .form-wizard .wizard-fieldset {
    display: none;
  }
  .form-wizard .wizard-fieldset.show {
    display: block;
  }
  .form-wizard-header{
    background: #FCFCFC;
    border-radius: 10px 10px 0 0;
    height: 119px;
    display: grid;
    align-content: center;
  }
  .form-wizard-header ul{
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-wizard .form-wizard-steps li {
    width: 20%;
    position: relative;
    text-align: center;
    display: table;
  }
  
  .form-wizard-header ul h6{
    margin-bottom: 0;
  }
  .form-wizard .form-wizard-steps li .cm-check-icons {
    background-color: #dddddd;
    border-radius: 50%;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    position: relative;
    text-align: center;
    width: 40px;
    z-index: 1;
    margin-bottom: 8px;
  }
  .form-wizard .form-wizard-steps li.active .cm-check-icons, .form-wizard .form-wizard-steps li.activated .cm-check-icons {
    background-color: #22C55E;
    color: #ffffff;
  }
  
  .form-wizard .form-wizard-steps li::after {
    background-color: #D8D8D8;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top:22%;
    transform: translateY(-50%);
    width: 100%;
  }
  .form-wizard .form-wizard-steps li:last-child::after {
    left: 0;
  }
  .form-wizard .form-wizard-steps li:last-child::after {
    width: 50%;
  }
  .form-wizard .form-wizard-steps li:last-child.active::after{
    display: none;
  }
  .inside-comon-div{
    background: #fff;
    padding-top: 30px;
    display: inline-block;
    width: 100%;
    padding-left: 110px;
    padding-right: 110px;
    padding-bottom: 50px;
  }
  .test-inside-div .form-check{
     width: 95px;
  }
  
  .inside-comon-div hr{
    background-color: #C4C4C4;
    margin-top: 5px;
  }
  
  .form-wizard .form-wizard-steps li.active::after, .form-wizard .form-wizard-steps li.activated::after {
    background-color: #22C55E;
    left: 50%;
    width: 50%;
    border-color: #22C55E;
  }
  .form-wizard .form-wizard-steps li.activated::after {
    width: 100%;
    border-color: #22C55E;
  }
  .form-wizard .form-wizard-steps li .cm-check-icons{
    color: #dddddd;
  }
  .form-wizard .form-wizard-steps li .sub-steps{
    color: #979797;
  }
  .form-wizard .form-wizard-steps li.active .sub-steps,
  .form-wizard .form-wizard-steps li.activated .sub-steps{
    color: #000;
  }
  .wizard-fieldset .from-group{
    position: relative;
  }
  .wizard-fieldset label{
    font-weight: 500;
    font-size: 15px;
    color: #000;
    margin-bottom: 10px;
  }
  .form-wizard .wizard-form-error {
    display: none;
    background-color: #d70b0b;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
  }
  .patinent-details .result-div::before{
    display: none;
  }
  .patinent-details .cm-history:after{
    display: none;
  }
  .nm-text{
    font-size: 15px;
    padding-bottom: 4px;
    display: table;
    margin: auto;
    color:#C2C2C2 ;
    font-weight: 400;
  }
  .booking-modal-div .modal-body{
    padding: 0;
  }
  .booking-modal-div .modal-header{
    border-bottom: none;
  }
  .booking-modal-div .e-control-wrapper{
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
    padding: 0.205rem 0.75rem;
    font-size: 1rem;
  }
  .e-input-group-icon.e-date-icon, *.e-control-wrapper .e-input-group-icon.e-date-icon{
    margin: 2px 0 4px 0 !important;
  }
  .test-details-div{
    background: #F8F8F8;
    border-radius: 10px;
    display: inline-block;
    padding:30px;
    width: 100%;
  }
  .form-wizard h4{
    font-size: 17px;
    font-weight: 600;
  }
  .cunt-div{
    display: inline-block;
    width: 100%;
    border: solid 1px #D0D0D0;
    background: #fff;
    border-radius: 0 0 5px 5px;
    padding:10px 25px;
  }
  .qty-input {
    color: #000;
    background: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .qty-input .product-qty,
  .qty-input .qty-count {
    background: transparent;
    color: inherit;
    font-weight: bold;
    font-size: inherit;
    border: none;
    display: inline-block;
    min-width: 0;
    height: 28px;
    line-height: 1;
  }
  .qty-input .product-qty:focus,
  .qty-input .qty-count:focus {
    outline: none;
  }
  .custome-div1{
    position: relative;
    width: 100%;
    display: inline-block;
  }
  .custome-div1 .recharts-legend-wrapper{
    position: absolute;
    margin: 0 0 0 auto !important;
    bottom: inherit;
    transform: translateY(-50%);
    top:50%;
    width: 255px !important;
  }
  .custome-div1 .recharts-surface{
    width: auto !important;
    height: 100% !important;
    position: absolute;
    top:50%;
    transform: translateY(-40%);
  }
  .ts-performance{
    position: relative;
    width: 100%;
    display: inline-block;
  }
  .ts-performance .recharts-legend-wrapper{
    position: absolute;
    margin: 0 0 0 auto !important;
    bottom: inherit;
    transform: translateY(-50%);
    top:50%;
    width: 160px !important;
    right: 0;
  }
  .ts-performance .recharts-legend-item{
    
    display: flex !important;
    align-items: center;
    margin-bottom: 5px !important;
  
  }
  .colention-btn{
    border: solid 1px #E0DEDE !important;
    border-radius: 50px !important;
    text-align: center;
    font-size: 14px !important;
    height: 27px;
    display: grid;
    align-content: center;
    padding: 0 !important;
    width: 54px !important;
    margin: auto;
    justify-content: center;
    margin-bottom: 0 !important;
    line-height: 26px !important;
  }
  
  
  .besy-btn-text{
    background-color:#F59E0B !important ;
  }
  .active-bytn{
    background-color:#22C55E !important ;
  }
  .inactive-bytn{
    background-color:#EA5455 !important ;
  }
  .ctl-td{
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  #tabs9{
    display: none;
  }
  #div1-mb-1,
  #div2-mb-2,
  #div3-mb-3,
  #div4-mb-4,
  #div5-mb-5,
  #div6-mb-6,
  #div7-mb-7{
    display: none;
  }
  .toggle-div-btn{
    position: relative;
    background: #E3E3E3 !important;
    border-radius: 50px !important;
    display:flex !important;
    justify-content: space-between !important;
    color: #fff !important;
    width: 57px;
    height: 27px;
    overflow: hidden;
    font-size: 11px !important;
    margin: auto;
  }
  .toggle-div-btn:after{
    position: absolute;
    left: 3px;
    top: 2px;
    width: 22px;
    height: 22px;
    border-radius: 30px;
    content: "";
    display: inline-block;
    background-color: #fff !important;
  }
  .on-text{
    display: none;
    position: absolute;
    left:5px;
  }
  .off-text{
    position: absolute;
    right: 5px;
  }
  .act-btn{
    background:#22C55E !important;
  }
  .act-btn:after{
    right: 0;
    left: inherit;
  }
  .act-btn .on-text{
    display: block;
  }
  .act-btn .off-text{
    display: none;
  }
  
  .ad-form-technic{
      background: #fff;
      border: solid 1px #D2D2D2;
      border-radius: 8px;
      display: inline-block;
      width: 100%;
  }
  
  
  
  .ad-form-technic .form-wizard .form-wizard-steps li .cm-check-icons{
    color: #AAAAAA;
    background: #fff;
    border: solid 1px #AAAAAA;
    
  }
  .ad-form-technic .form-wizard .form-wizard-steps li{
    width: 100%;
  }
  .ad-form-technic .form-wizard .form-wizard-steps li::after{
  
    width: 50%;
    right: -23%;
    left: inherit;
    background:#aaa;
    height: 1px;
  }
  
  .ad-form-technic .form-wizard .form-wizard-steps li.active .cm-check-icons, 
  .ad-form-technic .form-wizard .form-wizard-steps li.activated .cm-check-icons{
    background: #EA5455;
    border: solid 1px #EA5455;
    color: #fff;
    left: inherit;
  }
  
  .ad-form-technic .form-wizard .form-wizard-steps li.active::after, 
  .ad-form-technic .form-wizard .form-wizard-steps li.activated::after{
    background: #000;
  }
  
  .ad-form-technic .form-wizard .form-wizard-steps li:last-child:after{
    display: none;
  }
  .sp-file-div{
    margin-top:33px;
  }
  .choose-sec-div{
    margin: auto;
  }
  .choose-sec-div .form-check-input:checked,
  .vehicle-div-section .form-check-input:checked{
    background-color: #EA5455;
    border-color: #EA5455;
  }
  .hours-input-div .from-group{
    width:110px;
    margin-bottom: 0;
  }
  
  .work-ex-sec .file-ex{
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }
  .work-ex-sec .form-control{
    border: none !important;
  }
  .year-text{
    border-left: solid 1px #aaa;
    padding-left: 15px;
  }
  .techini-user{
    text-decoration: none;
    color: #000;
    cursor: pointer;
  }
  .techini-user span b{
    color: #000;
    font-size: 18px;
  }
  .techini-user span {
    color: #958D8D;
  }
  .user-img-sec{
    width: 62px;
    height: 62px;
    overflow: hidden;
    border-radius: 50%;
    display: grid;
    align-content: center;
    justify-content: center;
    margin: 0 10px 0 0;
  }
  .user-img-sec img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .technician-account-div .show-date-details{
    height: auto;
    align-items: flex-start;
    padding-top: 15px;
  }
  
  .dash-admin-reviw{
    border: solid 1px #D1D1D1;
    border-radius: 10px;
    align-content: center;
    justify-content: center;
    position: relative;
    height: 372px;
  }
  .dash-admin-reviw .recharts-surface{
    overflow: auto;
  }
  .ts-performance li.recharts-legend-item .recharts-surface{
    width: 10% !important;
  }
  .ts-performance .recharts-wrapper{
    width: 100% !important;
  }
  .custome-div1 .recharts-legend-item{
    
    display: flex !important;
    align-items: center;
    margin-bottom: 5px !important;
    position: relative;
  
  }
  .custome-div1 .recharts-legend-item .recharts-surface{
    position: relative;
    transform: none;
  }
  .user-top-details .comon-user-ds h4{
    font-size: 16px;
    position: relative;
    z-index: 2;
  
  }
  .user-top-details .comon-user-ds figure{
    position: relative;
    z-index: 2;
  }
  .user-top-details .comon-user-ds h4 span{
    font-size: 35px;
    font-weight: 900;
    margin-top: 7px;
  }
  
  .user-top-details > div:first-child .comon-user-ds{
    background: #B8F9D0;
  
  }
  .user-top-details > div:nth-child(2) .comon-user-ds{
    background: #FBD492;
  
  }
  .user-top-details > div:last-child .comon-user-ds{
    background: #FDD7D7;
  
  }
  .comon-user-ds{
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    height: 118px;
  }
  
  .comon-user-ds:after{
    position: absolute;
    content: "";
    width: 149px;
    right: -50px;
    top: -20px;
    background: #a9f0c3;
    height: 158px;
    border-radius: 50%;
    z-index: 1;
  }
  .user-top-details > div:nth-child(2) .comon-user-ds:after{
    position: absolute;
    content: "";
    width: 149px;
    right: -50px;
    top: -20px;
    background: #f7cc85;
    height: 158px;
    border-radius: 50%;
    z-index: 1;
  }
  .user-top-details > div:last-child .comon-user-ds:after{
    position: absolute;
    content: "";
    width: 149px;
    right: -50px;
    top: -20px;
    background: #f6cbcb;
    height: 158px;
    border-radius: 50%;
    z-index: 1;
  }
  .history-techic {
    background: #fff;
    border: solid 1px #D2D2D2;
    border-radius: 8px;
    display: inline-block;
    width: 100%;
  }
  .history-main-techician .result-div::before{
    left:-39px;
  }
  .history-main-techician .row{
    margin-bottom: 30px;
    padding: 20px;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  .admin-re-chat .recharts-wrapper{
    width: 100% !important;
  }
  
  .custome-div1 li.recharts-legend-item .recharts-surface{
    width: 10% !important;
  }
  
  .qty-input .product-qty {
    width: 50px;
    min-width: 0;
    display: inline-block;
    text-align: center;
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
    border: 1px solid #fdc6c6;
  }
  .qty-input .product-qty::-webkit-outer-spin-button, .qty-input .product-qty::-webkit-inner-spin-button {
    -webkit-appearance: none;
            appearance: none;
    margin: 0;
  }
  .qty-input .qty-count {
    padding: 0;
    cursor: pointer;
    width:23px;
    font-size: 1.25em;
    text-indent: -100px;
    overflow: hidden;
    position: relative;
  }
  .qty-input .qty-count:before, .qty-input .qty-count:after {
    content: "";
    height: 2px;
    width: 10px;
    position: absolute;
    display: block;
    background: #000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .qty-input .qty-count--minus {
    background:#FDC6C6;
    color: #000;
  }
  .qty-input .qty-count--add {
    background:#FDC6C6;
    color: #000;
  }
  .qty-input .qty-count--add:after {
    transform: rotate(90deg);
  }
  .qty-input .qty-count:disabled {
    color: #ccc;
    background: #f2f2f2;
    cursor: not-allowed;
    border-color: transparent;
  }
  .qty-input .qty-count:disabled:before, .qty-input .qty-count:disabled:after {
    background: #ccc;
  }
  .cunt-div .row{
    border-bottom: solid 1px #dbdbdb;
  }
  .customer_records{
    border: none !important;
  }
  .cunt-div .row > div{
    position: relative;
  
  }
  .comon-count{
    height: 77px;
    position: relative;
    justify-content: center;
  }
  .cunt-div .row > div .comon-count:after{
    border-right: solid 1px #dbdbdb;
    position: absolute;
    content: "";
    width: 1px;
    height:100%;
    top: 0;
    right: -10px;
  }
  /* .cunt-div .row > div:first-child{
    padding-left: 0;
  }
  .cunt-div .row > div:last-child{
    padding-right: 0;
  }
  .cunt-div .row > div:last-child .comon-count{
    justify-content: flex-end;
  } */
  .cunt-div .row:last-child{
    border-bottom: none;
  }
  .comon-count .form-check{
    position: relative;
  }
  .comon-count .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
    position: absolute;
    top: 41%;
    transform: translateY(-50%);
  }
  .cunt-div .row > div:nth-child(3) .comon-count::after{
    display: none;
  }
  .td{
    display: table-cell;
  }
  .tbl-re,
  .tbl-re-dl {
    width: 80%;
    margin: 0 auto;
    display: table;
  }
  .tbl-re-dl .td{
    display: table-cell;
    width: 20%;
    width: 19%;
    padding: 10px 0;
  }
  .tbl-re{
    background: #f8f8f8;
    height: 40px;
    line-height: 38px;
    
  }
  .tbl-re .td{
    font-weight: 600;
    font-size: 16px;
    color: #000;
    width: 19%;
    
  }
  .adbenf-btn{
    display: flex !important;
    color: #EA5455 !important;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    align-items: center;
    justify-content:flex-end;
    transition: all 0.5s;
    width: 190px;
    margin-left: auto;
  }
  .adbenf-btn:hover{
    font-weight: 600;
  }
  .inside-sp .ps-status-div{
    border: solid 1px #27AA12;
  }
  .inside-sp .iocn-cm{
    background: #27AA12;
  }
  .inside-sp .cm-disable .iocn-cm {
    background: #F4F4F4;
  }
  .inside-sp .ps-status-div{
    width: 90%;
    margin: auto;
  }
  .adbenf-btn img{
    margin-right: 5px;
  }
  .showSingle{
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  #div1{
    display: none;
  }
  #div2{
    display: none;
  }
  .notification-div h6{
    color: #bbb;
    font-size: 14px;
    margin-left: 10px;
  }
  #div3{
    display: none;
  }
  #div4{
    display: none;
  }
  #div5{
    display: none;
  }
  .qty-input {
    border-radius: 4px;
  }
  .form-check label{
    margin-bottom: 0;
  }
  .form-wizard-next-btn{
    background: #EA5455;
    width: 140px;
    height: 41px;
    text-align: center;
    display: grid;
    align-content: center;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.5s;
    cursor: pointer;
  }
  .form-wizard-next-btn:hover{
    background:#000;
    color: #fff;
  }
  
  #sun-sl{
    display: none;
  }
  #tu-sl{
    display: none;
  }
  #wn-sl{
    display: none;
  }
  #th-sl{
    display: none;
  }
  #fri-sl{
    display: none;
  }
  #sta-sl{
    display: none;
  }
  
  
  
  
  
  
  
  
  .payment-div h6.cm-heading-page,
  .bk-div h6.cm-heading-page{
    background-color:inherit !important;
    color: #000 !important;
    padding: 0 !important;
    font-size: 17px;
    font-weight: 600;
    align-items: center;
  }
  
  .plans .plan input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  
  .plans .plan {
    cursor: pointer;
    width: 100%;
    position: relative;
  }
  .paid-btn-new{
    background: #22C55E;
    color: #fff !important;
      font-size: 14px !important;
      width: 74px;
      height: 27px;
      display: grid;
      align-content: center;
      border: none;
      border-radius: 5px;
      line-height: 15px;
  }
  .unpaid-btn{
    background: #F59E0B;
    color: #fff !important;
      font-size: 14px !important;
      width: 75px;
      height: 27px;
      display: grid;
      align-content: center;
      border: none;
      border-radius: 5px;
      line-height: 15px;
  }
  .left-sec-div-md{
    border-radius: 10px;
    border: solid 1px #E7E7E7;
  }
  .common-list{
    display: inline-block;
    width: 100%;
    border-bottom: solid 1px #E7E7E7;
    padding: 40px 0 0 40px;
  }
  .common-list:nth-child(2){
    border: none;
  }
  .common-list h5{
    color: #F77B7B;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
  }
  .common-list ul li .sub-tl{
    color: #696767;
    font-size: 16px;
    font-weight: 600;
    width: 182px;
    display: inline-block;
    margin-bottom: 10px;
  }
  .total-payment{
    background: #f5f9ff;
    border-radius: 20px;
    padding: 30px;
    display: inline-block;
    width: 100%;
    height: 568px;
  }
  .total-payment h5{
    font-weight: 700;
    font-size: 18px;
    color: #000;
  }
  .or-id{
    text-decoration: none;
    color: #000;
    cursor: pointer;
  }
  .total-payment ul li{
    margin-bottom: 10px;
    font-size: 15px;
  }
  .total-payment ul li span{
    font-size: 15px;
    font-weight: 600;
    color: #696767;
    width: 154px;
    display: inline-block;
  }
  .padidl{
    background: #22C55E;
    color: #fff;
    width: 64px;
    height: 23px;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    font-size: 15px;
  }
  .sp-midn-bnt{
    border: solid 1px #bbb !important;
    position:  relative !important;
    width: 98%;
    text-align: left !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 37px;
  
  }
  .sp-midn-bnt::after{
    position: absolute;
    right: 10px !important;
      top: 5px !important;
    content: "\f107" !important;
    border: none !important;
    font-family: 'FontAwesome';
  
  }
  .sp-midn-bnt .e-input-group{
    position: absolute !important;
    left: 0 !important;
    right: 0;
    top:0;
    width: 100%;
    opacity: 0;
    margin-bottom: 0 !important;
    height: 100%;
  }
  .sp-midn-bnt .e-input-group-icon{
    position: absolute;
    min-width: 100% !important;
    min-height: 100% !important;
    margin-bottom: 0 !important;
  }
  .main-menu {
    /* overflow-y: auto; */
    height: 100vh;
  }
  
  .main-menu::-webkit-scrollbar-thumb:hover {
    background: rgba(255,0,0,7%);
   }
  .main-menu::-webkit-scrollbar {
    width: 6px;
   }
   
  .main-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius:0;
   }
   
  
  .main-menu::-webkit-scrollbar-thumb {
    background: rgba(255,0,0,7%);
    border-radius:0;
   }
   
   
   
   /* Handle on hover */
   
  .main-menu::-webkit-scrollbar-thumb:hover {
    background: rgba(255,0,0,7%);
   }
  
  .sp-mind-icon{
    left: -66px;
      top: -6px;
  }
  .dp-arrow-sp svg{
    transform: rotatez(180deg);
  }
  .spcial-add-text-fom label{
    font-size: 14px !important;
  }
  .pack label{
    font-size: 13px !important;
  }
  .technician-page .same-details span{
    font-size: 15px !important;
  }
  .back-invent{
    color: #000;
  }
  .serch-sec-div{
    height: 37px;
    background: #fff !important;
  }
  .serch-sec-div input{
    border: none !important;
    background: none !important;
  }
  #tabs1-login,
  #tabs2-login,
  #tabs3-login,
  #tabs4-login,
  #tabs5-login,
  #tabs6-login,
  #tabs7-login,
  #tabs8-login{
    display: none;
  }
  .spcial-add-text-fom .from-group{
    margin-bottom: 15px !important;
  }
  .new-buil-table3 td{
    font-size: 13px !important;
  }
  .new-buil-table3 th{
    vertical-align: top;
  }
  .uppadidl{
    background: #F59E0B;
    color: #fff;
    width: 84px;
    height: 23px;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    font-size: 15px;
  }
  .common-list h4{
    font-size: 20px;
    font-weight: 600;
  }
  .plans .plan .plan-content {
    display: inline-block;
    background: #F1F5F9;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    -webkit-transition: -webkit-box-shadow 0.4s;
    transition: -webkit-box-shadow 0.4s;
    -o-transition: box-shadow 0.4s;
    transition: box-shadow 0.4s;
    transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
    position: relative;
    height: 120px;
    display: grid;
    align-content: center;
    text-align: center;
  }
  
  .iconradio{
    background: #fff;
    width: 38px;
    height: 38px;
    overflow: hidden;
    border-radius: 50%;
    display: grid;
    align-content: center;
    text-align: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 8px;
  }
  .iconradio svg{
    color: #fff;
  }
  .plans .plan .plan-content img {
    margin-right: 10px;
    height: 72px;
  }
  
  .plans .plan .plan-details span {
    margin-top: 5px;
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: #4A4A4A;
    font-family: 'Inter', sans-serif;
  }
  
  .container .title {
    font-size: 16px;
    font-weight: 500;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    color: #252f42;
    margin-bottom: 20px;
  }
  
  .plans .plan .plan-details p {
    color: #646a79;
    font-size: 14px;
    line-height: 18px;
  }
  
  .plans .plan .plan-content:hover {
    -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
    box-shadow: 0px 3px 5px 0px #e8e8e8;
  }
  
  .top-user-section figure{
    height: 274px;
    width: 251px;
    overflow: hidden;
    display: inline-block;
    border-radius: 8px;
  }
  .top-user-section figure img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .top-user-section h2{
    font-size: 25px;
    color: #000;
  }
  .top-user-section h2 span{
    color: #958D8D;
    font-size: 18px;
    margin-top: 8px;
  }
  .top-user-section ul li{
    margin-bottom: 10px;
    color: #686868;
    font-size: 16px;
  }
  .top-user-section ul li span:first-child{
    width: 198px;
    display: inline-block;
    float: left;
    overflow: hidden;
  }
  .top-user-section ul li span:last-child{
   
  }
  .line-bar-d1{
    background: #FAC864;
      border-radius: 4px;
      display: inline-block;
      width: 100%;
      position: relative;
      height: 8px;
      overflow: hidden;
  }
  .best-text{
    background: #97CA39;
    color: #fff;
    text-align: center;
    display: grid;
    align-content: center;
    justify-content: center;
    width: 65px;
    height: 25px;
    border-radius: 5px;
    float: right;
    margin-right: 20px;
    position: absolute;
    bottom: 20px;
    right: 10px;
    font-size: 14px;
  }
  .best-text::after{
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: auto;
    border-left: solid 9px transparent;
    border-right: solid 9px transparent;
    border-top: solid 15px #97CA39;
    content: '';
    bottom: -7px;
    /* border-bottom: solid 10px transparent; */
    width: 3px;
  }
  .sub-modal-text{
    font-size: 16px;
    font-weight: 700;
  }
  .line-month{
    margin-right: 25px;
  }
  .line-bar-d1::before{
    width: 30%;
    position: absolute;
    content: "";
    left: 0;
    height: 8px;
    top:0;
    display: inline-block;
    background: #FF4F24;
  }
  .line-bar-d1::after{
    width: 30%;
    position: absolute;
    content: "";
    height: 8px;
    top:0;
    right: 0;
    display: inline-block;
    background: #97CA39;
  }
  .plans .plan input[type="radio"]:checked + .plan-content {
    background: #122d4b;
    -webkit-transition: ease-in 0.3s;
    -o-transition: ease-in 0.3s;
    transition: ease-in 0.3s;
    color: #fff;
  }
  .line-month{
    font-size: 14px;
    margin: 0;
  }
  .comon-div-daily{
    display: grid;
    border-radius: 8px;
    width: 124px;
    height:73px;
    align-content: center;
    justify-content: center;
    text-align: center;
    box-shadow: #aaa 1px 2px 8px;
  }
  .comon-div-daily span{
    color: #FF3636;
    font-weight: 800;
    font-size: 22px;
  }
  .bootom-modal-techinic-1 ul li{
    font-size: 14px;
    color: #686868;
    margin-bottom: 8px;
  }
  .bootom-modal-techinic-1 .row > div{
    position:relative
  }
  .bootom-modal-techinic-1 .row > div:after{
    border-right: solid 1px #aaa;
    content: "";
    position: absolute;
    top:0;
    right: 22px;
    width: 1px;
    height: 100%;
  }
  .bootom-modal-techinic-1 .row > div:last-child::after{
   display: none;
  }
  .view-dc{
    background: #EA5455 !important;
    color: #fff !important;
    font-size: 14px !important;
  }
  .swith-btn-cm{
    position: relative;
    width: 43px;
    height: 30px;
    border-radius: 20px;
    overflow: hidden;
    background: #16B41C;
    margin-right: 10px;
    display: grid;
    align-content: center;
  }
  
  .swith-btn-cm:after{
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    right: 5px;
    transform: translateY(-50%);
    top:50%;
  }
  .active-btn{
    background: #aaa;
  }
  .active-btn:after{
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    left: 5px;
    right: inherit;
    transform: translateY(-50%);
    top:50%;
  }
  .new-group-btn .btn-secondary{
    background: #F5F5F5;
    border-radius: 50px;
    width: 77px;
    border: none;
    color: #000;
  }
  .new-group-btn .dropdown-menu{
    border-radius: 8px;
  }
  .new-group-btn .dropdown-menu a{
    cursor: pointer;
  }
  .dots-text{
    color: #E6E6E6;
    font-size: 28px;
  }
  span.text-number {
    border: solid 1px #aaa;
    width: 46px;
    height: 30px;
    display: grid;
    justify-content: center;
    align-content: center;
    margin: auto;
    border-radius: 11px;
  }
  .add-login-btn,
  .login-dc-btn{
    border: solid 1px #EA5455 !important;
    color: #EA5455 !important;
    font-size: 14px !important;
  }
  .lab-profile-div h4{
    font-size: 18px;
  }
  .lab-profile-div ul{
    display: grid;
    justify-content: center;
  }
  .lab-profile-div ul li{
    margin-bottom: 15px;
    padding: 8px;
  }
  .lab-profile-div ul li:nth-child(even){
    background: #fff;
  }
  .lab-profile-div ul li:nth-child(odd){
    background: #FAFAFA;
  }
  .lab-profile-div ul li .list-heading{
    width: 370px;
    display: inline-block;
  
  }
  .gp-button .btn-secondary{
      background-color: #f5f5f5 !important;
      color: #000 !important;
      font-size: 14px;
      font-weight: 600;
      border-radius: 30px !important;
      transition: all 0.5s;
      transform: scale(1);
      border: none;
  }
  .gp-button .dropdown-menu{
      border-radius: 8px !important;
  }
  .gp-button .dropdown-menu a{
    cursor: pointer;
  }
  .delet-btn1{
    background: #EA5455 !important;
    color: #fff !important;
    width: 121px !important;
  }
  .sp-dl-icon{
    position: relative;
    top:-2px;
  }
  .grand-total-list{
    border-radius: 8px;
    padding: 10px 2px;
    display: flex;
    justify-content: center;
    color: #000;
    font-weight: 700;
  }
  .grand-total-list span{
    color: #000 !important;
    font-weight: 700 !important;
    font-size: 16px !important;
  }
  .design-payt-2 .total-payment{
    padding: 20px;
  
  }
  .gp-radio-div2 .form-check-input:checked{
     background-color: #EA5455 !important;
     border-color: #EA5455 !important;
  }
  .sp-lab-form{
    position: relative;
  }
  .sp-lab-form svg{
    position: absolute;
    right: 10px;
    top:12px;
    appearance: none;
  }
  .com-lab-fild{
    padding: 20px 15px 25px;
    border-radius: 8px;
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .com-lab-fild-new{
    background: none;
  }
  .com-lab-fild .rvm-btn{
    position: absolute;
    right: 16px;
    top: 8px;
    display: table;
    margin-left: auto;
    width: 31px;
  }
  .total-beneficiary{
    position: relative;
  }
  .admin-clone-new .rvm-btn{
    position: relative;
  }
  .total-beneficiary .btn-remove-customer{
     position: absolute;
     right: 0;
     top:0;
  }
  .total-beneficiary  .remove{
    position: relative;
  }
  .total-beneficiary  .remove .rvm-btn{
    display: none;
  }
  .new-postion-btn{
    position: relative;
      top: -95px;
      z-index: 3;
  }
  .admin-test .customer_records_dynamic-add .remove{
    padding: 20px 15px 25px;
    border-radius: 8px;
    position: relative;
    display: inline-block;
    width: 100%;
    background: #fff;
  }
  .com-lab-fild-new .rvm-btn{
    margin-top: 33px;
  }
  .customer_records_dynamic-add2 .remove{
    position: relative;
  }
  .customer_records_dynamic-add2 .rvm-btn{
    display: none;
  }
  .customer_records_dynamic-add2 .btn-remove-customer{
    position: absolute;
    right: 0;
    top:3px;
  }
  .new-line{
    background: #000 !important;
  }
  .admin-test .customer_records_dynamic-add .btn-remove-customer{
    position: absolute;
    right: 0;
    top:-22px;
  }
  .adbenf-btn2{
    display: table;
    margin-left: auto;
  }
  .customer_records_dynamic-add .rvm-btn{
    display: none;
  }
  .history-btn{
    border: solid 1px #EA5455 !important;
    color: #EA5455 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .total-inventory-page{
    border-radius: 8px;
    background: #fff;
  }
  .comon-file-incent{
    padding: 0 30px;
  }
  .comon-file-incent label{
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 15px;
    color: #000;
  }
  .inventory-body-2{
    background: #fbfbfb;
    width: 100%;
    display: inline-block;
  }
  .inventory-body-2 h2{
    font-size: 19px;
    font-weight: 600;
    margin-left: 32px;
  }
  .comon-filed-input{
    position: relative;
    width: 100%;
  }
  .comon-filed-input .form-control{
    border: none !important;
    height: 50px;
    box-shadow: #e5e5e5 1px 2px 8px;
   
  }
  .virals-div-sec{
    border: solid 1px #E1D7D7;
    border-radius: 8px;
    display:table;
    width: 94%;
    margin: auto;
    overflow: hidden;
  }
  .virals-div-sec .top-virls-1{
    background: #fff;
    border-bottom: solid 1px #E1D7D7;
    padding:10px 33px;
  }
  #edit-infomation{
    display: none;
  }
  .new-comon-invent{
    background: #fff;
    box-shadow: #e5e5e5 1px 2px 8px;
    border-radius:8px;
    height: 50px;
    padding: 0 20px;
  }
  .submit-btn{
    display: table !important;
    margin: auto;
    background: #EA5455;
    color: #fff;
  }
  #history-div{
    display: none;
  }
  .inventory-history-page{
    border-radius: 8px;
      background: #fff;
      padding:30px;
  }
  .inventory-history-page h2{
    font-size: 19px;
    font-weight: 600;
  
  }
  .calender-div-sec p{
    font-size: 14px;
    font-size: 600;
  }
  .inventory-history-page h2 span{
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
  }
  .new-comon-invent label{
    margin-bottom: 0;
  }
  .new-comon-invent .qty-input{
    width: 92px;
    margin-left: auto;
  }
  .virals-div-sec .top-virls-1 h2{
    margin-left: 0;
    margin-bottom: 0;
  }
  .viral-n{
    display: grid;
    justify-content: center;
    align-items: center;
    background: #fff2f2;
    width: 40px;
    height: 40px;
    color: #E74C3C;
    border-radius: 50%;
    font-weight: 700 !important;
  }
  .comon-filed-input .iput-number{
    display: grid;
    justify-content: center;
    align-items: center;
    background: #fff2f2;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 8px;
    top:5px;
    color: #E74C3C;
    border-radius: 50%;
  }
  .assign-btn{
    border: solid 1px #0A6934 !important;
    color: #0A6934 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .slect-btn{
    background-color: #F59E0B !important;
    color: #fff !important;
    font-size: 14px !important;
  }
  .test-modal-add thead,
  .his-table thead{
    background: #f7f7f7;
  }
  .test-modal-add tr,
  .his-table tr{
    font-size: 14px !important;
    vertical-align: middle;
  }
  .test-modal-add tbody, 
  .test-modal-add td, 
  .test-modal-add tfoot, 
  .test-modal-add th, 
  .test-modal-add thead, 
  .test-modal-add tr,
  .his-table tbody,
  .his-table td,
  .his-table tfoot,
  .his-table th{
    text-align: center;
  }
  .design-payt-2 .total-payment ul li span{
    font-size: 15px;
  }
  .comon-pay-text h5{
    color: #000;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0;
    
  }
  .comon-pay-text h5 span{
    color: #6A6A6A;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .amount-table-payment > div{
    position: relative;
  }
  .amount-table-payment > div:after{
    position: absolute;
    content: "";
    width: 1px;
    height: 50px;
    background: #D7D3D3;
    right: -25px;
    top: 0;
  }
  .comon-input-test{
    width: 15%;
    display: inline-block;
    margin-right: 10px;
  }
  .comon-input-test .form-control{
    border-radius: 20px;
    font-size: 14px;
  }
  .sp-booking-test .track-btn{
    background-color: #f59e0b !important;
  }
  .sp-booking-test .view1-btn{
    background-color:#02B596 !important;
    color: #fff !important;
    border: none !important;
  }
  .sp-adjects{
    position: relative;
    top:-3px;
  }
  .adpayment-table th{
    vertical-align: top;
    position: relative;
  
  }
  .top-form {
    width: 96px;
    height: 30px;
    display: table;
    margin: auto;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }
  .top-form .form-control{
    height: 28px;
    border-radius: 5px !important;
  }
  .adpayment-table th {
    vertical-align: top;
    position: relative;
    /* height: 90px; */
    display: table-cell;
  }
  .sp-button-adjucts .gp-button .download-btn{
    font-size: 13px;
  }
  .fc .fc-timegrid-slot {
    height: 66px !important;
    border-color: #E8E7E7;
  }
  .fc-theme-standard td, .fc-theme-standard th{
    vertical-align: middle !important;
    border-color: #E8E7E7 !important;
  }
  .slot-cl{
    padding:30px;
    width: 100%;
    display: inline-block;
    border-radius: 8px;
    border: solid 1px #D2D2D2;
  }
  .fc .fc-col-header-cell-cushion{
    color: #000 !important;
    text-decoration: none !important;
  }
  .fc .fc-timegrid-slot-label{
    border: none;
  }
  .demo-app{
    position: relative;
  }
  .fc-button-group{
    position: absolute !important;
    left: 60px;
    top:0;
  }
  .check-btn{
    background: #EA5455 !important;
    color: #fff !important;
    height: 39px;
    margin-top: 31px;
    font-size: 15px !important;
    transition: all 0.5s;
  }
  .submit-btn-1{
    background: #EA5455 !important;
    color: #fff !important;
     position: relative;
     top:45px;
     font-size: 15px !important;
  }
  .fc-today-button{
    display: none !important;
  }
  .fc-scrollgrid-sync-inner {
    height: 66px;
    vertical-align: middle;
    display: grid;
    align-content: center;
  }
  .fc-v-event{
    background: #EBFFEF !important;
    border-radius: 0 !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: solid 2px green !important;
    color: green !important;
    padding-top: 15px !important;
    padding-left: 15px !important;
  }
  .ad-fm{
    display: inline-block;
    width: 100%;
  }
  .rv-text{
    position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: table;
      margin: auto;
  }
  .cld-icon{
    position: relative;
  }
  
  .xnew-table .tbl-re-dl{
    width: 100%;
    background: #fff;
  
  }
  #xdiv1,
  #xdiv2,
  #xdiv3,
  #xdiv4,
  #xdiv5,
  #xdiv6{
    display: none;
  }
  .cld-icon .e-input-group {
    opacity: 0;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
  }
  .head-text-sp{
    background: #4E6CB9 !important;
    color: #fff !important;
    position: absolute;
      top: -4%;
      left: 60%;
      transform: translateX(-50%);
      display: table;
      margin: auto;
  }
  .new-dit-add .head-text-sp{
    top: -14%;
    left: 49%;
  }
  .head-text-sp:after{
    content: "";
    position: absolute;
    border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      border-top: solid 10px #4E6CB9;
      content: '';
    bottom: -10px;
    display: inline-block;
    width: 10px;
    height: 10px;
    left: 40%;
  }
  .sp-admintable{
    height: 483px;
  }
  .rv-text h2{
    font-weight: 900;
    font-size: 40px;
  }
  .rv-text h6{
    color: #9EA3AC;
    margin-bottom: 5px;
  }
  .fc-event-time,
  .fc-event-title{
    color: green !important;
  }
  .check-btn:hover{
    background: #000 !important;
  }
  .fc .fc-button-primary{
    background: #f4f4f5 !important;
    color: #000 !important;
    border: none !important;
  }
  .view-new-btn{
    border: solid 1px #CBC9C9 !important;
    border-radius: 5px ;
    color: #000 !important;
    font-size: 14px !important;
    background: #fff !important;
    width: 75px;
      height: 27px;
      display: grid;
      align-content: center;
      border: none;
      border-radius: 5px;
      line-height: 15px;
      padding: 0 !important;
  }
  /* .sp-button-adjucts .gp-button .edit-btn{
    font-size: 14px;
  } */
  .amount-table-payment > div:last-child:after{
    display: none;
  }
  .eye-icon{
    position: relative;
    top:-5px;
    margin-left: 5px;
  }
  .bootom-modal-techinic-1 ul li span{
    width: 198px;
    display: inline-block;
  }
  .collecton-section-div .d-flex > div:first-child{
    border: solid 1px #FF3636;
  }
  .collecton-section-div .d-flex > div:nth-child(2){
    border: solid 1px #EEA410;
  }
  .collecton-section-div .d-flex > div:nth-child(2) span{
    color: #EEA410;
  }
  .collecton-section-div .d-flex > div:last-child{
    border: solid 1px #97CA39;
    
  }
  .collecton-section-div .d-flex > div:last-child span{
    color: #97CA39;
  }
  .plans .plan input[type="radio"]:checked + .plan-content  .iconradio{
    color: #EA5455;
  }
  .plans .plan input[type="radio"]:checked + .plan-content .ts-cm{
    color: #fff;
  }
  .plans .plan .plan-content .iconradio{
    color: #fff;
  }
  .plans-ne ul{
    column-count: 3;
  }
  .comon-btn-rd{
    border: solid 1px #BDC0C3 !important;
    border-radius: 50px !important;
    width: 171px;
    height: 36px;
    display: grid;
    align-content: center;
    justify-content: center;
    padding: 0 !important;
  }
  .avbtn{
    border: solid 1px #EA5455 !important;
    color: #EA5455 !important;
  }
  .new-slote .plan-content{
    border: solid 1px #BDC0C3 !important;
    border-radius: 50px !important;
    width: 171px;
    height: 36px !important;
    display: grid;
    align-content: center;
    justify-content: center;
    padding: 0 !important;
    background-color: inherit !important;
    margin: auto;
  
  }
  .av-tabs .plan-content{
    border: solid 1px #EA5455 !important;
    color: #EA5455 !important;
  }
  .av-tabs .ts-cm{
    color: #EA5455 !important;
  }
  .new-slote .plan .plan-details span{
    margin-top: 0;
  
  }
  .bk-tabs-div .nav-pills .nav-link.active, .nav-pills .nav-link{
    border-radius: 0;
    color: #333333;
    font-family: 'Nunito', sans-serif;
    font-size: 17px;
    font-weight: 600;
  }
  .bk-tabs-div .nav-pills .nav-link.active, .nav-pills .nav-link:hover{
    color: #EA5455;
  }
  .bk-tabs-div .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
     border-bottom: solid 4px #EA5455;
     border-top: none;
     border-left: none;
     border-right: none;
  }
  /* .setting-md-div .row > div:nth-child(2n+2) .comon-div-sec-details{
    display: grid;
    justify-content: flex-end;
  } */
  .right-menu-sec ul li a{
    cursor: pointer;
  }
  .left-history h5{
    font-family: 'Nunito', sans-serif;
    color: #000;
    font-weight: 700;
    margin-bottom:0;
    font-size: 16px;
  }
  .mn-head{
    font-family: 'Nunito', sans-serif;
    color: #716868;
    font-size: 15px;
    font-weight: 600;
  }
  .same-details span{
    font-family: 'Nunito', sans-serif;
    color: #353535;
    font-size:13px;
    margin: 0 5px;
    display: inline-block;
  }
  .same-details span:first-child{
    margin-left: 0;
  }
  .same-details span svg{
    color: #d9d9d9;
    font-size: 8px;
    margin-right: 3px;
  }
  .new-selc{ 
    height: 30px;
    width: 107px;
    border: 1px #000 solid;
    margin: auto;
    display: table;
  }
  .new-selc li { padding: 5px 10px; z-index: 2; }
  .new-selc li:not(.init) { float: left; width: 130px; display: none; background-color: #E3F8EC !important;
  position: relative; z-index: 2; }
  .new-selc li:not(.init):hover, .new-selc li.selected:not(.init) { background-color: #E3F8EC !important; }
  .new-selc li.init { cursor: pointer; }
  
  a#submit { z-index: 1; }
  
  .pay-dp-btn .new-selc{
    border: solid 1px #0A6934 !important;
      background-color: #E3F8EC !important;
      color: #0A6934 !important;
      font-size: 12px !important;
      border-radius: 5px;
      border-radius: 4px;
      /* overflow: hidden; */
      display: inline-block;
      position: relative;
      padding: 0;
      text-align: left;
  }
  .pay-dp-btn .new-selc li:not(.init){
    width: 107px; 
  }
  .pay-dp-btn .new-selc li:not(.init):hover, 
  .pay-dp-btn  .new-selc li.selected:not(.init) {
    background-color: #0A6934 !important;
    color: #fff !important;
  }
  .pay-dp-btn .new-selc:after{
    position: absolute;
    right: 6px;
    top: 12px;
  
  }
  
  
  /* cm selected */
  .select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
  }
  
  .select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: #fff;
    width: 220px;
    height: 40px;
  }
  
  .select-styled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #c0392b;
    padding: 8px 15px;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }
  .select-styled:after {
    content: "";
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    top: 16px;
    right: 10px;
  }
  .select-styled:hover {
    background-color: #b83729;
  }
  .select-styled:active, .select-styled.active {
    background-color: #ab3326;
  }
  .select-styled:active:after, .select-styled.active:after {
    top: 9px;
    border-color: transparent transparent #fff transparent;
  }
  
  .select-options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #ab3326;
  }
  .select-options li {
    margin: 0;
    padding: 12px 0;
    text-indent: 15px;
    border-top: 1px solid #962d22;
    -moz-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    -webkit-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
  }
  .select-options li:hover, .select-options li.is-selected {
    color: #c0392b;
    background: #fff;
  }
  .select-options li[rel="hide"] {
    display: none;
  }
  
  
  /* cm secletc */
  .unpadi{
    border: solid 1px #0A6934 !important;
      background-color: #E3F8EC !important;
      color: #0A6934 !important;
      font-size: 12px !important;
      border-radius: 5px;
      width: 107px !important;
      margin: auto ;
  }
  .paid-sl{
    background-color: #f9ead2!important;
    border: 1px solid #f59e0b!important;
    color: #f59e0b!important;
    font-size: 12px!important;
    border-radius: 5px;
      width: 107px !important;
      margin: auto ;
  }
  .inpro{
    border: solid 1px #EA5455 !important;
      background-color: #F9E2E2 !important;
      color: #EA5455 !important;
      font-size: 12px !important;
      border-radius: 5px;
      width: 107px !important;
      margin: auto ;
  }
  #tab-ad-book1,
  #tab-ad-book2,
  #tab-ad-book3,
  #tab-ad-book4,
  #tab-ad-book5,
  #tab-ad-book6,
  #tab-ad-book7,
  #tab-ad-book8,
  #tab-ad-book9{
    display: none;
  }
  #pay-filter-div1,
  #pay-filter-div1,
  #issue-tab-div1,
  #issue-tab-div2,
  #issue-tab-div3,
  #issue-tab-div4,
  #issue-tab-div5,
  #issue-tab-div6,
  #issue-tab-div7,
  #issue-tab-div8{
    display: none;
  }
  #pay-filter-div3,
  #pay-filter-div4,
  #pay-filter-div5,
  #pay-filter-div6{
    display: none;
  }
  
  #lab-book-tab1,
  #lab-book-tab2,
  #lab-book-tab3,
  #lab-book-tab4,
  #lab-book-tab5,
  #lab-book-tab6,
  #lab-book-tab7,
  #lab-book-tab8{
    display: none;
  }
  
  #lab-issue-div1,
  #lab-issue-div2,
  #lab-issue-div3,
  #lab-issue-div4,
  #lab-issue-div5,
  #lab-issue-div6,
  #lab-issue-div7{
    display: none;
  }
  
  #payment-tab-div1,
  #payment-tab-div2,
  #payment-tab-div3,
  #payment-tab-div4,
  #payment-tab-div5,
  #payment-tab-div6,
  #payment-tab-div7,
  #payment-tab-div8{
    display: none;
  }
  .comon-td-dp{
    font-size: 14px !important;
  }
  .crm-dp-div2{
    padding: 8px !important;
    margin-bottom: 0 !important;
    font-size: 13px !important;
    border: none !important;
    box-shadow: #AAAAAA 1px 2px 4px;
  }
  .left-history ul li{
    padding: 0 10px;
    height: 32px;
    display: grid;
    align-content: center;
    justify-content: center;
    background: #F8F8F8;
    margin: 0 5px;
    border-radius: 50px;
    border: solid 1px #CDCCCC;
    font-size: 14px;
  }
  .left-history ul li:first-child{
    margin-left: 0;
  }
  .ps-st{
    background: #22C55E;
    width: 83px;
    height: 27px;
    display: flex;
    align-items:center;
    justify-content: center;
    color: #fff;
    border-radius: 5px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
  }
  .unps-st{
    background: #F59E0B;
    width: 83px;
    height: 27px;
    display: flex;
    align-items:center;
    justify-content: center;
    color: #fff;
    border-radius: 5px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
  }
  .cm-history{
    background: #fff;
    box-shadow: #dfdfdf 1px 4px 25px;
    border-radius: 10px;
    padding:20px 11px;
    position: relative;
    margin-bottom: 35px;
    display: inline-block;
    width: 100%;
  }
  .av-border-gren{
    cursor: pointer;
  }
  .seletc-green-date{
    background: #19B444 !important;
    color: #fff !important;
    cursor: pointer;
  }
  .seletc-green-date .com-avil{
    color: #fff !important;
  }
  
  .cm-history:after{
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    overflow: hidden;
    background: #22C55E;
    content: "";
    left: -44px;
    top:0;
  
  }
  .result-div > div:nth-child(2):after{
    background: #F59E0B;
  }
  
  .right-sec h5{
    font-weight: 800;
    font-family: 'Nunito', sans-serif;
    font-size: 17px;
  }
  .result-div{
    position: relative;
  }
  .result-div::before{
    position: absolute;
    height: 100%;
    background:#EDEAEA;
    width: 4px;
    top:0;
    left: -50px;
    content: "";
  }
  .ps-st span{
    display: grid;
    margin-right: 5px;
  }
  .left-history h6.nw-add{
    color: #EA5455;
    font-family: 'Nunito', sans-serif;
    font-size: 15px;
  }
  
  
  
  
  
  
  
  
  
  
  .new-slote input.radio:empty {
    margin-left: -999px;
  }
  
  /* style label */
  .new-slote input.radio:empty ~ label {
    position: relative;
    float: left;
    line-height: 36px;
    width: 171px;
    height: 36px;
    margin-top: 2em;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
  }
  
  .new-slote input.radio:empty ~ label:before {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    content: '';
    width:100%;
    border: solid 1px #BDC0C3;
    border-radius:50px;
  }
  
  /* toggle hover */
  .new-slote input.radio:hover:not(:checked) ~ label:before {
    
    color: #fff;
  }
  
  .new-slote input.radio:hover:not(:checked) ~ label {
    color: #888;
  }
  
  /* toggle on */
  .new-slote input.radio:checked ~ label:before {
    content:'\2714';
    text-indent: .9em;
    color: #9CE2AE;
    background-color: #4DCB6D;
  }
  
  .new-slote input.radio:checked ~ label {
    color: #777;
  }
  
  /* radio focus */
  .new-slote input.radio:focus ~ label:before {
    box-shadow: 0 0 0 3px #999;
  }
  
  
  .cm-slots{
    width: 114px;
    height: 120px;
    border-radius: 10px;
    display:grid;
    align-content: center;
    justify-content: center;
    background: #F1F5F9;
    text-decoration: none;
    color: #4A4A4A;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  .cm-slots:hover{
    color:#EA5455;
  }
  
  .plans ul li a.active{
    background: #EA5455;
    color: #fff;
  }
  .plans ul li a.active .iconradio svg{
    color:#EA5455;
  }
  
  .new-slote ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .new-slote ul li{
    
    margin: 0 15px;
    margin-bottom: 20px;
  }
  .new-slote ul li:nth-child(2n+1){
    margin-left: 0;
  }
  .new-slote ul li a{
    width: 171px;
    height: 36px;
    display: table;
    text-align: center;
    text-decoration: none;
    border: solid 1px #BDC0C3;
    border-radius:50px;
    color: #000;
    line-height: 36px;
    cursor: pointer;
  }
  .new-slote ul li .avilable-slt{
    border: solid 1px #EA5455;
    color: #EA5455;
    width: 171px;
    height: 36px;
    display: table;
    text-align: center;
    text-decoration: none;
    border-radius:50px;
    line-height: 36px;
  }
  .new-slote ul li .active{
    border: solid 1px #EA5455;
    color: #fff;
    background: #EA5455;
  }
  a.back-link {
    display: flex;
    align-items: center;
    gap: 19px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    text-align: left;
    color: #0000006B;
    text-decoration: none;
    position: relative;
    padding-left: 5%;
    top: 20px;
    width: max-content;
  }
  /* login page */
  .login-page .row{
    padding: 0;
    margin: 0;
  }
  .login-left{
     /*background: #F8F8F8;*/
     height: 100%;
     /*padding-top: 70px;*/
     text-align: center;
  }
  .login-left h2{
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 23px;
    margin-bottom: 0;
  }
  .login-left figure {
    display: inline-block;
    max-width: 667px;
  }
  .login-left figure img{
    width: 100%;
  }
  .loginbottomsmalltxt{
    font-size: 10pt;
  }
  .login_footer_section {
    margin-top: 32px;
  }
  .loginbottomsmalltxt {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
  }
  .topleftloginbtn{
    border:1px solid black;
  }
  .login-right{
    height: 100vh;
    display: grid;
    align-content: center;
  }
  .marginHere{
    margin-bottom:60px;
  }
  .form-sec-login input.form-control {
    height: 65px;
    border-radius: 20px;
    background: #E5E5E580;
    padding: 4px 24px;
    border: none;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    text-align: left;
  }
  .loginbottombuttons p.text-left.remember-btn {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    text-align: left;    margin-bottom: 0;
  }
  .form-sec-login .lable-input .field-icon {
    float: right;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    right: 23px;
    top: 23px;
  }
  button.btn.btn-default.topleftloginbtn {
    border-radius: 10px;
    background: #E5E5E580;
    margin: 15px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 21.78px;
    border: 1px solid #2125293b;
  }
  a.text-right.forget-btn {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    text-align: left;
  }
  .form-sec-login button[type=submit] {
    height: 65px;
    border-radius: 20px;
    background: #E5E5E580;
    margin-top: 31px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 19px;
  }
  .login-footer p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    text-align: center;
  }
  .loginbottombuttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
  }
  .remember-btn, .forget-btn {
    text-decoration: none;
    color: #939699;
    font-weight: bold;
  }
  
  .remember-btn:hover, .forget-btn:hover {
    color: #0056b3; /* Darker blue on hover */
  }
  .forget-btn{
  
  }
  .sign-btn {
    padding: 1px;
    width: 100%;
    background: #ffffff;
    color: black;
    /* display: grid; */
    height: 51px;
    justify-content: center;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 15px;
    transition: all 0.5s;
  }
  .sign-btn:hover,
  .signup-btn:hover{
    /*background: #000;*/
  }
  .sign-btn button,
  .signup-btn button,
  .register-bnt button{
    width: 100%;
    height: 100%;
    background: none;
    color: #939699;
    border: none;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 18px;
  }
  .login-right {
    max-width: 465px;
    margin: auto;
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 23px;
    text-align: center;
  }
  
  .image-container img {
    width: 60px;              /* Each image takes up 50% of the container width */
    height: auto;            /* Maintains the aspect ratio of the images */
    max-width: 100%;         /* Ensures images do not overflow their container */
  }
  
  .form-sec-login h5{
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    margin-bottom: 4px;
  }
  .sub-text-login{
    font-family: 'Nunito', sans-serif;
    color: #888888;
  }
  .form-sec-login .form-group{
    margin-bottom: 15px;
  }
  .form-sec-login label,
  .forget-page label{
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    color: #000;
    margin-bottom: 5px;
  }
  .register-btn{
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
  }
  .register-btn a{
    font-weight: 600;
    color: #000;
    text-decoration: none;
    transition: all 0.5s;
  }
  .register-btn a:hover{
    color: #FE0000;
  }
  .forget-page{
    width: 100%;
    height: 100vh;
    display: grid;
    align-content: center;
    justify-content: center;
  }
  .signup-btn {
    /* background: #FE0000; */
   
    height: 51px;
    justify-content: center;
    width: 291px;
    border-radius: 8px;
    margin: auto;
    margin-top: 35px;
    text-decoration: none;
    transition: all 0.5s;
  }
  
  
  
  
  
  
  .file-upload-wrapper {
    position: relative;
    width: 100%;
    height: 39px;
    border: 1px solid #ced4da;
    border-radius: 5px;
  }
  .file-upload-wrapper:after {
    content: attr(data-text);
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: 39px;
    line-height: 31px;
    color: #212529;
    border-radius: 5px 10px 10px 5px;
    font-weight: 300;
  }
  .file-upload-wrapper:before {
    content: "Choose File";
    position: absolute;
    top: 3px;
    right: 5px;
    display: inline-block;
    height: 31px;
    background: #dbdbdb;
    color: #000;
    font-weight: 600;
    z-index: 25;
    font-size: 15px;
    line-height:30px;
    padding: 0 15px;
    text-transform:capitalize;
    pointer-events: none;
    border-radius: 5px;
    font-family: 'Nunito', sans-serif;
  }
  .back-tbn{
    text-decoration: none;
    color: #000;
    font-family: 'Nunito', sans-serif;
    font-weight: 600
  }
  .account-div-register label,
  .bank-div-add label{
    margin-bottom:5px;
    color: #000;
    font-family: 'Nunito', sans-serif;
    font-weight: 600
  }
  .file-upload-wrapper:hover:before {
    background: #ffeded;
  }
  .payment-div{
    padding-top: 89px;
  }
  .filter-checks .form-check-input:focus{
    box-shadow: none !important;
  }
  #tabslab1{
    display: none;
  }
  #tabsn3{
    display: none;
  }
  #tabsn4{
    display: none;
  }
  .filter-checks .form-check-input:checked{
    background-color: #EA5455 !important;
    border-color: #EA5455 !important;
  }
  .file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 40px;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }
  .register-page h2{
    color: #000;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 25px;
  }
  .register-page p{
    color: #888888;
  }
  .upload-sec-div{
    background: #F6F6F6;
    padding: 15px;
    display: inline-block;
    width: 100%;
  }
  .upload-sec-div .file-upload{
    background: #fff;
  }
  .upload-sec-div h5{
    font-size: 15px;
    margin-bottom: 8px;
    color: #000;
  }
  .back-detaisls h5{
    font-size: 17px;
    font-weight: 800;
    color: #000;
  }
  .back-detaisls ul li{
    margin-bottom: 25px;
    font-size: 14px;
    color: #676464;
  }
  
  .back-detaisls ul li span{
    text-transform: uppercase;
    color: #000;
    font-size: 16px;
    font-weight: 700;
  }
  .upload-sec-div .file-upload__label{
    
    background: #fff;
  }
  .upload-sec-div .submit-btn{
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .register-bnt{
    width: 163px;
    height: 51px;
    border-radius: 5px;
    margin: auto;
    display: table;
    background: #FE0000;
    color: #fff;
  }
  .sign-text{
    color: #000 !important;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
  }
  .sign-text a{
    color: #000;
    text-decoration: none;
    transition: all 0.5s;
  }
  .sign-text a:hover{
    color: #FE0000;
  }
  .bk-div{
    padding-top:85px;
    display: inline-block;
  }
  .sp-pack-page{
    margin-top: -8px;
  }
  .sp-pack-page-bd{
    margin-top: -10px !important;
  }
  .customCSSnew{
    left: 302px!important;
  }
  .bk-inside-div{
    /* background: #fff; */
    border-radius: 8px 8px 0 0;
  }
  .mn-heading{
    padding-left: 25px;
    padding-top: 20px;
    font-family: 'Nunito', sans-serif;
    font-size: 19px;
    font-weight: 700;
  }
  .btn-remove-customer img{
    margin-top: 30px;
  }
  .graph-btn{
    color: #EA5455 !important;
    border: solid 1px #EA5455 !important;
    font-weight: 700 !important;
    transform: scale(1);
    transition: all 0.5s;
    font-size: 15px !important;
  }
  .graph-btn:hover{
    transform: scale(1.1);
  }
  .anals-div-dp{
    min-width:14rem !important;
    right: 3px !important;
    padding:10px 15px !important;
  }
  .addtest-btn,
  .addmore-btn{
    background-color: #EA5455 !important;
    color: #fff !important;
    display: table;
    width: 143px;
    height: 44px;
    line-height: 31px !important;
    border-radius: 50px !important;
    transition: all 0.5s;
    font-weight: 700 !important;
    font-size: 15px !important;
  }
  .add-test-m{
    background-color: #EA5455 !important;
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    display: table !important;
    margin:35px auto !important;
    transition: all 0.5s;
  }
  .addmore-btn{
    margin-left: auto !important;
    display: table !important;
    margin-top:15px !important;
    
  }
  .addtest-btn:hover,
  .addmore-btn:hover,
  .add-test-m:hover{
    background-color: #000 !important;
  }
  .bulk-btn{
    background-color: #000 !important;
    color: #fff !important;
    display: table;
    width: 143px;
    height: 44px;
    line-height: 31px !important;
    border-radius: 50px !important;
    transition: all 0.5s;
    font-weight: 700 !important;
    font-size: 16px !important;
  }
  .bulk-btn:hover{
    background-color: #EA5455 !important;
  }
  .scd-off{
    position: relative;
  }
  .hr-text{
    background: #F0F0F0;
      padding: 6px 10px;
      position: absolute;
      right: 1px;
      bottom: 1px;
      border-radius: 0 5px 5px 0;
  }
  .cm-titel-modal{
    font-weight: 700;
  }
  .add-test-modal label{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .file-upesc{
    position: relative;
      display: inline-block;
      width: 100%;
  }
  .file-upload{
    border: dashed 1px #A8A8A8;
    position: relative;
      display: inline-block;
      width: 100%;
      height: 108px;
  }
  .file-upload__label img{
    margin-right: 5px;
  }
  .file-upload__label {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 30px;
    color: #000;
    font-size: 16px;
    left: 50%;
    padding: 5px 10px;
    cursor: pointer;
    outline: none;
    padding: 15px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 50%;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-user-select: none;
    white-space: nowrap;
    width: 200px;
    font-weight: 700;
  }
  
  .file-upload__input {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;    
    right: 0;
    top: 0;
    width: 100%;
  }
  .blulks-upload-modal .tab-table{
     border: none;
  }
  .totals-tabs-sec{
    background: #fff;
    border-radius: 10px;
    min-height: 830px;
    border: solid 1px #D2D2D2;
    overflow: hidden;
  }
  .totals-tabs-sec .nav-pills .nav-link{
    border-radius: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #181D27 !important;
    position: relative;
  }
  .totals-tabs-sec .nav-pills .nav-link:after{
    position: absolute;
    content: "\f105";
    right: 8px;
    top:30%;
    font-family: 'FontAwesome';
    font-size: 20px;
  }
  .totals-tabs-sec .nav-pills .nav-link .cm-se-menu b{
    color: #181D27 !important;
  }
  .totals-tabs-sec .nav-pills .nav-link .cm-se-menu{
    color: #ABABAB;
  }
  .totals-tabs-sec .nav{
    width: 32%;
    height: 830px;
  }
  .cm-se-menu{
    display: flex;
    text-align: left;
    
  }
  .cm-se-menu img{
    margin-right: 15px;
  }
  
  .totals-tabs-sec .nav-pills .nav-link.active, 
  .totals-tabs-sec .nav-pills .show > .nav-link{
    background: #FFF2F2 !important;
    border: none;
    color: #181D27 !important;
  }
  .edit-tabs-div{
    display: inline-block;
    width: 100%;
  }
  .totals-tabs-sec .tab-content{
     width: 68%;
     padding: 15px 25px;
  }
  .totals-tabs-sec .tab-content h2{
    font-weight: 700;
    font-size: 18px;
    margin: 0;
  }
  .totals-tabs-sec .tab-content hr{
    background-color: #C4C4C4;
  }
  .pssword-fom input{
    border: none;
  }
  .pssword-fom input:focus{
    box-shadow: none;
    outline: none;
  }
  .new-page-table{
    border-radius: 8px;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    padding: 20px;
  }
  .comon-day{
    font-size: 14px;
    color: #aaa;
    
  }
  .com-avil{
    font-size: 14px !important;
    
    display: inline-block;
    padding-left: 15px;
    color: #19B444;
    
  }
  
  .crm-table {
    table-layout: fixed;
    width: 100% !important;
    
  }
  .crm-table td,
  .crm-table th{
    width: 60px;
    border: solid 1px #dfdfdf !important;
    vertical-align: middle;
    height: 75px;
  }
  .crm-table td{
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .crm-table .border-0{
    border: none !important;
  }
  .crm-table .av-border-gren{
    border-left: solid 2px #19B444 !important;
    background: #EBFFEF;
  }
  .crm-table .red-td{
    border-left: solid 2px #F43F5E !important;
    background: #FFE4E6;
  
  }
  .blk-table td {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .crm-table tr{
    border-bottom: none;
  }
  .crm-table .red-td h4{
    color:#F43F5E !important ;
    font-size: 14px;
  }
  .slote-btn{
    background: #EA5455 !important;
    color: #fff !important;
    margin-top: 30px !important;
    transition: all 0.5s;
  }
  .slote-btn:hover{
    background: #000 !important;
  }
  .sumbit-btn1{
    background: #EA5455 !important;
    color: #fff !important;
  }
  .arrow-btn{
    background: #F4F4F5 !important;
  }
  .veiw-caln{
    width: 100%;
    overflow: auto;
    display: inline-block;
  }
  .cl-gray-cions{
    position: absolute;
    right: -49px;
    top: -13px;
  }
  .sp-h1 span{
    position: relative;
    top:-2px;
  }
  .pay-3{
    right: -60px;
  }
  .nw-table-add td{
    font-size: 13px;
  }
  
  .arrow-btn span{
    margin: 0 5px;
  }
  .cm-times{
    font-size: 13px !important;
  }
  .comon-day span{
    font-size: 25px;
    color: #000;
  }
  .submit-btn{
    background-color: #EA5455 !important;
    font-weight: 700 !important;
    color: #fff !important;
    transition: all 0.5s;
    margin-top: 15px;
  }
  .submit-btn:hover{
    background-color: #000 !important;
  }
  .edit-tabs-div label{
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .edit-tabs-div .form-group{
    margin-bottom: 20px;
  }
  .edit-tabs-div select{
    font-size: 14px !important;
  }
  .add-tabs-section .nav{
    width: 100%;
    height: auto;
  }
  .add-tabs-section .nav-pills .nav-link:after{
    display: none;
  }
  .add-tabs-section .nav-pills .nav-link{ 
    background: #FFF7F7 !important;
    color: #C39495 !important;
    font-size: 16px;
    font-weight: 700;
    height: 56px;
    width: 250px;
    text-align: center;
  }
  .add-tabs-section .nav-pills .nav-link.active, 
  .add-tabs-section .nav-pills .show > .nav-link{
    color: #fff !important;
    background: #FE0000 !important;
  }
  
  .add-tabs-section .nav-pills li:first-child .nav-link{
    border-radius: 50px 0 0 50px;
  }
  .add-tabs-section .nav-pills li:last-child .nav-link{
    border-radius: 0 50px 50px 0;
  }
  .add-user-section{
    width: 100%;
    display: inline-block;
  }
  .add-tabs-section .tab-content{
     width: 100%;
     display: inline-block;
     padding: 0;
  }
  .add-more-btn{
    background-color: #EA5455 !important;
    color: #fff !important;
    border-radius: 50px !important;
    font-weight: 600 !important;
    display: table !important;
    margin-left: auto !important;
    font-size: 15px !important;
  }
  .serach-div-input .form-group{
    background: #F5F5F5;
    border-radius: 50px;
    height: 40px;
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding:  0 20px;
  }
  .serach-div-input .form-group .form-control{
    background: none;
    border: none;
  }
  .comon-use-div{
    background: #fff;
    border-radius: 10px;
    width: 100%;
    height: 231px;
    overflow: hidden;
    display: grid;
    align-content: center;
    box-shadow: #b1b1b1 1px 2px 8px;
    padding: 0 15px;
  }
  .seletc-green-date-red{
    background: #F43F5E !important;
    color: #fff !important;
  }
  .seletc-green-date-red h4.com-avil{
    color: #fff !important;
  }
  .comon-use-div h5{
    font-weight: 700;
    font-size: 15px !important;
  }
  .comon-use-div ul{
    margin-top: 15px;
  }
  #tabs-bc-div1,
  #tabs-bc-div12,
  #tabs-bc-div3,
  #tabs-bc-div4,
  #tabs-bc-div5,
  #tabs-bc-div6{
    display: none;
  }
  .noshadow-dp .show-hover-td{
     min-width: 100%;
  }
  .form-check-input:focus{
    box-shadow: none !important;
    border-color:#bbb !important ;
  }
  .form-check-input:checked{
    border-color: #E35051 !important;
    background-color: #E35051 !important;
  }
  .comon-use-div ul li{
    margin-bottom: 8px;
    color: #696767;
    font-size: 14px;
  }
  .comon-use-div ul li span{
    display: inline-block;
    width: 100px;
  }
  .add-view-brunch .comon-use-div{
    height: 361px !important; 
  }
  .login-n-btn{
    background: #22C55E !important;
    color: #fff !important;
    border-radius: 30px !important;
    font-size: 14px !important;
  }
  .reel-div h5{
    font-weight: 700;
    font-size: 16px;
  }
  .inside-table-div li{
    font-size: 11px !important;
  }
  .comon-ruels-div{
    margin-bottom: 30px;
  }
  .ne-sp-grph{
  
  }
  .clean-btn{
    right: -68px;
  }
  .show-hover-td{
    min-width: 264px;
    padding:5px 10px;
  }
  .show-hover-td p{
    font-size: 14px;
    color: #3C3B3B;
  }
  
  
  .dropdown-menu1{
    top: 80px;
    opacity: 0;
    transition:top 0.8s ease;
    z-index: 1;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
    display:none !important;
    right: 60px;
    
    
  }
  .dropdown:hover>.dropdown-menu1{top:100%;height:inherit;
    opacity:inherit; opacity: 1; display: block !important; left: 0 !important;}
  .edit-new-form .new-raister-add > div{
    flex-wrap: nowrap;
  }
  .edit-new-form .new-raister-add > div .col{
    padding: 0 6px;
  }
  .edit-new-form label{
    font-size: 11px !important;
    margin-bottom: 0;
  }
  .edit-new-form .form-group{
    
    align-items: center;
  }
  .edit-new-form .tg-new-btn{
    width: 30px;
  }
  .dropdown:hover>.dropdown-menu1{top:100%;
    height:inherit;opacity:inherit; opacity: 1; display: block;}
  
  .dropdown-menu1:before {
      position: absolute;
      top: -10px;
      width: 10px;
      left: 20px;
      height: 10px;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      border-bottom: solid 10px #fff;
      content: '';
  }
  .comon-sp-btn{
    font-size: 13px !important;
  }
  .noshadow-dp{
    border: none !important;
    box-shadow: #A0A0A0 1px 4px 8px !important;
    min-width: 228px !important;
  }
  .show-hover-td span{
    font-size: 13px !important;
    color: #A5A5A5;
  }
  .help-num{
    width: 100%;
    display:grid;
    align-content: center;
    border-radius: 8px;
    background: #FFEDED;
    height: 85px;
    padding: 0 20px;
  }
  .help-num h5 {
    color: #000;
    font-weight: 700;
    margin-left: 18px;
    margin-bottom: 0;
    font-size: 16px;
  }
  .help-num h5 span{
    font-size: 14px;
    font-weight: 400;
  }
  .help-num h5 span b{
    color: red;
  }
  .support-sec-div{
    background: #fff;
    border-radius: 8px;
    display: inline-block;
    width: 100%;
    padding: 30px;
  }
  .support-sec-div label{
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 15px;
  }
  .support-sec-div .form-group{
    margin-bottom: 25px;
  }
  .support-sec-div textarea.form-control{
    resize: none;
    height: 100px;
  }
  .closap-logo{
    display: none;
  }
  .rvm-btn{
    margin-top: 26px;
      display: inline-block;
      cursor: pointer;
  }
  .customer_records_dynamic .sp-rm-div{
    display: none;
  }
  .comon-chart-globe, .map-div-main,
  .booking-chart, .revenu-div{
    height: 383px;
  }
  .dt-from{
    padding:2px 10px 0 10px !important;
  }
  .dt-from span{
    border-bottom: none !important;
  }
  #back-btn1{
    display: none;
  }
  #serch-div-top{
    display: none;
  }
  .speacial-accept{
    margin-left: 56px;
  }
  .speacial-accept .iocn-cm{
    background: #22C55E;
  }
  .speacial-accept .cm-disable .iocn-cm{
    background: #F4F4F4;
  }
  .speacial-accept .comon-status h5{
    font-size: 12px;
  
  }
  .back-tn{
    text-decoration: none;
    color: #000;
  }
  .crm-newadd{
    font-size: 16px;
  }
  .crm-newadd span{
    margin-left: 5px;
    color: #AAAAAA;
  }
  .speacial-accept .comon-status:after {
    position: absolute;
    content: "";
    width: 57px;
    height: 1px;
    background: #454845;
    right: -41px;
    top: 25%;
  }
  .cm-link-a{
    color: #A0A0A0;
    font-size: 13px;
    font-weight: 400;
  }
  .serach-btntp{
    background: #EA5455 !important;
    color: #fff !important;
    border-radius: 0 8px 8px 0 !important;
  }
  .serch-sec-div .form-control{
    border-radius: 8px 0 0 8px !important;
  }
  #packgae-menu{
    display: none;
  }
  .serch-sec-div{
  
  }
  #packages-table{
    display: none;
  }
  .rd-delet-icon{
    position: relative;
    top:-3px;
  }
  .new-table-cancel .tbl-re, 
  .new-table-cancel .tbl-re-dl{
     width: 100%;
  }
  .new-table-cancel .tab-table tr:hover, 
  .new-table-cancel .tab-table  td:hover{
     background:none !important;
  }
  .ds-calender .e-input-group-icon{
    height: 100%;
  }
  .cla-btn{
    position: relative;
  }
  .e-input-group-icon {
    min-height: 50px !important;
    min-width: 50px !important;
    top:0 !important
  }
  .login-dc-btn{
    text-decoration: none !important;
    color: #ea5455!important;
  }
  .login-dc-btn a{
    text-decoration: none !important;
    color: #ea5455!important;
  }
  .dt-from .e-input-group-icon{
    min-height: 24px !important;
    min-width: 24px !important;
  }
  .paynew-btn1{
    border: solid 1px #E0DEDE !important;
    width: 92px;
    height: 30px;
    text-align: center;
    line-height: 14px !important;
    background: #fff !important;
  }
  .new-align-table .crm-btn .track-btn{
    height: 30px;
    line-height: 17px !important;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
     
  }
  .new-align-table .crm-btn .cancel-td{
    line-height: 17px !important;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 58px;
    margin-top: 0 !important;
  }
  .new-align-table .tbl-re .td{
  width: 38px;
  }
  .cl-gray-cions{
    position: absolute;
    overflow: hidden;
  }
  .cl-gray-cions .e-input-group {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top:0;
  }
  #tabs1-f,
  #tabs2-f,
  #tabs3-f,
  #tabs4-f,
  #tabs5-f,
  #tabs6-f,
  #tabs9-f,
  #tabs8-f,
  #tabs7-f{
    display: none;
  }
  .new-graph{
    display: inline-block;
    width: 100%;
    margin-top: 65px;
  }
  .ne-two-gf{
    margin: auto;
    display: table;
    margin-top: -10px;
    width: 570px;
    height: 315px;
    overflow: hidden;
  }
  .full-graph-new{
    width: 100%;
    margin: auto;
    display: table;
    margin-top: -10px;
    height: 315px;
    overflow: hidden;
  }
  .lashbord-page-dashoard .list-count li{
    text-align: right;
  }
  .ds-graph{
    margin: auto;
    display: table;
    margin-top: -10px;
    width: 550px;
    height: 413px;
    overflow: hidden;
  }
  .ne-two-gf img{
    object-fit: cover;
    width: 96% !important;
    height: 100%;
  }
  .ds-graph img{
    width: 96%;
    height: 100%;
  }
  
  .crm-dp-div2 p{
    margin-bottom: 0 !important;
  }
  .targetnew,
  .targetDiv2{
    padding: 0 !important;
  }
  .new-graph img{
    width: 100%;
  }
  
  .view-dc{
    text-decoration: none;
    color: #fff !important;
  }
  .customcl1{
    right: 30px !important;
    left: inherit !important;
  }
  .customcl1.e-daterangepicker{
    top:215px !important;
  }
  .date-cancelpage1{
    left: 0;
    text-decoration: none;
  }
  .admin-booking-table .tbl-re{
  
    display: flex;
    justify-content: center;
  
  }
  .form-select:focus{
    box-shadow: none !important;
  }
  .admin-booking-table .tbl-re-dl{
    display: flex;
    justify-content: center;
  }
  .admin-booking-table .tbl-re-dl .td{
     display: flex;
     justify-content: center;
     width: 155px;
     align-items: center;
  }
  .admin-booking-table .tbl-re .td{
    display: flex;
    justify-content: center;
    width: 155px;
    text-align: center;
  }
  .specail-lable-modal label{
    font-size: 14px;
  
  }
  .specail-lable-modal .from-group{
    margin-bottom: 15px !important;
    display: inline-block;
    
  }
  .bulk-table td{
    font-size: 14px;
  }
  .comon-td-dp{
    padding: 0 !important;
  }
  .bulk-table th{
    vertical-align: top;
  }
  .new-ds{
    padding: 0 !important;
  }
  @media screen and (max-width: 991px) {
    
  
    .plans .plan {
      width: 100%;
    }
  
    .plan.complete-plan {
      margin-top: 20px;
    }
  
    .plans .plan .plan-content .plan-details {
      width: 70%;
      display: inline-block;
    }
  
    .plans .plan input[type="radio"]:checked + .plan-content:after {
      top: 45%;
      -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);
    }
  }
  
  @media screen and (max-width: 767px) {
    .plans .plan .plan-content .plan-details {
      width: 60%;
      display: inline-block;
    }
  }
  
  @media screen and (max-width: 540px) {
    .plans .plan .plan-content img {
      margin-bottom: 20px;
      height: 56px;
      -webkit-transition: height 0.4s;
      -o-transition: height 0.4s;
      transition: height 0.4s;
    }
  
    .plans .plan input[type="radio"]:checked + .plan-content:after {
      top: 20px;
      right: 10px;
    }
  
    .plans .plan .plan-content .plan-details {
      width: 100%;
    }
  
    .plans .plan .plan-content {
      padding: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
    }
  }
  
  /* inspiration */
  .inspiration {
    font-size: 12px;
    margin-top: 50px;
    position: absolute;
    bottom: 10px;
    font-weight: 300;
  }
  
  .inspiration a {
    color: #666;
  }
  @media screen and (max-width: 767px) {
    /* inspiration */
    .inspiration {
      display: none;
    }
  }
  
  @media (min-width: 992px) {
    .col-lg-3 {
      padding-right: 7.5px; /* Half the default padding */
      padding-left: 7.5px; /* Half the default padding */
    }
    .sidebar-menu {
      position: fixed;
      top:0;
      left: 0;
      width: 17%;
      height: 100vh;
      margin-top:0;
      background: #fff;        z-index: 99;
      box-sizing: border-box;
      overflow: hidden;
      
    }
    #navAccordion.active .logo-admin .closap-logo {
      display: block;
      width: 50px;
  }
    .navbar-expand-lg .sidenav {
      flex-direction: column;
    }
    .full-width {
      margin-left: 0;
      width: 100% !important;
      padding-left:70px;
  }
  
   
    
  }
  
  
  @media (min-width: 1900px) {
   /* .sidebar-menu,
    .sidebar-menu{
      width:10% !important;
    }
    .dasb-div{
      padding-left: 73px;
    }
    .content-wrapper{
      padding-left:317px;
    }
    .top-bar-menu{
      width: 90% !important;
    }
    .top-bar-menu{
      padding-left: 25px;
    }*/
    
    
    .list-count{
      font-size: 16px;
    }
    .booking-chart, .revenu-div {
      height: 431px;
    }
    .comon-chart-globe, .map-div-main{
      height: 427px;
    }
    .tab-table{
      height: 487px;
    }
    .comon-chart-globe .recharts-legend-wrapper{
      bottom: 145px !important;
    }
    .date-tx {
      width: 68%;
    }
    .list-count {
      width: 32%;
      display: grid;
      justify-content: flex-end;
    }
    .list-count li {
      font-size: 13px;
    }
    .date-tx h5 span{
      font-size: 14px;
    }
    .icon-f{
      width: 30px;
      height: 30px;
    }
    .icon-f img{
      width: 70%;
      margin: auto;
    }
    
  }
  
    .footer {
      width: calc(100% - 230px);
      margin-left: 230px;
    }
    @media (min-width: 1700px) {
      .dasb-div{
    /*    padding-left: 125px !important;*/
      }
    }
    @media (min-width: 1600px) {
      .booking-chart, .revenu-div{
        height: 448px;
      }
    
      .recharts-wrapper{
        height: 380px !important;
      }
    
      /*
      .content-wrapper{
        padding-left: 202px;
      }
      .sidebar-menu, .sidebar-menu {
        width: 12%;
    }
    .top-bar-menu {
      width: 86%;
  }*/
  .comon-chart-globe, .map-div-main {
    height:448px;
  }
    }
    @media (min-width: 1366px) {
      .dasb-div{
        padding-left:70px;
      }
      .sp-div-top1{
        padding-top:73px;
      }
    }
  
    @media (max-width: 1919px) {
      .date-tx {
        width: 82%;
       }
      
       .date-tx h5 span{
         font-size: 13px;
       }
       .icon-f{
         width: 30px;
         height: 30px;
       }
       .icon-f img{
         width: 70%;
         margin: auto;
       }
    }
    @media (max-width: 1440px) {
      .date-tx {
        width:60%;
      }
      .list-count {
        width: 40%;
        display: grid;
        justify-content: flex-end;
      }
    
    }
    @media (max-width: 1180px) {
      .sidebar-menu{
        width: 200px;
      }
      .content-wrapper{
        padding-left:197px;
      }
      .qty-input{
        width: 70px;
      }
      .add-tabs-section .nav-pills .nav-link{
        width: 182px;
      }
      .comon-use-div{
        height: auto;
        padding-top: 10px;
      }
  
    }
    @media (max-width: 1080px) {
  
      .cm-heading{
        font-size: 14px;
      }
      
      .date-tx h5 span {
          font-size: 11px;
      }
      .comon-chart-globe, .map-div-main{
        height: 430px;
      }
    }
    @media (max-width:1024px) {
      .comon-overview ul li{
         font-size: 14px;
      }
      .date-tx {
        width: 52%;
      }
      .top-bar-menu{
        width: 81%;
      }
      .sidebar-menu {
        width: 196px;
      }
    }
    @media (max-width: 900px) {
      .content-wrapper {
        margin-left: 0;
        padding-left: 0;
      }
      .sidebar-menu{
        width: 100%;
      }
      .inside-comon-div{
        padding-left: 50px;
        padding-right: 50px;
      }
      .logo-admin{
        justify-content: space-between;
        padding:10px 20px;
      }
      .show-date-details{
        height: auto;
        padding: 20px;
        display: inline-block;
        width: 100%;
      }
      .show-date-details h6{
        width:180px;
      }
      .recharts-legend-wrapper{
        width: 100% !important;
      }
      .comon-chart-globe, .map-div-main,
      .booking-chart, .revenu-div{
        height: auto;
      }
      .table-sec-div .tbody, .table-sec-div td, .table-sec-div tfoot, .table-sec-div th, .table-sec-divthead, .table-sec-div tr{
        font-size: 9px;
      }
      .st-text-s,
      .st-text-c,
      .st-text-sb,
      .st-text-cl,
      .track-btn,
      .view1-btn {
        font-size: 9px !important;
      }
      .dasb-div{
        padding-left: 0;
        padding-top: 20px;
        padding-right: 0;
      }
      .gp-button .download-btn,
      .edit-btn,
      .filter-btn{
        font-size: 14px;
      }
      .qty-input{
        width: 80px;
      }
      .totals-tabs-sec .nav,
      .totals-tabs-sec .tab-content{
        width: 100%;
        height: auto;
      }
      .add-tabs-section .nav-pills .nav-link{
        width: 142px;
      }
      .comon-use-div,
      .add-view-brunch .comon-use-div{
        height: auto !important;
        margin-bottom: 20px;
      }
      .comon-use-div .edit-btn{
        margin-top: 30px;
      }
      .left-history ul li{
        margin-bottom: 10px;
      }
      .crm-table {
        table-layout: fixed;
        width: 950px !important;
      }
  
      .table-sec-div{
        overflow-x: auto;
      }
    }
   @media (max-width: 820px) {
    .qty-input{
      width: 59px;
    }
    .sp-admintable{
      height: auto;
    }
    
    }
    @media (max-width: 768px) {
      .col-md-3 {
        padding-right: 7.5px; /* Half the default padding */
        padding-left: 7.5px; /* Half the default padding */
      }
      .qty-input{
        width: 56px;
      }
      .test-inside-div .form-check {
        width: 81px;
      }
      }
    
    @media (max-width: 667px) {
      .recharts-wrapper{
        height: 320px !important;
      }
      .qty-input{
        width: 100px;
        justify-content: center;
      }
      .cunt-div .row > div:last-child .comon-count{
        justify-content: flex-start;
      }
      .cunt-div .row{
        border: none;
      }
      .cunt-div .row > div .comon-count:after{
        display: none;
      }
      .test-inside-div .form-check{
        width: 100%;
      }
      .cunt-div .row > div:first-child {
        padding-left:15px;
      }
      .adbenf-btn{
        justify-content: flex-start;
        margin-left: 0;
      }
      .add-move .form-group{
        margin-bottom: 15px;
  
      }
      .comon-count{
        margin-bottom: 20px;
      }
      .cunt-div{
        padding: 10px 5px;
      }
      .wizard-fieldset label{
        font-size: 12px;
      }
      .cm-slots{
        width: 100%;
        margin-bottom: 25px;
      }
      .btn-group, .btn-group-vertical{
        display: inline-block;
      }
      .download-btn, .downlaod-file-btn{
        font-size: 14px !important;
      }
      .common-list{
        padding-left: 10px;
      }
      .help-num{
        height: auto;
        padding: 25px;
      }
      .help-num h5{
        margin-left: 0;
      }
      .form-wizard .form-wizard-steps li{
        width: 31%;
      }
      .inside-comon-div {
        padding-left: 0;
        padding-right: 0;
      }
      .sp-padding{
        padding-left: 20px;
        padding-right: 20px;
      }
      .submit-btn-1{
        float: right;
        top:0;
      }
      .cl-gray-cions{
        right: 0;
      }
    }
    
    @media (max-width: 480px) {
    .ts-performance .recharts-legend-wrapper{
      position: relative !important;
      left: 50%;
      top:inherit !important;
    }
  
    .custome-div1 .recharts-legend-wrapper{
      position: relative !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      transform: none;
      top:inherit !important;
      margin: auto !important;
    }
    .new-dit-add .head-text-sp{
      top:70px;
    }
    .comon-count,
    .cunt-div .row > div:last-child .comon-count{
      display: flex;
    }
    .cunt-div .row > div:last-child {
      padding-right: 13px;
  }
  
    .download-btn, .downlaod-file-btn,
    .filter-btn{
      display: flex !important;
      align-items: center !important;
      font-size: 11px !important;
      padding: 5px 9px !important;
      width: 93px;
      justify-content: center;
    }
    .edit-btn{
      display: flex !important;
      align-items: center !important;
      font-size: 11px !important;
      padding: 5px 9px !important;
    }
    .cm-width{
      width: 100px;
      display: table;
    }
    .st-text-s,
    .st-text-c,
    .st-text-sb,
    .st-text-cl{
      width: 64px;
    }
    .sp-mobilemenu{
      width: 42px;
      display: inline-block;
    }
  
    .table-responsive{
      table-layout: fixed;
      width: 660px !important;
    }
    .comon-input-test {
      display: inline-block;
      margin-right: 10px;
      width: 22%;
  }
  .new-lable{
    font-weight: 500 !important;
    font-size: 12px;
      font-weight: 500;
  }
  .custome-div1 .recharts-surface {
    position: relative !important;
    top: 50%;
    margin: auto;
    left: 50%;
    transform: translate(-50%, -34%);
  }
  .custome-div1 .recharts-legend-item .recharts-surface {
    position: relative;
    transform: none;
    margin: 0;
    left: 0;
  }
  
    }
  
  
    .mobile-menu-sec .active-m a {
      background: rgba(255,0,0,7%);
      color: #fff;
     }
     .logo-side{
      background: rgb(254,0,0);
      display: inline-block;
      width: 100%;
      padding: 15px 10px;
      text-align: center;
      background: linear-gradient(to right, rgba(254,0,0,1) 0%,rgba(209,2,2,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fe0000', endColorstr='#d10202',GradientType=1 );
     }
    .mobile-menu-sec li a, .mobile-menu-sec li .dropdown .btn {
      text-decoration: none;
      color: #212529;
      padding: 10px 5px 10px 10px;
      display: block;
      background: #f7f7f7;
      margin-bottom: 3px;
      width: 100%;
      text-align: left;
  }
  
  button.close-menu {
    color: #fff;
    background: #212529;
    border-radius: 5px;
    display: block;
    margin: 40px 0 0 0;
    width: 100px;
  }
  
  .new-lable{
    font-weight: 500 !important;
    font-size: 15px !important;
    font-weight: 500;
    margin-bottom: 0;
  }
  
  
  .mobile-menu-div2 {
    width: 340px !important;
  }
  .table-sec-div {
    width: 100%;
  }
  /*Card Form CSS Start*/
  .card-form-container {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-form {
    display: block;
    width: 450px;
    margin: auto;
    background: #fefefe;
    box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
    padding: 25px 30px;
    border-radius: 15px;
    transition: all 0.3s;
  }
  
  .expiry-and-cvc-container {
    display: flex;
  }
  
  .expiration-date-field {
    display: inline-block;
    flex: 1 1 auto;
  }
  
  .cvc-field {
    display: inline-block;
    flex: 0 0 100px;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  
  
  #card-details{
    margin:10px;
  }
  
  /*Card Form CSS End*/
  .rsidebar123 {
      position: fixed;
      top: 100px; /* Adds space at the top */
      right: 0;
     
      bottom: 100px; /* Adds space at the bottom */
      width: 60px; /* Width when collapsed */
      background-color: #333;
      z-index: 1000; /* High z-index to overlay content */
      transition: width 0.3s ease; /* Smooth transition for collapsing */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-top-left-radius: 30px; /* Curved top corner */
      border-bottom-left-radius: 30px; /* Curved bottom corner */
  }
  .rsidebar {
    position: fixed;
    top: 120px;
    right: 0; padding-top: 100px;
    bottom: 20px;
    width: 64px;
    background-color: #C4C4C4;
    z-index: 1000;
    transition: width 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
   /* box-shadow: -2px 0 8px rgb(221 205 205 / 50%);*/
  }
  
  .rsidebar.expanded {
      width: 200px; /* Width when expanded */
  }
  
  .ricon-strip {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px; /* Give space for the toggle button */
  }
  
  .ricon, .toggle-button {
    margin: 0px 0;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #676767;
    /* font-size: 24px; */
    /* padding: 10px; */
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
    fill: #FFFFFF;
  }
  
  .toggle-button {
    position: absolute; 
      top: 90px;
    right: 2px;
  }
  span.shape-top, span.shape-bottom {
      display: none;
  } 
  .input-icon {
      position: relative;
      display: inline-block;
  }
  
  .input-icon input {
      padding: 8px 20px 8px 40px; /* Adjust padding to make space for the icon */
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 200px;
  }
  
  .input-icon .icon {
      position: absolute;
      left: 10px; /* Adjust based on your padding */
      top: 50%;
      transform: translateY(-50%);
      color: #aaa;
  }
  
  /* Apply CSS properties to ui-widgets class */
  .ui-widgets {
      position: relative;
      display: inline-block;
      width: 5rem;
      height: 5rem;
      border-radius: 4rem;
      margin: 3.5rem;
      border: 1.2rem solid palegreen;
      box-shadow: inset 0 0 0px grey;
      border-left-color: palegreen;
      border-top-color: chartreuse;
      border-right-color: darkgreen;
      border-bottom-color: white;
      text-align: center;
      box-sizing: border-box;
  }
   
          /*  Apply css properties to the second 
              child of ui-widgets class */
          .ui-widgets:nth-child(2) {
              border-top-color: chartreuse;
              border-right-color: white;
              border-left-color: palegreen;
              border-bottom-color: white;
          }
    
          /*  Apply css properties to ui-widgets 
              class and ui-labels class*/
          .ui-widgets .ui-labels {
   
              left: 0;
              bottom: -16px;
              text-shadow: 0 0 4px grey;
              color: black;
              position: absolute;
              width: 100%;
              font-size: 16px;
          }
      
  @property --progress-value {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
  }
  
  @keyframes progress {
   to { --progress-value: 75; }
  }
  
  /*.progress-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
      conic-gradient(hotpink calc(var(--progress-value) * 1%), pink 0);
      animation: progress 2s 1 forwards;
  }
  
  .progress-bar::before {
    counter-reset: percentage var(--progress-value);
    content: counter(percentage) '%';
    animation: progress 2s 1 forwards;
  }
  .progress-bar .p-values {
      font-weight: 800;
      font-size: 1rem;
      color: black;
  }*/
  
  .expandable_right_sidebar {
    position: fixed;
    top: 120px;
    bottom: 10%;
    right: 0;
    /* width: 61px; */
    height: 100%;
    /* background-color: #f4f4f4; */
    /* overflow: auto; */
    padding: 10px;
    box-shadow: -3px 0 5px rgba(0,0,0,0.1);
  }
  
  /* Styling the paragraph inside the sidebar */
  .expandable_right_sidebar p {
    margin: 0; /* Removes default margin */
  }
  
  .circle_progress {
    width: 50px;              /* Specify the diameter of the circle */
    height: 50px;             /* Height must be the same as the width for a perfect circle */
    border: 3px solid #4CAF50; /* Adjust the color and width of the border */
    color: #4CAF50;            /* Text color, same as border for consistency */
    background-color: transparent; /* Make the background transparent */
    border-radius: 50%;        /* This turns the square div into a circle */
    display: flex;             /* Flexbox to help with centering */
    justify-content: center;   /* Center horizontally */
    align-items: center;       /* Center vertically */
    font-size: 20px;           /* Optional: adjust font size as needed */
    font-weight: bold;         /* Optional: bold text */
  }
  .cinfo p {
  
   
  }
  .course_tbl{
    border: 2px dashed #817d7d;
      padding: 0px;
      margin: 5px 0;
      background-color: #f9f9f9;
      border-radius: 8px;
  }
  
  /*New CSS*/
  .frame-2 .container {
    margin: 0 15.7px 42.5px 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 443.2px;
    box-sizing: border-box;
  }
  .frame-2 {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 149px 0 44px 0;
    width: 1920px;
    box-sizing: border-box;
  }
  .frame-2 .group-2 {
    border-radius: 20px;
    background: rgba(229, 229, 229, 0.5);
    position: relative;
    margin: 0 0.9px 16px 1px;
    padding: 22px 24px 21px 24px;
    width: fit-content;
    box-sizing: border-box;
  }
  .frame-2 .email-id-or-phone-number {
    overflow-wrap: break-word;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.42);
  }
  .frame-2 .group-5 {
    border-radius: 20px;
    background: rgba(229, 229, 229, 0.5);
    position: relative;
    margin: 0 26.9px 21px 0;
    display: flex;
    padding: 22px 0 18px 6.6px;
    width: 104px;
    box-sizing: border-box;
  }
  .frame-2 .logo {
    overflow-wrap: break-word;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.42);
  }
  .frame-2 .group-3 {
    border-radius: 20px;
    background: rgba(229, 229, 229, 0.5);
    position: relative;
    margin: 0 0.9px 11.5px 1px;
    padding: 21.5px 24px;
    width: fit-content;
    box-sizing: border-box;
  }
  .frame-2 .password {
    overflow-wrap: break-word;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.42);
  }
  .frame-2 .forget-password {
    overflow-wrap: break-word;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.42);
  }
  .frame-2 .remember-me {
    margin-right: 11px;
    width: 275px;
    overflow-wrap: break-word;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.42);
  }
  .frame-2 .container {
    /*background: url('images/Image20.jpeg') 50% / cover no-repeat;*/
    margin-bottom: 7px;
    width: 667px;
    height: 667px;
  }
  .frame-2 .group-4 {
    border-radius: 20px;
    background: rgba(229, 229, 229, 0.5);
    position: relative;
    margin: 0 0.9px 32px 1px;
    display: flex;
    flex-direction: row;
    padding: 21.5px 0;
    width: 465px;
    box-sizing: border-box;
  }
  .frame-2 .log-in {
    margin-right: 18.9px;
    overflow-wrap: break-word;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.42);
  }
  .frame-2 .arrow-1 {
    background: rgba(0, 0, 0, 0.46);
    margin: 10.5px 0 9.5px 0;
    width: 24px;
    height: 2px;
  }
  .frame-2 .group-7 {
    position: relative;
    margin: 0 3.9px 8px 3px;
    display: flex;
    padding-right: 1px;
    width: 460px;
    box-sizing: border-box;
  }
  .frame-2 .line-1 {
    background: #E5E5E5;
    position: absolute;
    left: 50%;
    top: 22px;
    translate: -50% 0;
    width: 460px;
    height: 1px;
  }
  .frame-2 .or-sign-in-with {
    overflow-wrap: break-word;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.42);
  }
  .frame-2 .powered-by-world-space-council-dyanmic-footer {
    margin-left: 65.6px;
    overflow-wrap: break-word;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.42);
  }
  .frame-2 .tag-line {
    margin: 0 1px 40px 0;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    color: #000000;
  }
  .frame-2 .group-9 {
    margin-right: 0.9px;
    display: flex;
    flex-direction: row;
    width: 236px;
    box-sizing: border-box;
  }
  .frame-2 .group-8 {
    display: flex;
    width: 102px;
    height: 57px;
    box-sizing: border-box;
  }
  .frame-2 .image-1 {
    background: url('images/Image11.png') 50% / cover no-repeat;
    width: 102px;
    height: 57px;
  }
  .frame-2 .group-81 {
    margin: 5px 57px 8px 0;
    display: flex;
    width: 77px;
    height: 44px;
    box-sizing: border-box;
  }
  .frame-2 .image-2 {
    background: url('images/Image2.png') 50% / cover no-repeat;
    width: 77px;
    height: 44px;
  }
  .by-continuing-you-agree-to-amazons-conditions-of-useand-privacy-notice {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    line-height: 1.333;
    color: rgba(0, 0, 0, 0.78);
  }
  .by-continuing-you-agree-to-amazons-conditions-of-useand-privacy-notice-sub52 {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    text-decoration-line: underline;
    line-height: 1.333;
    color: rgba(0, 0, 0, 0.78);
  }
  .by-continuing-you-agree-to-amazons-conditions-of-useand-privacy-notice-sub53 {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    text-decoration-line: underline;
    line-height: 1.333;
    color: rgba(0, 0, 0, 0.78);
  }
  .by-continuing-you-agree-to-amazons-conditions-of-useand-privacy-notice-sub54 {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    line-height: 1.333;
    color: rgba(0, 0, 0, 0.78);
  }
  .frame-2 .by-continuing-you-agree-to-amazons-conditions-of-useand-privacy-notice {
    margin-bottom: 31px;
    overflow-wrap: break-word;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.78);
  }
  .frame-2 .container-1 {
    margin: 42px 235.1px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 466.9px;
    box-sizing: border-box;
  }
  .frame-2 .container-2 {
    background: #FFFFFF;
    position: relative;
    display: flex;
    padding: 9px 1.1px 25px 0;
    width: 263px;
    height: fit-content;
    box-sizing: border-box;
  }
  .frame-2 .container-3 {
    margin-bottom: 191px;
    display: flex;
    flex-direction: row;
    width: fit-content;
    box-sizing: border-box;
  }
  img.astronotsimg {
    position: relative;
    right: -34px;
    bottom: -164px;
  }
  
  
  /**********new updated css**/
  .top-bar-menu.shadow.float-start {
    background: #f7f7f7;
    box-shadow: none !important;
  }div#navAccordion {
    background: #f7f7f7;
  }main.body-total.content-wrapper.float-start.w-100 {
    background: #fff;
  }
  
  span.notification-count {
    color: #fff;
    width: 21px;
    height: 21px;
    background: #EB3B3B;
    gap: 0px;
    border-radius: 100%;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    display: block;
    position: absolute;
    right: -11px;
    top: -3px;
  }
  
  .user-div-top.d-flex.align-items-center {
    gap: 12px;
  }
  
  figure.tc-bg {
    background: #0FC06B;
    border-radius: 3.79px;
  }
  
  figure.ac-bg {
    background: #1BB9F4;
    border-radius: 3.79px;
  }
  
  figure.as-bg {
    border-radius: 3.79px;
    background: #2163D5;
  }
  
  figure.tca-bg {
    background: #1BB9F4;
    border-radius: 3.79px;
  }
  
  .date-tx figure img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(51%) saturate(0%) hue-rotate(33deg) brightness(109%) contrast(101%);
  }
  .cinfo-count {
    border-bottom: 0.5px solid #EFEFEF;
  }
  
  .cinfo-count p {
    font-family: Kumbh Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.96px;
    letter-spacing: 0.009478683583438396px;
    text-align: left;
  }
  
  .cinfo-count p span {
    font-family: Kumbh Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 18.96px;
    letter-spacing: 0.009478683583438396px;
    text-align: left;
  }.cinfo-value p {
    font-family: Inter;
    font-size: 9px;
    font-weight: 300;
    line-height: 18.96px;
    text-align: left;
  }.cinfo-value {
    padding-top: 10px;padding-left: 24px;
  }
  .calander_box {
    background: #D9D9D926;
    gap: 0px;
    border-radius: 20px;
    padding: 7px;
    margin-top: 6px;
  }
  
  
  
  .calander_box .calander {
    margin: 0;
    justify-content: space-between;
  }
  
  .calander_box .calander .calander_left_section {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    margin: 0;
  }
  
  .calander_box .calander_right_section {
    display: flex;
    gap: 24px;
  }
  
  .calander_box .calander_right_section button {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 18px;
    font-weight: normal;
  }
  
  
  
  .calander_box .calander_grid:last-child {
    margin-top: 0 !important;
    padding-top: 0;
  }
  
  .calander_box .calander_grid span {
    height: auto;
    width: auto;
    border-radius: 0;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    text-align: center;
    background: transparent;
    margin: 5px 0;
  }
  .right-course-list .coursesbody {
      border: 2px dashed #122D4B4D;
      padding: 16px;
      margin-top: 20px;
  }
  
  
  .course-table-block .course-table-view {
    border: 2px dashed #122D4B4D;
    border-radius: 10px;
    padding: 16px;
    margin-top: 16px;
  }
  
  .course-table-view table.table {
    margin: 0 !important;
  }
  
  .course-table-view table.table thead {
    border-radius: 8px 8px 0px 0px !important;
    border: 0.45px solid #DADADA;
  }
  
  .course-table-view table.table thead th {
    background: #F4F4F4 !important;
    font-family: Kumbh Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 26.32px;
    text-align: left;
  }
  
  .course-table-view table.table td {
    font-family: Kumbh Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;     vertical-align: middle;
    text-align: left;    padding: 15px 10px;
    color: #716c6c;
  }
  .course-table-view table.table td a {
    font-family: Kumbh Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;     vertical-align: middle;
    text-align: left;   
    color: #716c6c;
  }
  .dashboard-card-list .card {
    width: 100%;
    padding: 24px;
    margin: 0;
    border: 1px solid #DEDEDE;
    box-shadow: none;
    text-align: left !important;
  }
  
  .row.dashboard-card-list {
    margin: 26px -6px 26px -20px;
  }
  .dashboard-card-list .card .card-icon {
    background: #F6F6F6;
    width: 40px;
    height: 40px;
    align-items: center;
    padding: 0;
    border-radius: 100%;
    font-size: 13px;
    color: #333;
  }.coursesbody.trc1 {
      border: none;
      padding: 0;
  }
  .right-course-list .coursesbody ul.courselist {
    margin: 0 !important;
  }
  .dashboard-card-list .card .card-content {
    padding: 8px 0 12px;
    text-align: left;
  }
  
  .dashboard-card-list .card .card-content h2.heading {
    font-family: Kumbh Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    margin: 0;
  }
  
  .dashboard-card-list .card .card-content .time {
    background: #EAF2FF;
    padding: 6px 8px;
    font-family: Inter;
    font-size: 10px;
    font-weight: 400;
    line-height: 8px;
    text-align: left;
  }
  
  .dashboard-card-list .card .card-content p {
    font-family: Kumbh Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin: 0;
  }
  
  .dashboard-card-list .card .card-footer {
    padding: 0;
    background: transparent;
    border-radius: 0;
    border: none;
  }
  
  .dashboard-card-list .card .card-footer button.view-button {
    padding: 0px 20px 0px 20px;
    border-radius: 16px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 44px;
    height: 44px;
    text-align: center;
  }
  .coursesbody ul.courselist li.list-item {
      background: transparent;
      border: 0.5px solid #DEDEDE;
      padding: 20px;
      margin-bottom: 14px;
  }
  
  .coursesbody ul.courselist li.list-item .header {
      font-family: Kumbh Sans;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
      margin: 0px;
  }
  
  .coursesbody ul.courselist li.list-item  .body {
      padding: 20px  0 0!important;
      margin: 0;
  }
  .row.course-table-block {
    padding-right: 15px;
  }
  .coursesbody ul.courselist li.list-item .body > div >div {
    font-family: Kumbh Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }.coursesbody ul.courselist li.list-item .body .photo-circles {
    margin-top: 30px;
  }
  
  
  
  .row.course-table-block {
    padding-right: 15px;
  }
  
  .course-table-view table.table td button.view1-btn {
    border: none !important;
    padding: 0 !important;
    font-size: 24px !important;
    line-height: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -5px;
    color: #818181 !important;
  }
  .dashboard-footer {
    background: #f7f7f7;
    clear: both;
    overflow: hidden;
    padding: 10px;
    z-index: 99;
    position: relative;
  }
  
  .dashboard-footer ul {
    display: flex;
    list-style: none;
    gap: 30px;
    margin: 0 0 0 30%;
    padding: 0;
  }
  
  .dashboard-footer ul li a {
    color: #000;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
    text-align: left;
    text-decoration: none;
  }
  
  .dashboard-footer .text-right {
    text-align: right;
  }
  
  .dashboard-footer .text-right p {
    margin: 0;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    /* line-height: 15.73px; */
    text-align: right;
  }
  
  .footer-row.row {
    align-items: center;
  }
  
  
  main.body-total.content-wrapper.float-start.w-100 {
    padding-left: 354px;
  }
  
  div#navAccordion {
    width: 354px;
  }
  
  main.body-total.content-wrapper.float-start.w-100.full-width {
    padding-left: 84px;
  }
  
  
  
  main.body-total.content-wrapper.float-start.w-100.full-width .top-bar-menu.shadow.float-start {
    padding-left: 84px;
  }  .dasb-div{
    padding-left: 44px !important;padding-right: 20px;
  }
  .dasb-div.bk-div.float-start.w-90 {
    width: 100%;
    padding-right: 84px;
  }
  div#navAccordion .logo-admin img {
    width: 320px;
  }
  .main-menu {
    padding-left: 41px;
    padding-right: 108px;
  }.sidebar-menu .search-form-side {
    padding: 20px 58px 17px 41px;
    width: 100%;
  }
  .search-form-side input {
    width: 100%;
    height: 32px;
    border-radius: 30px;
    padding-left: 48px;
  }/*
.main-menu.d-none.d-lg-block {
    overflow: auto;
    max-height: 2500px;
}*/
  .sidebar-menu .search-form-side button {
    top: 3px;
    position: absolute;
    border: none;
    background: none;
    padding: 0;
    left: 26px;
  }.logout_block {
    padding: 86px 15px 0;
  }
  
  .logout_block a.logout-link {
    display: flex;
    gap: 12px;
    font-family: Kumbh Sans;
    font-size: 20px;
    padding: 12px 25px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;    justify-content: center;
    color: #676767;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
  }
  .sidebar-menu.active .main-menu {
    padding-left: 10px;
    padding-right: 10px;
  }
  .logout_block a.logout-link:hover {
    background: #d9d9d9;
    background: #D9D9D978;
  }
  .sidebar-menu.active .main-menu ul li a {
    padding: 12px;
  } .sidebar-menu.active a.logout-link {
    font-size: 0;gap: 0;
    padding: 12px;
  }.sidebar-menu.active .logout_block {
    padding-left: 0;
    padding-right: 0;
  }.sidebar-menu.active .search-form-side {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }.sidebar-menu.active .search-form-side input.form-control {
    padding: 10px 32px;
  }
  @media screen and (max-width: 767px) {
  a.back-link {
  
    top: 0px;
  
  }
  }
  figure.creditcard-bg img {
      width: 95%;
  }
  .graph-top {
    display: flex;
    gap: 25px;
    position: absolute;
    top: 30px;
    left:20px;
    z-index: 1;
  }
  .toptable-heading {
      font-family: Kumbh Sans;
      font-size: 18px;
      font-weight: 500;
      line-height: 26.32px;
      text-align: left;
  }
  .graph-chart {
    position: relative;
  }
  
  .graph-top .btn-top {
    background: #F5F5F7;
    font-family: Roboto;
    font-size: 18.47px;
    font-weight: 700;
    line-height: 21.65px;
    text-align: left;
    color: #0C0B0B;
    border: none;
    padding: 9px 27px;
    border-radius: 10px;
    text-transform: capitalize;
  }
  .astronautsicon {
    margin-top: 68px;
    margin-left: 76px;
  }
  .submenu li{
    list-style-type: none;
  }
  .course-table-block .pagination {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
  }
  .pagination button {
      padding: 2px 15px;
      margin: 0;
      border: none;
      background: transparent;
      border-radius: 30px !important;
      font-family: Inter;
      font-size: 15px;
      font-weight: 500;
      line-height: 18.15px;
      text-align: left;
      color: #676767 !important;
  }
  
  .pagination button.disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination button.active {
     
    background: #676767;
  
      color: white !important;
      border-radius: 30px;
  }
  
  .pagination {
    text-align: center;
  }
  .fog-get-fomm.login-right h2 {
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 21.78px;
      text-align: left;
  }p.text-right.lg-text {
      text-align: right;
  }
  
  .transisition-block {
      border: 2px dashed #122D4B4D;
      padding: 30px 20px 20px;
      margin-top: 20px;
      border-radius: 10px;
  }
  .head-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .transisition-block .in-box-tx {
      border: 0.5px solid #DEDEDE;
      padding: 20px;
      border-radius: 10px;
  }.usr-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 32px;
  }.usr-block .usr-check {
      flex: 0 0 50%;
      display: flex;
      justify-content: space-between;
  }.credit_card-block {
      margin-top: 18px;
  }.passport-block img {
      width: 100%;
  }.content-block {
      padding-top: 16px;
  }.passport-block {
      padding-top: 20px;
  }.pass-heading {
      font-family: Kumbh Sans;
      font-size: 22px;
      font-weight: 600;
      line-height: 27.29px;
      text-align: center;
      margin-bottom: 20px;
  }.transisition-block.pass-area-block {
      padding-top: 15px;
  }
  .course-graph.graph-chart .chart-graph-line {
        border: 2px dashed #122D4B4D;
      border-radius: 10px;
      margin-top: 18px;
      margin-bottom: 16px;
      padding-bottom: 10px;
      overflow: hidden;
  }
  
  
  .passList {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      row-gap: 14px;
  }
  
  .passList .in-box-tx {
      width: 100%;
      padding: 24px 21px;
  }
  .passList .in-box-tx .head-block {
      margin: 0 20px;
      font-family: Kumbh Sans;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
  }
  .passList .in-box-tx .mainbody {
      margin: 0 20px;
  }.passList .in-box-tx .head-block span {
      font-family: Kumbh Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
  }.passList .usr-check {
      flex: 0 0 66%;
  }
  .passList .debit {
      font-family: Kumbh Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
  }
  .hold {
      font-family: Kumbh Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;color: #FFBB38;
  
      text-align: left;
  }
  
  .passList .in-box-tx .head-block .ellipsis {
      margin-top: -12px;
  }.course-table-view table.table td button.view1-btn {
      background: transparent;
  }
  
  .np-links {
      display: flex;
      gap: 15px;
      justify-content: flex-end;
  }
  .col-lg-6.text-right.np-links {
      display: flex;
      gap: 15px;
      justify-content: flex-end;
  }
  
  .np-links a {
      font-family: Kumbh Sans;
      font-size: 13px;
      font-weight: 400;
      line-height: 16.12px;
      text-align: left;
      color: #122D4BE5;
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 5px;
    
  }
  
  
  ul.rglist-link {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 38px;
      margin: 0;
      padding: 0;
      list-style: none;
  }
  ul.rglist-link li img {
      width: 36px;
      height: 36px;
  }
  
  .transisition-form .flex-form-group {
      display: flex;
      gap: 20px;
      margin-bottom: 12px;
      align-items: center;
  }
  
  .transisition-form .flex-form-group .m-label {
      flex: 0 0 10%;
      font-family: Kumbh Sans;
      font-size: 20px;
      font-weight: 700;
      line-height: 58px;
      text-align: center;
  }.transisition-form .flex-form-group .select-input {
      display: flex;
      flex-wrap: wrap;
      gap: 14px;
  }
  .select-input select {
      padding-top: 12px;
      font-family: Kumbh Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 24.92px;
      text-align: left;
      padding-bottom: 12px;
      border-radius: 10px;
      color: #888888;
      border: 0.5px solid #ECECEC;
  }.select-search button.submit-serch {
      padding: 17px 22px 17px 22px;
      border-radius: 10px;
      height: 112px;
      background: var(--Main-colour, #001B12);
  }
  .transisition-form {
      border: 2px dashed #122D4B4D;
      padding: 50px 20px 40px;
      margin-top: 20px;
      border-radius: 20px;
  }img {
      max-width: 100%;
  }.select-search button.submit-serch img {
      max-width: unset;
  }
  
  
  
  
  
  
  
  
  .course-table-view ul.nav.nav-tabs {
      border: none;
      margin-bottom: 12px;
      display: flex;
      gap: 80px;
  }
  
  .course-table-view ul.nav.nav-tabs li a {
      border-width: 0 0 2px 0;
      border-color: transparent;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      text-align: left;
      color: #718EBF;
      padding: 6px 12px;
  }
  
  .course-table-view ul.nav.nav-tabs li a.active {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      text-align: left;
      color: #122D4B;
      border-color: #122D4B;
  }
  
  .course-table-view table.transistion-table th {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      color: #718EBF;
      text-align: left;
      border-top: 0px;
  }
  
  
  
  .course-table-view table.transistion-table thead {
      border-left: 0 !important;
      border-right: 0 !important;
  }
  
  .course-table-view table.transistion-table td {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;
      text-align: left;
      color: #232323;
      padding: 15px 10px;
  }
  
  
  
  .course-table-view table.transistion-table td .description {
      display: flex;
      align-items: center;
      gap: 14px;
  }
  
  .course-table-view table.transistion-table td .description a.up-link {
      border: 2px solid #718EBF;
      width: 30px;
      height: 30px;justify-content: center;
      display: flex;
      align-items: center;
      
      padding: 5px;
      border-radius: 100%;
  }
  
  a.Download-link {
      border: 1px solid #123288;
      font-family: Inter;
      font-size: 15px;
      font-weight: 400;
      line-height: 18.15px;
      text-align: left;
      padding: 9px 15px;
      border-radius: 50px;
      text-decoration: none;
      color: #123288 !important;
      cursor: pointer;
  }
  
  .team-member {
      display: flex;
      align-items: center;
      gap: 15px;
  }a.space-link {
      text-decoration: none;
  }
  
  /*********popular course*****/
  
  .box.tool-box {
      text-align: center;
      border: 1px solid #E9EAF0;
      padding: 24px;transition:0.5s;
  }
  .box.tool-box:hover{box-shadow: 0px 12px 32px 0px #1D20261A;
  transition:0.5s;
  
  }
  .box.tool-box h5 {
     transition:0.5s;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      margin-bottom: 8px;
  }
  
  .box.tool-box p {
   margin: 0;
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      color: #8C94A3;
  }
  
  .col-lg-12.popular-keyword {
      display: flex;
      align-items: center;
      gap: 24px;
      flex-wrap: wrap;
      margin-top: 40px;
  }
  
  .popular-keyword ul.list-group {
      border: none;
      display: flex;
      gap: 12px;
  }
  
  ul.list-group.list-group-horizontal li a {
      background: #F5F7FA;
      padding: 6px 12px;
      cursor: pointer;
      font-family: Inter;
      font-size: 14px; transition: 0.5s;
      font-weight: 500;
      line-height: 20px; color:#1D2026;
      letter-spacing: -0.01em;
      text-align: left;
  } 
  
  .popular-keyword ul.list-group li {
      background: none;
      border: none;
      padding: 0;
  }
  ul.list-group.list-group-horizontal li a:hover { transition: 0.5s; background: #122d4b; color:#fff;}
  .topcategory .inline-box {
      display: flex;
      gap: 20px;
      align-items: center;
      background: #f5f7fa;
      padding: 20px;
  }
  
  .topcategory .inline-box .cat-image {
      background: #fff;
      width: 64px;
      height: 64px; transition: 0.5s;
      display: flex;
      align-items: center;
      padding: 15px;
      justify-content: center;
  }
  
  .topcategory .inline-box .cat-content p {
      margin: 0;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;color:#8C94A3 ; }

  .topcategory .inline-box .cat-content h5 {
   
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
  }
  
  .topcategory .inline-box:hover, .best-course-card:hover {
      background: #fff;    transition: 0.5s;
      box-shadow: 0px 12px 32px 0px #1D20261A;
  }
  .instructor-block .best-course-card {
      border: 1px solid #E9EAF0; transition: 0.5s;
  }
  .best-course-card {
      transition: 0.5s;
  }
  .instructor-block .best-course-card:hover{
     transition: 0.5s;
      box-shadow: 0px 12px 32px 0px #1D20261A;
  }
  .topcategory .inline-box:hover .cat-image {
      background: #122d4b; transition: 0.5s;
  }
  
  .course-footer a {
      display: flex;
      gap: 4px;
      align-items: center;
  }
  
  .category-heading h2 {
      text-align: center;
  
      font-family: Inter;
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: -0.01em;
      text-align: center;
      margin-bottom: 40px;
  }.topcategory .inline-box:hover .cat-image img { transition: 0.5s;
      filter: brightness(0) saturate(100%) invert(100%) sepia(20%) saturate(0%) hue-rotate(133deg) brightness(105%) contrast(101%);
  }
  .more-view a {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.01em;
      text-align: center;
      display: inline-flex;
      align-items: center;
      gap: 10px;
      color: #122d4b !important;
  }
  
  .more-view {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: center;
  }.best-course-list {
      display: flex;
      gap: 2%;
      flex-wrap: wrap;
      row-gap: 25px;
  }
  .best-course-list .col-s-5 {
      flex: 0 0 18.4%;
  }
  
  .best-course-card img {
      width: 100%;
      display: block;
  }
  
  .course-body {
      padding: 15px 14px;
  }
  
  .course-body h3.course_title {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.01em;
      text-align: left;
      margin-bottom: 0;
  }
  
  .course-footer {
      border-top: 1px solid #E9EAF0;
      padding: 15px 14px;
      display: flex;
      justify-content: space-between;
  }
  
  span.corse-rating {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.01em;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 5px;
  }
  span.corse-student {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #8C94A3;
      display: flex;
      align-items: center;
      gap: 4px;
  }
  
  span.cr-value {
      color: #4E5566;
      font-weight: 500;
  }
  
  .cat-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
  }
  
  .cat-price span.crs_categoury {
      padding: 4px 6px 4px 6px;
      background: #122d4b;
      font-family: Inter;
      font-size: 10px;
      color: #fff;
      font-weight: 500;
      line-height: 12px;
      text-align: left;
      text-transform: uppercase;
  }
  
  span.crs_price {
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
      color:  #122d4b;
  }
  
  .feature.courses h2 {
      font-family: Inter;
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: -0.01em;
      text-align: left;
  }
  
  .feature.courses p {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
  }
  
  .course_price_cat {
      display: flex;margin-bottom: 10px;
      justify-content: space-between;
  }
  
  .feature-item {
      border: 1px solid #E9EAF0;
    transition:0.5s;
  }
  .feature-item:hover {transition:0.5s;
      box-shadow: 0px 12px 32px 0px #1D20261A;
  }
  .feature-item .course-content {
      padding: 15px 24px 0;
  }
  span.course_category {
      background: #E1F7E3;
      padding: 4px 6px;
  
      font-family: Inter;
      font-size: 10px;
      font-weight: 500;
      display: flex;
      line-height: 12px;
      text-align: left;
      text-transform: uppercase;
      align-items: center;
  }
  
  span.course_price {
      display: flex;
      gap: 4px;
  }
  
  span.course_price span.sale-price {
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.015em;
      text-align: left;
      color: #1D2026;
  }
  
  span.reg-price {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      text-decoration: line-through;
      color: #A1A5B3;
  }
  
  .course_link a {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
  }
  
  .course_link {
      margin-bottom: 17px;
  }
  
  .course-user-rating {
      display: flex;
      justify-content: space-between;
      margin-bottom: 17px;
  }
  
  span.course_user {
      display: flex;
      align-items: center;
      gap: 12px;
  }.course-image img {
      width: 100%;
  }
  .course-image img {
      width: 100%;
  }
  
  .course-image {
      height: 100%;
      padding-top: 66.666%;
      position: relative;
  }
  
  .course-image img {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
  }
  .ratingreview {
    align-items: center;
    gap: 5px;
}

.ratingreview p {
    margin: 0;
}

.ratingreview span.icon {
    display: flex;
}
  .course-card-block:hover .course-hover-detalis {
    opacity: 1;
    visibility: visible;
}.course-hover-detalis {
    border: 1px solid #e9eaf0;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(29, 32, 38, 0.12);
    width: calc(100% + 80px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 1;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
}.course-hover-detalis .feature-course-right-content {
    padding: 20px;
}.course-hover-detalis .feature-course-right-content-header {
    padding: 0;
}.course-hover-detalis .feature-course-info {
    margin-bottom: 10px;
}.feature-course-info {
    display: flex;
    justify-content: space-between;
}button.course-item-5-card-info-tag.tag-color2 {
    
	color: #342f98;
    background-color: #ebebff;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase; border:none;
    padding: 4px 6px;
}

.course-hover-detalis a.feature-course-head {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #1d2026 !important;
    margin-bottom: 15px;
	text-decoration:none;
}
a.theme-btn.brows-btn {
    display: flex;
    gap: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
}.course-hover-detalis .feature-course-footer a {
    display: flex;
    align-items: center;
    gap: 8px;
}
 .course-card-block a {
    display: block;
}.course-hover-detalis .feature-course-mid {
    margin-bottom: 20px;
}.feature-course-mid {
    display: flex;
    justify-content: space-between;
}.feature-course-mid div {
    display: flex;
}.course-hover-detalis .feature-course-user-data {
    display: block !important;
}.course-hover-detalis .feature-course-mid img {
    height: 48px;
    width: 48px;
}.course-hover-detalis .feature-course-user-data p {
    font-size: 14px;
    letter-spacing: -0.01em;
    color: #8c94a3;
    padding-left: 12px;
    margin: 0;
}.feature-course-mid h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #4e5566;
    align-self: center;
    margin: 0;
    padding-left: 12px;
    display: inline-block;
    transition: 0.3s;
}
.course-hover-detalis .feature-course-mid a {
    margin-bottom: 20px;
    text-decoration: none;
}
  .feature-course-mid p {
 
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #1d2026;
    margin-left: 6px;
    align-self: center;
}.feature-course-mid p span {
    font-size: 14px;
    line-height: 24px;
    color: #a1a5b3;
    font-weight: 400;
}.course-hover-detalis .feature-course-footer {
    padding: 0;
    border-top: 0;
}.feature-course-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e9eaf0;
    padding: 20px 24px;
}
.feature-course-footer .p-color {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #4e5566;
}.course-hover-detalis .price-discount {
    border-bottom: 1px solid #e9eaf0;
    padding: 0 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.course-hover-detalis .what-your-learn h5 {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: #1d2026;
}
  .course-hover-detalis .price-discount h5 {
    font-size: 24px;
    color: #1d2026;
    margin: 0;
    display: flex;
    align-items: center;
}.course-hover-detalis .price-discount h5 del {
    color: #8c94a3;
    font-size: 16px;
    margin: 0 5px;
    margin-right: 10px;
}.course-hover-detalis .price-discount h5 span {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #ff6636;
    background-color: #ffeee8;
    padding: 8px 12px;
}.course-hover-detalis .price-discount .heart-icon {
    width: 48px;
    height: 48px;
    background: #ffeee8;
    text-align: center;
    line-height: 47px;
    cursor: pointer;
}.course-hover-detalis .what-your-learn {
    padding: 20px;
    border-bottom: 1px solid #e9eaf0;
}
  .course-hover-detalis .what-your-learn ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
  .feature-courses-list {
      gap: 24px;
      flex-wrap: wrap;
  }
  .course-hover-detalis .what-your-learn ul li {
    padding-left: 30px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #6e7485;
    position: relative;
    margin-bottom: 8px;
}.course-card-block {
    position: relative;
}
.what-your-learn ul li:before {
    height: 9px;
    width: 14px;
    border-bottom: 2px solid #171717;
    border-left: 2px solid #171717;
    transform: rotate(315deg);
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 7px;
    z-index: 9;
}.course-hover-detalis .hover-footesection {
    padding: 20px 20px 30px;
}
  .feature-courses-list .feature-item {flex: 0 0 48.5%; margin: 0;}
  .feature-courses{
      margin-bottom: 20px;
  }
  .recent-course .best-course-card {
      border: 1px solid #E9EAF0;
  }a.brows-btn , .brows-btn {
      background: #122d4b;
      height: 48px;text-transform: capitalize;
      padding: 0px 24px 0px 24px;
      gap: 12px;
      text-decoration: none;
      font-family: Inter;
      font-size: 16px;    display: inline-flex;
      transition: 0.5s;    border-radius: 0;
      font-weight: 600;
      line-height: 45px; 
      letter-spacing: -0.008em;
      text-align: left;
      color: #fff !important;
      cursor: pointer;
  }
  a.brows-btn img{width: 24px;filter: brightness(0) saturate(100%) invert(100%) sepia(20%) saturate(0%) hue-rotate(133deg) brightness(105%) contrast(101%);transition:0.5s;
  }
  a.brows-btn:hover {background: #122d4b;color:#fff!important;transition:0.5s; }
  a.brows-btn:hover {
    background-color: #122d4b;
    box-shadow: 0px 6px 20px 0px #122d4b;
	background: #122d4b;color:#fff!important;transition:0.5s;
}
  a.button.border-btn, .button.border-btn {

font-weight: 600;
    font-size: 16px;
    line-height: 48px;
    padding: 0px 24px;
    letter-spacing: -0.008em;
    background-color: #ffeee8;
    text-align: center; border-color:#ffeee8;    border: 1px solid #ffeee8;
    text-decoration: none;
    color: #122d4b;
    margin-top: 20px;
}  a.button.border-btn:hover, .button.border-btn:hover {
	background-color: #122d4b;
    box-shadow: 0px 6px 20px 0px #122d4b;   border: 1px solid #122d4b;
background: #122d4b;color:#fff!important; transition:0.5s;}
  
  a.brows-btn:hover img{    filter: brightness(0) saturate(100%) invert(100%) sepia(20%) saturate(0%) hue-rotate(133deg) brightness(105%) contrast(101%);transition:0.5s;
  }
  .instructor-block .course-image {
      padding-top: 100%;
  }
  p.instructor-position {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: center;
      margin-bottom: 0;
  }.instructor-name {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #1D2026;
      text-align: center;
      margin-bottom: 4px;
  }.recent-course {
      padding-top: 30px;
      padding-bottom: 20px;
  }
.instructor-inner {
      display: flex;
      background: #122d4b;
  }
  .instructor-inner .became-instructor-content {
      padding: 40px;
      flex: 0 0 56%;
  }
  .became-instructor-image {
      flex: 40% 1;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      padding-right: 58px !important;
  }
.instructor-inner .became-instructor-content h2 {
      font-family: Inter;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #fff;
  }
  
  .instructor-inner .became-instructor-content p {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #fff;
  }
  
  .instructor-inner a.brows-btn {
      background: #fff;color:#122d4b !important;
      padding: 15px 28px;
  }
  /*
  .instructor-inner a.brows-btn:hover {
      color: red !important;
  }
  */
  .instructor-inner a.brows-btn img {
    filter: brightness(0) saturate(100%) invert(14%) sepia(10%) saturate(6650%) hue-rotate(182deg) brightness(93%) contrast(92%);
  }
  
  .became-instuctor.row {
      padding: 15px 26px;
      gap: 3%;
      margin-bottom: 56px;
  }
  
  .step-block {
      padding: 40px;
      border: 1px solid #ddd;
  }

  
  .became-instuctor.row .col-lg-6 {
      width: 48.5%;
      padding: 0;
      flex: 0 0 48.5%;
  }
  

  
  .step-block h2 {
      font-family: Inter;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: -0.01em;
      margin-bottom: 27px;
      text-align: left;
  }
  
  ul.step-list {
      display: flex;
      flex-wrap: wrap;
      gap: 2%;
      list-style: none;
      margin: 0;
      padding: 0;
      row-gap: 20px;
  }
  ul.step-list li {
      flex: 0 0 48%;
      display: flex;
  
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      gap: 16px;
      align-items: center;
  }
  
  ul.step-list li span {
      background: #EBEBFF;
      height: 52px;    min-width: 52px;
      width: 52px;
      display: flex;
      font-family: Inter;
      font-size: 24px;
      font-weight: 600;
      line-height: 29.76px;
      text-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
  }
  
  ul.step-list li span.step2 {
      background: #FFF0F0;
      color: #122d4b;
  }
  
  ul.step-list li span.step3 {
      color: #E34444;
      background: #FFF0F0;
  }
  
  ul.step-list li span.step4 {
      background: #E1F7E3;
      color: #23BD33;
  }
  .row.ourclient {
      padding: 80px 0;
  }
  
  .ourclient h2 {
      font-family: Inter;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: -0.01em;
      text-align: left;
  }
  
  .row.ourclient p {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
      max-width: 309px;
  }
  
  ul.client-list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      row-gap: 24px;
      align-items: center;
  }
  
  ul.client-list li {
      flex: 20%;
      box-shadow: 0px 0px 32px 0px #091A4412;
      justify-content: center;
      display: flex;
      padding: 36px;
  }
  
  .course-filter-section {
      padding-top: 120px;
  }
  
  .course-filter-section {
      padding-top: 120px;
  }
  .filter-left {
      display: flex;
      gap: 24px;
  }
  
  button.filter_toggle {
      border: 1px solid #FFDDD1;
  
      font-family: Inter;
      font-size: 16px;
      background: #fff;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.008em;
      padding: 12px 24px;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 12px;
  }
  
  span.course_count {
      background: #FFEEE8;
      width: 20px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-align: center;
      margin-left: 12px;
  }
  
  
  
  .course-search input[type="text"] {
      width: 457px;
      height: 48px;
      padding: 12px 18px 12px 50px;
      border: 1px solid #E9EAF0;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #1D2026;
  }
  .course-search form {
      position: relative;
  }
  
  .course-search form button {
      position: absolute;
      left: 16px;
      background: transparent;
      border: navajowhite;
      padding: 0;
      top: 10px;
  }
  
  .course-search form button img {
      width: 24px;
  }
  .filter-shorting form {
      display: flex;
      align-items: center;
      gap: 24px;
      justify-content: flex-end;
  }
  
  .filter-shorting form label {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #4E5566;
  }
  
  .filter-shorting form  select.form-select {
      border: 1px solid #E9EAF0;
      width: 200px;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      padding: 12px 24px;
      cursor: pointer;
      border-radius: 0;
      color: #4E5566;
  }
  .filter-suggestion {
      padding: 24px 0;
  }
  
  
  
  .suggestion-tags span {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #1D2026;
  }.suggestion-tags {
      display: flex;
      gap: 12px;
  }
  .suggestion-tags a {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #122d4b !important;
    cursor:pointer;
    transition:0.5s;
  }
  .suggestion-tags a:hover {
      text-decoration: underline !important;
    transition:0.5s;
  }
  
  
  .filter_result-text {
  
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: right;
      color: #1D2026;
  }
  
  .filter_result-text span {
      font-weight: 500;
  }
  .filter-course-list {
      padding-top: 16px;
      row-gap: 24px;
  }
  
  
  .course-pagination {
      padding-top: 40px;
      padding-bottom: 80px;
  }
  .course-pagination ul.pagination {
      display: flex;
      justify-content: center;
      border: none !important;
    gap:5px;
  }
  
  .course-pagination ul.pagination li.page-item {
      border: none !important;
      padding: 0;
  }
  
  .course-pagination ul.pagination li.page-item a {
      border: none;
  
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.01em;
      text-align: center;
      color: #1D2026;    border-radius: 100%;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
  }.course-pagination ul.pagination li.page-item a.active {
      background: #122d4b;
      color: #fff;
  }.course-pagination ul.pagination li.page-item a:hover {
      background: #122d4b; color: #fff;
  }.course-pagination ul.pagination li.page-item a:focus {
      box-shadow: none;
  }
  .best-course-list .course-body h3.course_title {
     
      font-size: 16px;
      font-weight: 500;
    
  }
  .filter-item {
      border: 1px solid #E9EAF0;
      margin-bottom: 24px;
  }.filter-item h2 {
      font-family: Inter;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      padding: 23px 20px;
      margin: 0;
  }.filter-item ul.toggle-content {
      margin: 0;
      padding: 16px 0px;
      list-style: none;
      border-top: 1px solid #E9EAF0;
  }ul.toggle-content li a {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #4E5566;
      text-decoration: none;
  }ul.toggle-content li {
      padding: 10px 20px;
  }
  ul.toggle-content li.cat-item {
      border-bottom: 1px solid #E9EAF0;
  }
  
  li.sub-cat-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }li.cat-item ul {
      margin: 0;
      padding: 16px 0 0;
  }span.cat-icon img {
      filter: brightness(0) saturate(100%) invert(75%) sepia(14%) saturate(240%) hue-rotate(189deg) brightness(87%) contrast(85%);
  }ul.toggle-content li.cat-item span.cat-icon {
      margin-right: 12px;
  }ul.toggle-content li .form-check-input[type=checkbox] {
      border-radius: 0;
      border: 1px solid #CED1D9;
      width: 18px;
      height: 18px;
      margin-right: 12px;
  }
  h2.toggle-heading {
      display: flex;
      justify-content: space-between;
  }.filter-item ul.toggle-content li.cat-item {
      position: relative;
  }.filter-item ul.toggle-content li.cat-item span.dropdown-icon {
      position: absolute;
      right: 20px;
      top: 15px;
  }ul.toggle-content li ul li {
      padding-left: 0;
      padding-right: 0;
  }
  li.cat-item.active span.cat-icon img{
    filter: brightness(0) saturate(100%) invert(15%) sepia(99%) saturate(375%) hue-rotate(167deg) brightness(91%) contrast(99%);
  }
  
  .course-pagination ul.pagination li.page-item a img{
    filter: brightness(0) saturate(100%) invert(15%) sepia(99%) saturate(375%) hue-rotate(167deg) brightness(91%) contrast(99%);
  }
  .course-pagination ul.pagination li.page-item a:hover img{
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(239deg) brightness(102%) contrast(103%);
  }
  ul.toggle-content li ul li .form-check-input:checked {
      background-color: #122d4b !important;
      border-color: #122d4b !important;
  }
  
  /*********single page css******/
  
  .single-course-wrapper {
      position: relative;
  }
  
  .single-course-wrapper:before {
      content: "";
      display: block;
      position: absolute;
      height: 350px;
      background: #F5F7FA;
      top: 0;
      width: 100%;
      z-index: 0;
  }
  .single-course-block.row {
      z-index: 1;
      position: relative;
      padding: 80px 40px;
  }
  
  .breadcrumb li {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
      padding-right: 20px;
  }
.breadcrumb-item+.breadcrumb-item::before {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    margin: 0 !important;
    padding: 0;
    content: "" !important;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: relative;
    left: -12px;
    width: 9px !important;
    height: 9px !important;
    top: 6px;
    min-width: 9px !important;
}
  .single-course-content h2 {
      font-family: Inter;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: -0.01em;
      text-align: left;
      margin-bottom: 24px;
  }
  .course-author {
      margin-top: 24px;
  }
  
  
  .single-course-content p {
      font-family: Inter;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;
  }
  
  .course-author-content {
      display: flex;
      align-items: center;
      gap: 12px;
  }
  
  .course-author-content .author-image img {
      width: 50px;
      border: 2px solid #FFFFFF;
      border-radius: 100%;
  }
  
  .course-author-content .author-image  span.user3 img {
      margin-left: -15px;
  }
  
  .author-name p {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
      margin: 0 0 2px;
  }
  
  .author_ct-name a {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      cursor: pointer;
  }
  
  .course-author-rating {
      display: flex;
      justify-content: flex-end;
      align-items: center;
  }
  
  .course-author-rating .rating-content {
      display: flex;
      align-items: center;
      gap: 8px;
  }
  
  .course-author-rating .rating-content span.rating_value {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      color: #1D2026;
  }
  
  .course-author-rating .rating-content span.rating_view {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
  }
  
  .sidebar-block {
      border: 1px solid #E9EAF0;
      background: #FFFFFF;
      box-shadow: 0px 6px 16px 0px #0000000F;
  }
  
  .course_top {
      border-bottom: 1px solid #E9EAF0;
      padding: 24px;
  }
  
  .course_top .course_price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
  }
  
  span.price-value {
      display: flex;
      gap: 8px;
      align-items: center;
  }
  
  span.price-value span.saleprice {
      font-family: Inter;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;
  }
  
  
  
  span.regular-price {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #8C94A3;
      text-align: left;
      text-decoration: line-through;
  }
  
  span.price-discount {
      background: #FFEEE8;
      padding: 8px 15px;
  
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      text-align: left;
  }
  
  .timer_value {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.01em;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 8px;
  }
  
  .course_feature {
      border-bottom: 1px solid #E9EAF0;
      padding: 24px;
  }
  
  .course_feature ul {
      list-style: none;
      margin: 0;
      padding: 0;
  }
  
  .course_feature ul li {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      margin-bottom: 16px;
      text-align: left;
      display: flex;
      justify-content: space-between;
  }
  
  .course_feature ul li span.fname-item {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #1D2026;
  }
  
  .course_feature ul li span.value-item {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #6E7485;
  }
  
  .course_feature ul li:last-child {
      margin: 0;
  }
  
  .course_buy-buttons {
      border-bottom: 1px solid #E9EAF0;
      padding: 24px;
  }
  
  .courbutton-group {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin-bottom: 12px;
  }
  
  .courbutton-group a.add-cart-btn.btn {
      width: 100%;
      background: #122d4b;
      border-radius: 0;
  
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      padding: 0 32px;
      line-height: 56px;
      letter-spacing: -0.01em;
      color: #fff;
      text-align: center;
  }
  
  .courbutton-group a.add-buy-btn.btn {
      background: #FFEEE8;
      border-radius: 0;
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      padding: 0 32px;
      line-height: 56px;
      letter-spacing: -0.01em;
      color: #122d4b;
      text-align: center;
      width: 100%;
  }
  
  a.wishlist-btn.btn {
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: -0.004em;
      text-align: left;
      border: 1px solid #E9EAF0;
      border-radius: 0;
      padding: 0 26px;
      flex: auto;
      text-align: center;
  }
  
  a.gift-btn.btn {
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: -0.004em;
      text-align: left;
      border: 1px solid #E9EAF0;
      border-radius: 0;
      padding: 0 26px;
      flex: auto;
      color: #122d4b;
      text-align: center;
  }
  
  .course_buy-buttons p {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
    margin:0;
      text-align: left;
  }.course_included {
      border-bottom: 1px solid #E9EAF0;
      padding: 24px;
  }.sidebar-block h4 {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
  }
  .lectures-section .course-single-title {
    margin-bottom: 20px;
}

.lectue-body-item label.form-check-label {
    display: flex;
    flex: 1;
    justify-content: space-between;
    width: 100%;
}
.course-watch-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lectue-body-item label.form-check-label span.course-duration img {
    width: 15px;
    opacity: 0.6;
}



.course-watch-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    margin: 0;
}

.course-watch-head h3 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.01em;
    text-align: left;
}

.course-watch-head  .course-status {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: right;
}

.courstProgess {
    margin-bottom: 24px;
}.watchCourse-item .form-check label.form-check-label {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    align-items: center;
}

.watchCourse-item .form-check .form-check-input[type=checkbox] {
    margin: 0;
    position: relative;
    top: 15px;
    left: 14px;
    height: 20px;
    width: 20px;
    border-radius: 0;
}
.watchCourse-item label.form-check-label span.course-duration {
    display: flex;
    gap: 8px;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 22px;
letter-spacing: -0.01em;
text-align: left; color:#A1A5B3;

}.watchCourse-item label.form-check-label span.course-duration img {
    width: 15px;
    opacity: 0.6;
}
.watchCourse-item .form-check {
    width: 100%;
    margin: 0;
    padding: 0;
}.watchCourse-item .form-check label.form-check-label {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    align-items: center;
    margin: 0;
}

.watchCourse-item .form-check .form-check-input[type=checkbox] {
    margin: 0;
    position: relative;
    top: 15px;
    left: 14px;
    height: 20px;
    width: 20px;
    border-radius: 0;
}


.accordion-wrap-faq.accordion .accordion-item {
    border-radius: 0 !important;
    border: none !important;
    border-top: 0;
}
.watchCourse-item .form-check {
    width: 100%;
}
  /******watchcours comment*/
.comments-list {
    margin-top: 30px;
    position: relative;
    padding: 0;
    list-style: none;
}

 /**
 * Comments - details
 -----------------------*/
.comments-list:before {
    content: '';
    width: 1px;
    height: 100%;
    background: #E9EAF0;
    position: absolute;
    left:20px;
    top: 0;
}

 .comments-list:after {
     content: '';
     position: absolute;
     background: #c7cacb;
     bottom: 0;
     left: 27px;
     width: 7px;
     height: 7px;
     border: 3px solid #dee1e3;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     border-radius: 50%;
 }

 .reply-list:before,
 .reply-list:after {
     display: none;
 }
.comments-list>li:before {
    content: '';
    width: 1px;
    height: 73%;
    background: #E9EAF0;
    position: absolute;
    left: 20px;
    top: 0;
}
.reply-list li:before {
   content: '';
    width: 32px;
    height: 1px;
    background: #E9EAF0;
    position: absolute;
    top: 20px;
    left: -30px;
}.reply-link a {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    display: flex;
    align-items: center;
    gap: 12px;
    color: #8C94A3;
    text-decoration: none;
}
ul.comments-list.reply-list li {
    display: flex;
    gap: 12px;
}

 .comments-list li {
     margin-bottom: 15px;
    
     position: relative;
 }
.comment-box .comment-head h6.comment-name.by-author {
    margin: 0;
}

.comment-box .comment-name a {
    text-decoration: none;
}

.attach-file-item {
    background: #F5F7FA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
}

.file-content-block {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 12px;
}



.file-content-block h4 {
    margin: 0;

    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 4px;
    text-align: left;
    color: #1D2026;
}

.file-content-block  span.file_size {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #6E7485;
}
.overview-description ul li {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: left;color:#4E5566;
}
.file-action {
    display: flex;
}

.comment-box .comment-head span {
    position: relative;
}

.comment-box .comment-head span:before {
    content: " • ";
}

.reply-link a {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    display: flex;
    align-items: center;
    gap: 12px;
    color: #8C94A3;
    text-decoration: none;
}

.comments-list>li {
    position: relative;
    margin-bottom: 20px;
}


 .comments-list li:after {
     content: '';
     display: block;
     clear: both;
     height: 0;
     width: 0;
 }

 .reply-list {
    padding-left: 50px;
    clear: both;
    margin-top: 20px;
}a.admin_info {
    background: #564FFD;
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    text-align: right;
    padding: 4px 6px;
    color: #fff;
    text-decoration: none;
}.reply_input input.form-control {
    padding-left: 54px;
    border-radius: 0;
    height: 48px;
    border: 1px solid #E9EAF0;
}span.chat-icon-black {
    position: absolute;
    top: 9px;
    left: 20px;
}.d-flex.reply_input {
    gap: 10px;
    align-items: center;
    margin-top: 20px;
}.reply_input .col-auto.position-relative {
    flex: 1;
}

 /**
 * Profile Avatar - ---------------------------*/
 .comments-list .comment-avatar {
     width:40px;
     height:40px;
     position: relative;    min-width: 40px;
     z-index: 99;
     float: left;
     border: 1px solid #FFF;
     -webkit-border-radius: 100;
     -moz-border-radius: 100;
     border-radius: 100%;
   
     overflow: hidden;
 }

 .comments-list .comment-avatar img {
     width: 100%;
     height: 100%;  -webkit-border-radius: 100;
     -moz-border-radius: 100;
     border-radius: 100%;
 }

 .reply-list .comment-avatar {
     width: 40px;
     height: 40px;
 }

 .comment-main-level:after {
     content: '';
     width: 0;
     height: 0;
     display: block;
     clear: both;
 }

 /**
 * -------------- Comment Box ---------------------------*/
 .comments-list .comment-box {

     float: right;
     position: relative;
    
 }

 .comments-list .comment-box:before {
     border-width: 11px 13px 11px 0;
     border-color: transparent rgba(0, 0, 0, 0.05);
     left: -12px;
 }

 .reply-list .comment-box {
     width: 610px;
 }
.comment-main-level {
    display: flex;
    gap: 12px;
}
.comment-box .comment-head {
    display: flex;
    gap: 12px;
    margin-bottom: 12px;
    align-items: center;
}


 .comment-box .comment-head i:hover {
     color: #03658c;
 }

 .comment-box .comment-name {

font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: -0.01em;
 color:#1D2026 ;
}

 .comment-box .comment-name a {
     color: #283035;
 }

 .comment-box .comment-head span {
     float: left;
     color: #999;
     font-size: 13px;
     position: relative;
     top: 1px;
 }

 .comment-box .comment-content {

font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 22px;
letter-spacing: -0.01em;
text-align: left;margin-bottom: 12px;

 }
.comment-box .comment-head h6.comment-name.by-author {
    margin: 0;
}
.comment-box .comment-name a {
    text-decoration: none;
		
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.01em;
	text-align: right;

}.comment-box .comment-head span:before {
    content: " • ";
}.comment-box .comment-head span {
    position: relative;
}
/*
 .comment-box .comment-name.by-author,
 .comment-box .comment-name.by-author a {
     color: #03658c;
 }

 .comment-box .comment-name.by-author:after {
     content: 'author';
     background: #03658c;
     color: #FFF;
     font-size: 12px;
     padding: 3px 5px;
     font-weight: 700;
     margin-left: 10px;
     -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
     border-radius: 3px;
 }
  .comment-box .comment-name.by-admin:after {
     content: 'admin';
     background: #03658c;
     color: #FFF;
     font-size: 12px;
     padding: 3px 5px;
     font-weight: 700;
     margin-left: 10px;
     -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
     border-radius: 3px;
 }*/

 /** =====================
 * Responsive
 ========================*/
 @media only screen and (max-width: 766px) {
     .comments-container {
         width: 480px;
     }

     .comments-list .comment-box {
         width: 390px;
     }

     .reply-list .comment-box {
         width: 320px;
     }
 }
  /*****watch-comemntcss end*/
  
  ul.feature-list {
      line-height: normal;
      margin: 0;
      padding: 0;
      list-style: none;
  }
  
  ul.feature-list li {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.01em;
      text-align: left;
      margin-bottom: 16px;
  }
  
  
  
  ul.feature-list li span.fname-item {
      display: flex;
      align-items: center;
      gap: 8px;
  }
  
  ul.feature-list li:last-child {
      margin: 0;
  }
  
  ul.feature-list li img {
      filter: brightness(0) saturate(100%) invert(12%) sepia(95%) saturate(812%) hue-rotate(180deg) brightness(86%) contrast(91%);
  }
  .course_share_inco {
      padding: 24px;
  }.course_share_inco {
      padding: 24px;
  }
  
  .share_list {
      display: flex;
      gap: 8px;
      align-items: center;
  }
 .share_list a.copy-link.share-link {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #F5F7FA;
    flex: auto;
    text-decoration: none;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #4E5566;
    cursor: pointer;
    width: auto;
}
.share_list a.share-link {
    display: flex;
    background: #F5F7FA;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    transition: 0.5s;
    height: 48px;
    width: 48px;
    min-width: 48px;
}.student-top-head  .profile-thumb {
    height: 200px;
    width: 200px;
}.profile-wrap.course-instructor.instructor_profile .profile-left {
    align-items: center;
}
 .share_list a.share-link:hover {
      background: #122d4b !important;
      color: #fff !important;
      transition: 0.5s;
  }
  
  .share_list a:hover:hover img {
      filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(4%) hue-rotate(36deg) brightness(102%) contrast(100%) !important;
      transition: 0.5s;
  }
  .course-featureed-image {
      margin: 40px 0;
  }.course-featureed-image img {
      width: 100%;
  }
  .course-featureed-image {
      margin: 40px 0;
  }
  
  .course-featureed-image img {width: 100%;}
  
  
  .course-data-tabs ul li button {
      border-width: 0 0 3px 0 !important;
      border-color: transparent;
      font-size: 16px;
      font-weight: 500;
      padding: 20px 40px;
      color: #4E5566 !important;
  }
  
  .course-data-tabs ul.nav-tabs {
      display: flex;
      gap: 24px;
      margin-bottom: 40px;
  }
  
  .course-data-tabs ul li button.active {
      border-color: #122d4b !important;
      color: #122d4b !important;
  }
  
  .course-data-tabs h3 {
      color: #1D2026;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
      font-weight: bold;
  }
  .course-data-tabs p {
      font-size: 14px;
      line-height: 22px;
      color: #4E5566;
  }
  .course-data-tabs ul li button {
      border-width: 0 0 4px 0 !important;
      border-color: transparent;
      font-size: 16px;
      font-weight: 500;
      padding: 20px 40px;
      color: #4E5566 !important;
  }
  

  .course-data-tabs ul li button.active {
      border-color: #122d4b !important;
      color: #122d4b !important;
  }
  
  .course-data-tabs h3 {
      color: #1D2026;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
      font-weight: bold;
  }
  .course-data-tabs p {
      font-size: 14px;
      line-height: 22px;
      color: #4E5566;
  }
  
  .course-learn-section {
      padding: 40px;
      margin-bottom: 40px;
      background: rgba(225, 247, 227, 0.4);
  }
  
  .checkbox-field ul {
      margin: 0;
      padding: 0;
      list-style: none;
  }.course-learn-section .checkbox-field ul li {
      margin-right: 0;
  }.checkbox-field ul li {
      position: relative;
      padding-left: 35px;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: -0.01em;
      color: #4e5566;
      margin-bottom: 20px;
      line-height: 22px;
  }.checkbox-field ul li svg {
      position: absolute;
      left: 0;
  }
.course-single-title h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.01em;
}.course-list-section ul li svg {
  position: absolute;
  left: 0;
}
.course-list-section {
  margin-bottom: 40px;
}.course-single-title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}.course-list-section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}.course-list-section.course-requirements ul li {
  padding-left: 15px;
}.course-list-section ul li {
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #4e5566;
  margin-bottom: 14px;
  position: relative;
  /*padding-left: 32px;*/
}.course-list-section.course-requirements ul li::before {
  content: "";
  width: 5px;
  height: 5px;
  left: 0;
  top: 9px;
  border-radius: 50%;
  position: absolute;
  background-color: #4e5566;
}
.accordion-wrap-faq.accordion {
  border-radius: 0px !important;
}

.accordion-wrap-faq.accordion h2 {
  margin: 0;
}


.accordion-wrap-faq.accordion h2 button.accordion-button {
  padding: 13px 20px;
  background: #ffffff;
  border: 1px solid #e9eaf0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0;
  transition: all 0.3s;
}
.course-curriculum-section {
  margin-bottom: 40px;
}.sub-heading-meta ul {
  margin: 0;
  padding: 0;
  list-style: none;
}.sub-heading-meta ul li {
  margin: 0 8px;
  display: inline-block;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #4e5566;
}.sub-heading-meta ul li:first-child {
  margin-left: 0;
}img, svg {
  vertical-align: middle;
}.accordion-wrap-faq.lecture-wrap .accordion__list2 {
  margin-bottom: 0;
}.accordion-wrap-faq .accordion__list2 {
  margin-bottom: 20px;
}

.accordion-wrap-faq .accordion__list2.open .link {
  color: #122d4b;
}
.linkwrap {
  display: flex;
  justify-content: space-between;
  width: 100%;padding-left: 36px;
}.accordion-wrap-faq.accordion h2 button.accordion-button::after {
  position: absolute;
  left: 24px;
}
.accordion-wrap-faq.accordion h2 button.accordion-button:not(.collapsed) , .accordion-wrap-faq.accordion h2 button.accordion-button:focus{
  border-bottom: none;
  box-shadow: none;
}
.accordion-wrap-faq.accordion h2 button.accordion-button:not(.collapsed) {
  border-bottom: none;
  box-shadow: none;font-size: 16px;
  color: #1d2026;
  font-weight: 500;
}
.accordion-wrap-faq.accordion h2 button.accordion-button{

  font-size: 16px;
  color: #1d2026;
  font-weight: 500;

}.sub-heading-meta ul li img {
  margin-right: 3px;
}
.accordion-wrap-faq.lecture-wrap .accordion__list2.open .accordion-header-content {
  color: #122d4b;
}.accordion-wrap-faq.lecture-wrap .accordion-header-content {
  font-size: 16px;
  color: #1d2026;
  font-weight: 500;
}.accordion-header-content {
  font-size: 18px;
}.accordion-wrap-faq.lecture-wrap .accordion__list2.open .accordion-header-content svg {
  transform: rotate(180deg);
}.accordion-wrap-faq.lecture-wrap .accordion-header-content svg {
  margin-right: 7px;
  transition: all 0.3s;
}.accordion-wrap-faq.lecture-wrap .accordion-body-text {
  box-shadow: none;
  border: 1px solid #e9eaf0;
  border-top: none;
  border-bottom: none;
  padding: 0;
}.course-curriculum-section .lectue-body-item {
  padding: 7px 20px;
}.lectue-body-item {
  display: flex;
  padding: 14px 20px;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;
  justify-content: space-between;
}.course-curriculum-section .curriculum-body-left {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;gap: 12px;
  color: #4e5566;
  display: flex;
  align-items: center;
}.course-curriculum-section .curriculum-body-right {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #8c94a3;
}.accordion-wrap-faq .accordion__list2 .link:hover {
  border: 1px solid transparent;
  box-shadow: 0px 12px 32px rgba(29, 32, 38, 0.1);
}

.student-profile-menu.single-course {
  border-bottom: 1px solid #e9eaf0;
}.student-profile-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}.student-profile-menu ul li {
  display: inline-block;
}.student-profile-menu ul li a.active {
  color: #1d2026;
}.student-profile-menu ul li a {
  font-weight: 500;
  font-size: 16px;
  color: #4e5566;
  position: relative;
  transition: all 0.3s;
  padding: 20px 38px;
}.overview-section {
  padding: 40px 0;
}.overview-description {
  margin-bottom: 40px;
}
.student-profile-menu ul li a::before {
  content: "";
  left: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s;
  position: absolute;
  background-color: #122d4b;
}.student-profile-menu ul li a:hover::before , .student-profile-menu ul li a.active::before{
  width: 100%;
}.overview-description p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #4e5566;
  margin-bottom: 20px;
}

.course-instructor-section {
  margin-bottom: 40px;
}
span.star-icons {
  display: flex;
  align-items: center;
  color: #FD8E1F;
  gap: 2px;
  font-size: 18px;
}.student-profile-menu.single-course ul li {
  display: inline-flex;
}
.student-profile-menu.single-course ul li a {
  text-decoration: none;
}
/*********profile****/
.profile-wrap .profile-left {
  display: flex;
  align-items: center;
}select.form-select.select-rating {
  margin: 0;
  border: 1px solid #e9eaf0;
  width: 200px;
  line-height: 48px;
  padding: 0 20px;
  font-size: 16px;
  color: #4e5566;
  border-radius: 0;
}
.profile-wrap {
  border: 1px solid #ffddd1;
  padding: 40px;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
}.profile-wrap.course-instructor {
  border: 1px solid #e9eaf0;
  padding: 32px;
  margin-bottom: 20px;
}.profile-wrap.course-instructor .profile-left {
  align-items: flex-start;
} .profile-left .profile-thumb {
  width: 136px;    overflow: hidden;
  height: 136px;  border-radius: 100%;
  margin-right: 24px;
  flex: 0 0 auto;
}.profile-wrap .profile-left .profile-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-left .profile-data h3 {
  font-size: 20px;
  margin-bottom: 6px;
}.profile-left .profile-data p {
  font-size: 14px;
  line-height: 22px;
}
 .profile-left .profile-data p {
  font-size: 16px;
  line-height: 24px;
  color: #6e7485;
}
 .profile-left .profile-review {
  margin: 16px 0;
} .profile-left .profile-data .profile-review ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}.profile-left .profile-data .profile-review ul li {
  display: inline-flex;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #6e7485;
  margin-right: 17px;
} .profile-left .profile-data .profile-review ul li img {
  margin-right: 5px;
} .profile-left .profile-data .profile-review ul li span {
  font-weight: 500;
  color: #1d2026;
  font-size: 16px;
  margin-right: 4px;
}
span.star-icon {
  display: flex;
  align-items: center;
  color: #fd8e1f !important;
  font-size: 20px;
}

/******rating area**/

.rating-area {
  padding-bottom: 24px;
  display: flex;
  align-items: flex-start;
}
.rating {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.01em;
  color: #6e7485;
  margin-left: 12px;
  margin-right: 12px;
}.rating-percentage {
    flex: 1;
}
.rating-box {
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 200px;
    height: 190px;
    border: 1px solid #e9eaf0;
    margin-right: 25px;
}
.rating-box h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1d2026;
  margin-bottom: 24px;
}.rating-box .total-star {
  margin-bottom: 14px;
}
.total-star {
  padding: 0;
  margin: 0;
}.rating-box p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #1d2026;
}
.total-star li svg {
  height: 20px;
  width: 20px;
}
.rating-area .rating-percentage .percentage-line {
  margin-bottom: 18px;
}
.percentage-line {
  display: flex;
  align-items: center;
}
.rating-area-percentage {
  position: relative;
  width: 234px;
}.rating-area .rating-percentage .percentage-line .rating-area-percentage {
  margin: 0 12px;    flex: 1;
}.percentage-text span {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.01em;
}.main-percentage-bg {
  width: 100%;
  height: 8px;
  background-color: #fff2e6;
}.rating-area-percentage .main-percentage {
  position: absolute;
  top: 0;
  left: 0;
}.main-percentage {
  height: 8px;
  width: 100%;
  background-color: #fd8e1f;
}.rating-area-percentage .one {
  width: 3%;
}.rating-area-percentage .four {
  width: 21%;
}.rating-area-percentage .three {
  width: 8%;
}


/****review**/
.review-item {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9eaf0;
}
.review-item .review-thumb {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 100%;
  flex: 0 0 auto;
  margin-right: 16px;
}.review-item .review-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}.review-item .review-data h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #1d2026;
  margin-bottom: 8px;
  line-height: 1;
}.review-item .review-data h5 span {
  font-size: 12px;
  color: #6e7485;
  margin-left: 20px;
  position: relative;
}.review-item .review-data h5 span::before {
  content: "";
  left: -13px;
  top: 6px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  background-color: #6e7485;
}.review-item .review-data .review {
  margin-bottom: 12px;
}.review-item .review-data .review ul {
  margin: 0;
  padding: 0;
  list-style: none;
}.review-item .review-data .review ul li {
  display: inline-block;
}.review-item .review-data p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #4e5566;
}.profile-wrap.course-instructor .profile-left .profile-data p a {
  color: #6e7485;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s;
}a.button.loadmore-btn {
  background: #122d4b;
  color: #fff;
  display: flex;
  width: max-content;
  padding: 14px 30px;
  text-decoration: none;
  gap: 20px;
  font-size: 16px;
  line-height: 24px;cursor: pointer;
  letter-spacing: -0.008em;
}
.text-right {
  text-align: right;
}.related-course-head a.brows-btn {
  padding: 14px 24px;
  display: inline-flex;
  align-items: center;
}
.related-course-head.row {
  margin-bottom: 40px;
}.related-course-section {
  border-top: 1px solid #e9eaf0;
  padding: 40px 0;
}

/****gift-card***/
.bredadcrumb-section {
  text-align: center;
  background-color: #f5f7fa;
  padding: 40px 0;
}

.bredadcrumb-section h3 {
  font-size: 24px;
  color: #1d2026;
  font-weight: 600;
  line-height: 32px;
  margin: 6px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
}



.breadcrumb-menu nav {
  display: flex;
  justify-content: center;
}

.section-padding {
  padding: 80px 0;
}
.course-gift-wrap {
  margin-right: 100px;
}.course-gift-wrap .section-title {
  text-align: left;
  margin-bottom: 30px;
}.course-gift-wrap .section-title h2 {
  font-size: 32px;
}.course-gift-wrap .contact-wrap {
  padding: 0;
  margin-bottom: 40px;
}
.course-gift-wrap .contact-wrap .contact-title {
  margin-bottom: 24px;
}.course-gift-wrap .contact-wrap .contact-title h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1d2026;
  margin: 0;
}
.form-selects label, .form-textarea label, .form-field-reply label, .form-field-price .field-wrapper .text-currencies, .form-field-price label, .form-field-social label, .form-field label {
  font-size: 14px;
  color: #1d2026;
  line-height: 22px;
  margin-bottom: 6px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
}.form-field .field-wrapper {
  overflow: hidden;
  position: relative;
}.form-field .field-wrapper input {
  padding-right: 60px;
}
.form-upload, .form-selects .bv_mainselect .bv_atual, .form-field-reply .field-wrapper input, .form-field-price .field-wrapper input, .form-field-social .field-wrapper input, .form-field .field-wrapper input, .form-searchbox input {
  width: 100%;
  height: 48px;
  font-size: 16px;
  color: #4e4e4e;
  line-height: 24px;
  padding: 12px 18px;
  text-transform: capitalize;
  transition: all 0.3s linear;
  border: 1px solid #e9eaf0;
}.plans .plan {
  cursor: pointer;
  margin-bottom: 24px;
}
.plans .plan input[type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
}

.plans .plan-content img {
  margin-right: 15px;
}
.plans .plan-content span {
  line-height: 1;
  margin-right: 24px;
  font-family: Inter;
}.plans .plan-content span:last-child {
  margin-right: 0;
}
.default-input-field input, .default-input-field textarea {
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 50px 10px 18px;
  transition: all 0.3s;
  border: 1px solid #e9eaf0;
  transition: all 0.3s;
}
.default-input-field textarea {
  height: 112px !important;
  resize: none;
}.course-gift-wrap .plans .plan-content img {
  height: auto;
}
.course-gift-wrap .plans .plan-content {
  display: flex;
  height: 48px;
  border: 1px solid #e9eaf0;
  position: relative;
  padding: 15px 18px;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #4e5566;
  transition: all 0.3s;
  background: #fff;
  border-radius: 0;
}
.course-gift-wrap small.plan-content.paypal-column strong {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #8c94a3;
  margin: 0;
  transition: all 0.3s;
}
.form-field-social .field-wrapper {
  position: relative;
}
.form-field-social .field-wrapper span.icon {
  top: 50%;
  left: 18px;
  position: absolute;
  padding-right: 12px;
  transform: translateY(-50%);
  border-right: 1px solid #e9eaf0;
  width: 31px;
}
.form-field-social .field-wrapper input {
  padding: 8px 18px;
  padding-left: 62px;
}
.form-field-social .field-wrapper span.icon img {
  width: 31px;
}
.amount-sidebar {
  border: 1px solid #e9eaf0;
}
.amount-sidebar .course-details-wrap {
  padding: 24px;
  border-bottom: 1px solid #e9eaf0;
}.amount-sidebar .course-details-wrap .course-details-item:last-child {
  margin-bottom: 0;
}
.amount-sidebar .course-details-wrap .course-details-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}.amount-sidebar .course-details-wrap .course-details-item .course-details-thumb {
  width: 100px;
  height: 75px;
  margin-right: 12px;
  flex: 0 0 auto;
}.amount-sidebar .course-details-wrap .course-details-item .course-details-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}.amount-sidebar .course-details-wrap .course-details-item .course-details-data p {
  font-size: 12px;
  line-height: 16px;
  color: #a1a5b3;
  margin-bottom: 2px;
}
.amount-sidebar .course-details-wrap .course-details-item .course-details-data p span {
  color: #4e5566;
}
.amount-sidebar .course-details-wrap .course-details-item .course-details-data .course-detalis-title {
  font-size: 14px;
  line-height: 22px;text-decoration: none;
  letter-spacing: -0.01em;
  color: #1d2026;
  margin-bottom: 12px;    margin-top: 12px;     display: block;
}
.amount-sidebar .course-details-wrap .course-details-item .course-details-data h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #122d4b;
}
.amount-sidebar .about-sidebar-wrap {
  padding: 24px;
}
.amunt-detalis h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1d2026;
  margin-bottom: 15px;
}
.amunt-detalis ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.amunt-detalis ul li {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #6e7485;
  line-height: 22px;
  padding-bottom: 13px;
}
.amunt-detalis ul li span {
  float: right;
  color: #1d2026;
  font-weight: 500;
}
.amunt-detalis ul li:last-child {
  border-bottom: 1px solid #e9eaf0;
}
.amunt-detalis h3 {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.01em;
  color: #202029;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.amunt-detalis h3 span {
  color: #202029;
  font-weight: normal;
  font-size: 16px;
}
.payment-btn {
  width: 100%;
  background: #122d4b;
  border-radius: 0;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  padding: 0 32px;
  line-height: 56px;
  letter-spacing: -0.01em;
  color: #fff;
  text-align: center;
  text-decoration: none;
  display: block;
}
