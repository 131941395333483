.about-section {border-bottom:1px solid #e9eaf0;}
.section-padding {padding:80px 0;}
.about-content {margin-right:100px;}
.about-content h3 {font-weight:600; font-size:80px; line-height:80px; letter-spacing:-0.02em; color:#e9eaf0; margin-bottom:15px;}
.about-content h2 {color:#1d2026; font-size:48px; line-height:52px; margin-bottom:24px; font-weight:600; letter-spacing:-0.02em;}
.about-content p {font-size:20px; line-height:32px; color:#6e7485;}
.company-content h3 {font-weight:600; font-size:32px; line-height:40px; letter-spacing:-0.01em; color:#1d2026; margin-bottom:20px;}
.company-content p {font-size:14px; line-height:22px; letter-spacing:-0.01em; color:#6e7485; margin-right:110px;}
.section-padding-bottom {padding-bottom:100px;}
.counter-wrap {display:flex; flex-wrap:wrap; justify-content:space-between;}
.counter-wrap .counter-item {display:flex; flex-wrap:wrap;}
.counter-wrap .counter-item .counter-icon {margin-right:16px;}
.counter-wrap .counter-item .counter-data .counter-number {font-weight:600; font-size:32px; line-height:40px; letter-spacing:-0.01em; color:#1d2026;}
.counter-wrap .counter-item .counter-data p {font-weight:500; font-size:14px; line-height:20px; letter-spacing:-0.01em; color:#4e5566;}
.our-mission-section {background:#ffeee8;}
.our-mission-content h5 {font-style:normal; font-weight:500; font-size:16px; line-height:22px; color:#ff6636;}
.our-mission-content h2 {font-weight:600; font-size:40px; line-height:48px; letter-spacing:-0.01em; color:#1d2026; margin-bottom:24px;}
.our-mission-content p {font-size:16px; line-height:24px; color:#4e5566;}
.testimonial-slider {display:flex; gap:2%;}
.testimonial-content {background:#f5f7fa; padding:80px 24px; text-align:center; position:relative;}
.testimonial-data {text-align:center; margin-top:35px;}
.testimonial-content:before {content:""; width:0; height:0; position:absolute; bottom:-22px; left:50%; transform:translateX(-50%); border-left:16px solid transparent; border-right:16px solid transparent; border-top:24px solid #f5f7fa;}
.testimonial-content p {font-size:18px; line-height:24px; letter-spacing:-0.015em; color:#1d2026;}
.testimonial-content .quote1 {position:absolute; top:35px; left:35px;}
.testimonial-content .quote2 {position:absolute; bottom:35px; right:35px;}
.testimonial-data h5 {font-weight:500; font-size:16px; line-height:22px; color:#1d2026;}
.testimonial-data p {font-size:14px; line-height:22px; letter-spacing:-0.01em; color:#6e7485;}
.testimonial-data p a {color:#6e7485;}
.section-title {text-align:center; margin-bottom:40px;}
.section-title h2 {font-weight:600; font-size:40px; letter-spacing:-0.01em; color:#1d2026;margin-bottom:20px;}
.branches-thumb {height:364px; position:relative;}
.branches-thumb img {width:100%; height:100%; object-fit:cover;}
.branches-data {text-align:center; background-color:#fff; padding:0px 31px 16px 31px; position:absolute; bottom:24px; width:calc(100% - 48px); margin-left:24px; transition:all 0.3s;}
.branches-data h5 {font-weight:500; font-size:12px; line-height:12px; text-transform:uppercase; color:#ff6636; transition:all 0.3s; opacity:0; margin:0 0 3px;}
.branches-data h4 {font-weight:500; font-size:16px; line-height:22px; color:#1d2026;}
.branches-data p {font-size:14px; line-height:22px; letter-spacing:-0.01em; color:#6e7485;}
.contact-section {background-color:#f5f7fa;}
.contact-info-wrap {margin-right:100px;}
.contact-info-item {border-bottom:1px solid #e9eaf0; display:flex; padding:30px 0;}
.contact-title h5 {font-size:24px; line-height:32px; color:#1d2026;}
.contact-info-item h5 {font-weight:500; font-size:16px; text-transform:uppercase; color:#122d4b; margin:0; flex:0 0 40%;}
.contact-info-item p {font-size:16px; line-height:24px; color:#1d2026;}
.contact-wrap {background:#ffffff; padding:40px;}
.contact-title {margin-bottom:30px;}
.contact-title h5 {font-size:24px; line-height:32px; color:#1d2026;}
.contact-title p {font-size:16px; line-height:24px; color:#8c94a3;}
.form-selects label, .form-textarea label, .form-field-reply label, .form-field-price .field-wrapper .text-currencies, .form-field-price label, .form-field-social label, .form-field label {font-size:14px; color:#1d2026; line-height:22px; margin-bottom:6px; letter-spacing:-0.01em; text-transform:capitalize;}
.form-field .field-wrapper {overflow:hidden; position:relative;}
.form-field .field-wrapper input {padding-right:60px;}
.contact-info-item p a {
    color: #1d2026;
    display: block;
}
.contact-us-section.career-page {
    border: none;
}.join-team-section {
    background: #f5f7fa;
}.join-team-thumb-wrap {
    display: flex;
}.join-team-thumb-wrap .join-team-thumb:nth-child(1) {
    width: 200px;
    height: 425px;
}.join-team-thumb-wrap .join-team-thumb:nth-child(2) {
    width: 200px;
    height: 521.83px;
}.join-team-thumb-wrap .join-team-thumb:nth-child(3) {
    width: 200px;
    height: 448px;
    margin-top: 74px;
}.join-team-thumb-wrap .join-team-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.join-team-thumb-wrap .join-team-thumb {
    margin-right: 24px;
}.join-team-content h2 {
    font-size: 40px;
    font-weight: 600;
    color: #1d2026;
    line-height: 48px;
    margin-bottom: 18px;
    letter-spacing: -0.01em;
}.join-team-content p {
    font-weight: 400;
    color: #4e5566;
}.join-team-content .checkbox-field ul {
    margin-top: 40px;
}.checkbox-field ul {
    margin: 0;
    padding: 0;
    list-style: none;
}.join-team-content .checkbox-field ul li {
    padding: 30px;
    margin-bottom: 32px;
    padding-left: 75px;
    background-color: #fff;
}.checkbox-field ul li {
    position: relative;
    padding-left: 35px;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: -0.01em;
    color: #4e5566;
    margin-bottom: 20px;
    line-height: 22px;
}.join-team-content .checkbox-field ul li svg {
    left: 30px;
    width: 30px;
    height: 30px;
}.checkbox-field ul li svg {
    position: absolute;
    left: 0;
}.benefit-package {
    border-bottom: 1px solid #e9eaf0;
}.gray-bg {
    position: relative;
    z-index: 0;
}
.gray-bg::before {
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 83%;
    z-index: -1;
    position: absolute;
    background-color: #f5f7fa;
}
.company-main-wrap {
    background-color: #fff;
    padding: 80px 80px 60px;
    border: 1px solid #e9eaf0;
    width: calc(100% + 160px);
    margin-left: -80px;
}.company-main-wrap {
    background-color: #fff;
    padding: 80px 80px 60px;
    border: 1px solid #e9eaf0;
    width: calc(100% + 160px);
    margin-left: -80px;
}.open-position-wrap {
    background: #ffffff;
    margin-bottom: 24px;
}

.open-position-header h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #1d2026;
    margin-bottom: 20px;
}.open-position-header .sub-heading-meta ul {
    display: flex;
}.open-position-header .sub-heading-meta ul li {
    display: flex;
    align-items: center;
}.sub-heading-meta ul li {
    margin: 0 8px;
    display: inline-block;
    font-size: 14px;
    letter-spacing: -0.01em;
    color: #4e5566;
}.open-position-header {
    padding: 24px;
    border-bottom: 1px solid #e9eaf0;
}.open-position-wrap .open-position-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
}.open-position-wrap .open-position-bottom p {
    font-family: "Inter";
    font-size: 14px;
    line-height: 22px; margin:0;
    letter-spacing: -0.01em;
    color: #4e5566;
}.open-position-wrap .open-position-bottom a.arrow-icon {
    display: flex;
    width: 48px;
    height: 48px;
    background: #ffeee8;
    align-items: center;
    justify-content: center;
}.open-position-wrap .open-position-bottom p span {
    color: #e34444;
}

.single-career-section {
    background: #f5f7fa;
}.single-career-herader .open-position-header {
    padding: 0;
    border: 0;
    margin-bottom: 40px;
}.single-career-herader .open-position-header .sub-heading-meta {
    margin-bottom: 12px;
}.open-position-header .sub-heading-meta ul {
    display: flex;
}.open-position-header .sub-heading-meta ul li {
    display: flex;
    align-items: center;
}.single-career-herader .open-position-header h2 {
    font-family: "Inter";
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.01em;
    color: #1d2026;
}.header-info-wrap {
    display: flex;
    align-items: center;
    gap: 150px;
}.header-info-wrap .header-info-item h4 {
    font-family: "Inter";
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #ff6636;
    margin-bottom: 8px;
}.header-info-wrap .header-info-item p, .header-info-wrap .header-info-item a {
    font-family: "Inter";
    font-size: 16px;
    line-height: 24px;
    color: #1d2026;
}.single-career-herader .open-position-header {
    padding: 0;
    border: 0;
    margin-bottom: 40px;
}.single-career-herader .open-position-header .sub-heading-meta {
    margin-bottom: 12px;
}.single-career-herader-right {
    width: 100%;
    text-align: right;
}
.course-list-section ul li {
    display: flex;
    gap: 10px;
}.single-career-body-wrap .single-career-herader-right {
    text-align: center;
}
.faq-header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    justify-content: space-between;
}.faq-header h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 1;
    letter-spacing: -0.01em;
    color: #1d2026;
}.custom-select-wrapper {
    position: relative;
    display: inline-block;
    user-select: none;
    cursor: pointer;
}.custom-select-wrapper select.custom-select.sources.form-select {
    border-radius: 0;
} 
.faq-left-sidebar {
    margin-right: 14px;
    border: 1px solid #e9eaf0;
    padding: 8px 0;
}.faq-left-sidebar .nav {
    display: block;
}.faq-left-sidebar .nav .nav-link.active {
    color: #fff!important;
    background-color: #122d4b !important;
}
.faq-left-sidebar .nav .nav-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #1d2026;
    display: block;
    padding: 12px 16px;
    width: 100%;
    border-radius: 0;
    text-align: left;
    transition: all 0.3s;
    border-bottom: 1px solid #e9eaf0;
}.faq-right-sidebar {
    background: #f5f7fa;
    padding: 20px;
    margin-left: 14px;
}.faq-right-sidebar h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #1d2026;
    margin-bottom: 8px;
}.faq-right-sidebar p {
    font-size: 12px;
    line-height: 16px;
    color: #6e7485;
    margin-bottom: 24px; 
}.sidebar-field {
    margin-bottom: 12px;
}.sidebar-field input, .sidebar-field textarea {
    width: 100%;
    height: 42px;
    background: #ffffff;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #8c94a3;
    border: none;
    padding: 10px 16px;
}
.sidebar-field textarea { 
    height: 120px;
    resize: none;
}
.faq-section .accordion button {
    background: #ffffff;
    border: 1px solid #e9eaf0;
    padding: 18px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    transition: all 0.3s;
    border-radius: 0 !important;
    box-shadow: none;
}



.faq-section .accordion-item {margin-bottom: 15px;border-radius: 0 !important;border: none;box-shadow: none !important;}