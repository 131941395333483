/* Calendar.css */

.calander {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: #f4f4f8; */
  padding: 10px 19px;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
  margin-bottom: 0px;
}
  
  .calander_left_section {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-right: 183px;
}
  
.calander_right_section button {
    background-color: #122D4B;
    border: none;
    color: white;
    font-size: 14pt;
    font-weight: 800;
    padding: 8px 17px;
    margin: 0 13px;
    border-radius: 8%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}
  
  .calander_right_section button:hover {
    background-color: #122e4b;
  }
  
  .calander_grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* 7 days a week */
    justify-items: center;
    text-align: center;
    padding: 10px;
    user-select: none;
  }
  
  .calander_grid span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 5px;
    background-color: #fff;
    color: #141313;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-weight: 800;
  }
  
  .calander_grid span:hover {
    background-color: #122d4b;
    color: white;
}
  
  .calander_grid span.empty {
    visibility: hidden;
  }
  