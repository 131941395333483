.instructor_profile {display:flex; background-color:#fff; align-items:center; justify-content:space-between;}
.profile-wrap.course-instructor.instructor_profile {border:none; padding:0; margin:0;}
.course-author .course-author-content {display:flex; align-items:center;}
.course-author .course-author-content .author-name {margin-top:auto;}
.course-author .course-author-content .author-name .author_ct-name {padding-top:inherit;}
.instructor_courses {row-gap:24px;}
.instructor-about-content {border:1px solid #E9EAF0; padding:24px;}
.instructor-about-content h4 {font-family:"Inter"; font-weight:500; font-size:18px; line-height:100%; text-transform:uppercase; color:#1d2026; margin-bottom:15px;}
.instructor-about-content p {font-family:"Inter"; font-size:14px; line-height:22px; letter-spacing:-0.01em; color:#6e7485; margin-bottom:20px;}
.about_information ul.nav-tabs {margin:0; padding:0; justify-content:flex-start; border:none; border-bottom:1px solid #e9eaf0;}
.about_information ul.nav-tabs li a {font-weight:500; font-size:16px; color:#4e5566; position:relative; transition:all 0.3s; padding:20px 38px; margin:0 auto; border-radius:0; white-space:nowrap; text-align:center; width:200px; border-width:0 0 3px 0; border-color:transparent;}
.about_information ul.nav-tabs li a.active {border-color:#1d2026; color:#1d2026; ;}
.share_list.profile-share {flex-wrap:wrap; text-align:right; justify-content:flex-end; align-content:end;}
.instructor-share {display:flex; gap:10px;}
.website-link a {display:flex; align-items:center; gap:10px; font-family:Inter; font-size:14px; font-weight:500; line-height:20px; letter-spacing:-0.01em; text-align:left;}
.contact-us-section {padding-top:16px; border-bottom:1px solid #ffddd1;}
.about-content.become-instructor {margin-right:0;}
.about-content.become-instructor h2 {font-size:64px; margin-bottom:32px; line-height:68px;}
.about-content.become-instructor p {font-size:24px; margin-bottom:40px; color:#6e7485;}
.counter-section.become-instructor {padding:45px 0; background:#ffeee8;}
.counter-item {display:flex; flex-wrap:wrap;}
.counter-item .counter-icon {margin-right:16px;}
.counter-item span.counter-value {font-weight:600; font-size:32px; line-height:40px; letter-spacing:-0.01em; color:#1d2026;}
.counter-item .counter-data p {font-weight:500; font-size:14px; line-height:20px; letter-spacing:-0.01em; color:#4e5566;}
.counter-icon img {width:40px; height:40px;}
.section-padding {padding:80px 0;}
.join-team-content.become-instructor {margin-left:45px;}
.join-team-content h2 {font-size:40px; font-weight:600; color:#1d2026; line-height:48px; margin-bottom:18px; letter-spacing:-0.01em;}
.join-team-content p {font-weight:400; color:#4e5566;}
.team-field ul {margin:0; margin-top:26px; padding:0; list-style:none;}
.team-field ul li {position:relative; padding:0; margin-bottom:24px; font-weight:normal; font-size:14px; letter-spacing:-0.01em; color:#4e5566; gap:15px; margin-bottom:20px; line-height:22px; display:flex;}
.team-field ul li svg {left:30px; width:30px; height:30px;}
.field-text span {font-size:18px; color:#1d2026; font-weight:500; line-height:24px; text-transform:capitalize; width:100%; margin-bottom:4px; display:inline-block;}
span.icon_fill-check img {width:30px; height:30px;}
.become-successful-instructor {background:#f5f7fa;}
.section-title {text-align:center; margin-bottom:40px;}
.section-title h2 {margin-bottom:20px;}
.section-title h2 {font-weight:600; font-size:40px; letter-spacing:-0.01em; color:#1d2026;}
.progress-content {padding:24px; text-align:center; transition:0.3s; background-color:#fff; margin-bottom:20px;}
.progress-content-left {height:80px; width:80px; line-height:80px; text-align:center; margin-bottom:24px; display:inline-block; background:rgba(86, 79, 253, 0.1);}
.progress-content-left img {transition:0.3s;}
.progress-content-right {align-self:center;}
.progress-content-right a {margin-bottom:6px; white-space:nowrap; display:inline-block; color:#1d2026; font-size:18px; color:#1d2026; font-weight:500; line-height:24px; text-decoration:none; text-transform:capitalize; width:100%;}
.progress-content-right p {font-size:14px; color:#6e7485; line-height:22px; letter-spacing:-0.01em; text-transform:capitalize;}
.progress-content:hover {box-shadow:0px 12px 32px rgba(29, 32, 38, 0.1);}
.instructor-rules__info h2 {font-size:40px; color:#1d2026; font-weight:600; line-height:48px; margin-bottom:20px; letter-spacing:-0.01em; text-transform:capitalize;}
.instructor-rules__info p {width:535px; font-weight:400; color:#6e7485; margin-bottom:14px;}
.instructor-rules__info ul {width:535px; margin:0 0 0 16px; padding:0;}
.instructor-rules__info ul li {font-size:16px; color:#1d2026; font-weight:400; padding-left:0px; margin-bottom:12px; position:relative;}
.help-center-section {background:#ffeee8;}
.join-team-content h2 {font-size:40px; font-weight:600; color:#1d2026; line-height:48px; margin-bottom:18px; letter-spacing:-0.01em;}
.join-team-content.help-center-content p {color:#6e7485;}
.join-team-content.help-center-content .course-list-section {margin:33px 0;}
.help-center-wrap {display:flex; align-items:center;}
.help-center-wrap .help-center-data p {font-weight:500; font-size:12px; line-height:12px; text-transform:uppercase; color:#8c94a3; margin-bottom:8px;}
.help-center-wrap a.help-center-icon {width:56px; height:56px; background:#ffffff; border-radius:100%; display:flex; align-items:center; justify-content:center; flex:0 0 auto; margin-right:16px;}
.help-center-wrap .help-center-data a {font-weight:500; font-size:18px; line-height:24px; color:#1d2026;}
.gallery-thumb {margin-left:100px;}
.our-mission-content.become-instructor {height:100%; display:flex; flex-direction:column; justify-content:center;}
.our-mission-content h2 {font-weight:600; font-size:40px; line-height:48px; letter-spacing:-0.01em; color:#1d2026; margin-bottom:24px;}
.our-mission-content.become-instructor p {font-size:20px; margin-bottom:32px; line-height:32px;}
.start-teaching {background-color:#1d2026; padding:100px 0;}
.footer-header-content {margin-right:202px;}
.footer-header-content h3 {color:#fff; margin-bottom:18px !important;}
.footer-header-content p {color:#b7bac7; font-size:16px; margin-bottom:40px; letter-spacing:normal;}
.footer-header.footer-header2 .footer-header-content a.footer-btn.orange-btn {line-height:64px; font-size:20px; padding:0 40px;}
.dateof-history {font-family:Inter; font-size:18px; font-weight:400; line-height:24px; letter-spacing:-0.015em; text-align:left; color:#1D2026; margin-bottom:12px;}
.history_list .accordion-item {border:1px solid #E9EAF0 !important; border-radius:0 !important; margin-bottom:24px;}
.history_list .accordion-item button.accordion-button {border-radius:0; padding:24px 68px 24px 24px;}
.history_list .accordion-item .accordion-button:not(.collapsed) {background:#fff;}
.history_list .accordion-body {padding:24px;}
.history-info {display:flex; align-items:center; gap:16px;}
.history_list .accordion-item .accordion-button:not(.collapsed) .dateof-history {color:#122d4b;}
.history-info span {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left; display:flex; align-items:center; gap:6px;}
.history-info span img {width:20px; height:20px;}
.history_list .accordion-body {padding:24px;}
.payment-block {display:flex; width:100%; height:100%; align-items:center; flex:100%; box-shadow:1px 0px 0px 0px #E9EAF0 inset; padding-left:50px;}
.payemnt-information {}
.purchse-course-list {display:flex; width:100%; align-content:center; gap:24px; flex-wrap:wrap;}
.course-item {gap:20px; display:flex; align-items:center; width:100%;}
.course-item .course-image {position:relative; flex:0 0 160px; min-height:120px; padding:initial;}
.course-detail-info {flex:0 0 50%;}
.course-reviews {display:flex; align-items:center; gap:6px; margin-bottom:8px;}
.course-reviews span.course-rating {font-family:Inter; font-size:14px; font-weight:500; line-height:20px; letter-spacing:-0.01em; text-align:left; color:#1D2026;}
.course-reviews svg {color:#FD8E1F; font-size:20px;}
span.reviews_people {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; color:#8C94A3; letter-spacing:-0.01em; text-align:left;}
.course_title a {font-family:Inter; font-size:16px; font-weight:500; line-height:22px; text-align:left; color:#1D2026; text-decoration:none;}
.course-detail-info .course_title {margin-bottom:45px;}
.coursebyauthor {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left;}
.coursebyauthor span {font-family:Inter; font-size:14px; color:#A1A5B3; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left;}
.payemnt-information .payment_info {display:flex; margin-top:24px; justify-content:space-between;}
.payemnt-information .infodate-time {font-family:Inter; font-size:24px; font-weight:400; line-height:32px; text-align:left; margin-bottom:12px;}
.payment_info span.cardonnername {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left; color:#1D2026;}
.payment_info span.card-number {font-family:Inter; font-size:18px; font-weight:400; line-height:24px; letter-spacing:-0.015em; text-align:left; color:#1D2026; flex:0 0 50%;}
.payment_info span.card-expiry {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:right;}
.history_list .accordion-item button.accordion-button:after {display:none;}
span.ac-toggle {position:absolute; right:20px; background:#E9EAF0; width:48px; height:48px; display:flex; align-items:center; justify-content:center;}
.payemnt-information {width:100%;}
.history_list .accordion-item .accordion-button:not(.collapsed) span.ac-toggle {background:#122d4b; transition:0.5s;}
.history_list .accordion-item .accordion-button span.ac-toggle img {transition:0.5s;}
.history_list .accordion-item .accordion-button:not(.collapsed) span.ac-toggle img {filter:brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(5392%) hue-rotate(171deg) brightness(140%) contrast(118%); transition:0.5s; transform:rotate(180deg);}
.ins-dasboard {background:#f5f7fa;}
.instructor-box {background:#111033; margin:24px 0; padding:40px; box-sizing:border-box;}
.instructor_info {display:flex; align-items:center; color:#fff; gap:15px;}
.instructor_image img {border-radius:100%;}
.step-info {display:flex; align-items:center; gap:24px; color:#fff;}
.activity-box {background:#f5f7fa; height:100%; border-radius:0;}
.instructor_content p {margin:0; font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; color:rgb(255 255 255 / 50%); text-align:left;}
.step-info span {font-family:Inter; font-size:14px; font-weight:500; line-height:20px; letter-spacing:-0.01em; text-align:right; width:100px; align-items:center;}
.step-info .line-bar {margin:0;}
.process_complete {font-family:Inter; font-size:16px; font-weight:600; line-height:22px; text-align:left; width:192px;}
.ins-dasboard main {background:#fff !important;}
.activity-box .card-header {display:flex; align-items:center; box-shadow:0px -1px 0px 0px #E9EAF0 inset; align-items:center; justify-content:space-between; padding:16px 20px;}
.activity-box .card-header h2.card-title {font-family:Inter; font-size:16px; font-weight:500; line-height:22px; text-align:left;}
.group-links {display:flex; justify-content:flex-end; gap:12px;}
.group-links a.down-link {background:#3e546c; text-align:center; display:flex; align-items:center; padding:11px;}
.group-links a.down-link img {filter:brightness(0) saturate(100%) invert(99%) sepia(100%) saturate(2%) hue-rotate(135deg) brightness(107%) contrast(101%);}
.cs-dropdown {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:right; color:#6E7485; text-decoration:none;}
.cs-dropdown {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:right; color:#6E7485; text-decoration:none;}
.card-body ul.activity {margin:0; padding:0; list-style:none;}
.activity-box .card-body {padding:0;}
.card-body ul.activity li {display:flex; gap:12px;}
.card-body ul.activity li .activity-icon {width:32px; height:32px; background:#122d4b; display:flex; align-items:center; justify-content:center; border-radius:100%; min-width:32px;}
.card-body ul.activity li {margin:12px 20px; margin-bottom:16px;}
.activity-info {}
.activity-info p {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left; margin-bottom:6px;}
.activity-info p span {font-weight:600;}
.activity-info span.time {background:none; padding:0; font-family:Inter; font-size:12px; font-weight:400; line-height:16px; text-align:left; color:#8C94A3;}
.revenu-div.activity-box {border:none; border-radius:0;}
.total-content-div.new-dit-add.activity-box {border:none; border-radius:0; border-radius:0; border:none;}
.btn.cs-dropdown {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:right; color:#6E7485; text-decoration:none;}
.student_wrapper {padding-bottom:40px;}
span.corse-rating svg {color:#FD8E1F; font-size:18px;}
.instructor-course h3.course_title {font-size:16px;}
.instructor_price {border-top:1px solid #E9EAF0; padding:16px 16px 14px; display:flex; align-items:center;}
.instructor_price .price_info {display:flex; align-items:center; gap:4px; flex:auto;}
.price_info span.sale_price {font-family:Inter; font-size:18px; font-weight:600; line-height:24px; text-align:left;}
span.regular_price {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; color:#A1A5B3; text-align:left; text-decoration:line-through;}
.edit_option .btn.cs-dropdown {display:flex; align-items:center;}
.edit_option .btn.cs-dropdown img {opacity:0.6;}
.edit_option .btn.cs-dropdown:after {display:none;}
.edit_option .dropdown-menu {box-shadow:0px 6px 16px 0px #0000000F; padding:17px 0 !important; width:200px; border-radius:0;}
.edit_option .dropdown-menu a.dropdown-item {font-family:Inter; font-size:14px; font-weight:400; transition:0.5s; line-height:22px; letter-spacing:-0.01em; text-align:left; padding:5px 18px;}
.edit_option .dropdown-menu a.dropdown-item:hover {background:#112b48; color:#fff; transition:0.5s;}
.message-area.instructor-message {background:#fff;}
.activity-box .cardbody {padding:22px 20px;}
.instructoru_reviews .rating-box.reviewsinf0 {margin:0; padding:20px; background:#FFF2E5;}
.instructoru_reviews-all.row {border-top:1px solid #E9EAF0; margin-top:22px; padding-top:20px;}
.instructoru_reviews-all .rating-area-percentage {flex:2;}
.activity-box .cardbody .recharts-wrapper {height:auto !important;}
.instructor-couse-detail {padding:24px; margin-bottom:24px;}
.ins-course-image {position:relative; padding-top:100%; display:block; height:100%;}
.ins-course-image img {position:absolute; top:0; height:100%; object-fit:cover; display:block;}
span.crc-info {font-family:Inter; font-size:12px; font-weight:400; line-height:16px; text-align:left; color:#8C94A3;}
span.crc-info span {color:#4E5566;}
.crc-top-head {display:flex; align-items:center; gap:16px; margin-bottom:12px;}
.inc-crs-content {}
.inc-crs-content h3 a {font-family:Inter; font-size:20px; font-weight:600; line-height:26px; text-align:left; color:#1D2026; text-decoration:none;}
.inc-crs-content h3 {margin-bottom:8px;}
.inc-crs-content p {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left; color:#6E7485;}
.inc-crs-content .course-author {padding-top:10px;}
.inc-crs-content .author_ct-name a {font-family:Inter; font-size:16px; font-weight:500; line-height:22px; text-align:left; color:#1D2026;}
.course-revenue {border-top:1px solid #E9EAF0; margin-top:24px; padding-top:24px;}
.insprice-block {gap:32px;}
.insprice-block span {display:block; font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left; color:#6E7485;}
.insprice-block .course_prices {padding-right:32px; border-right:1px solid #E9EAF0; font-family:Inter; font-size:20px; font-weight:400; line-height:32px; text-align:left;}
.insprice-block .course_earning {font-family:Inter; font-size:20px; font-weight:400; line-height:32px; text-align:left;}
.withdrewr_price {gap:12px;}
.withdrewr_price .edit_option .btn.cs-dropdown {display:flex; align-items:center; background:#F5F7FA; padding:11.5px; border-radius:0;}
.instructor_wrapper .topcategory {row-gap:24px;}
a.add-card-btn {display:flex; padding:32px 48px 32px 48px; gap:8px; border:2px dashed #d9dae0; justify-content:center; text-decoration:none; align-items:center; font-family:Inter; font-size:16px; font-weight:500; line-height:22px; text-align:left; color:#1D2026;}
.instructor_wrapper .row.revenue_row {row-gap:24px; margin-top:24px;}
.addnew_card {margin-top:34px;}
a.add-card-btn span {border:1.5px solid #FF6636; width:24px; display:flex; align-items:center; justify-content:center; color:#FF6636; border-radius:100%; height:24px;}
.card_cantrol {display:flex; margin-top:32px;}
.card_cantrol span.next-preview-link {flex:0 0 50%; display:flex; justify-content:flex-end; font-size:12px; gap:12px;}
span.dot-ct {flex:0 0 50%;}
.card_cantrol span.next-preview-link a.next-btn {transform:rotate(-180deg); opacity:0.6;}
span.dot-ct {display:flex; align-items:center; gap:8px;}
span.dot-ct span {background:red; display:block; width:12px; height:12px; border-radius:100%;}
span.dot-ct span:first-child {opacity:0.3;}
.course-gift-wrap.activity-box {margin:0;}
.cardbody table th {font-family:Inter; font-size:10px; font-weight:500; line-height:12px; text-align:left; padding:9px 20px; background:#F5F7FA; color:#6E7485;}
.cardbody table td {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left; padding:12px 20px;}
.course-gift-wrap.activity-box .cardbody h6 {font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left; color:#A1A5B3;}
.course-gift-wrap.activity-box .cardbody label.plan.complete-plan {margin-bottom:12px;}
.row.course-revenue {margin-top:24px;}
.cardbody button.view1-btn.ms-2 {border:0px !important; outline:none; box-shadow:none !important; padding:0 !important;}
.cs-dropdown::after {display:none;}
.instructor-main .instructor_wrapper {padding-bottom:40px;}
.dropdown-menu {box-shadow:0px 6px 16px 0px #0000000F; border-radius:0; border:1px solid #E9EAF0; width:200px; padding:8px 0px 8px 0px !important;}
.profile_form h2 {margin-bottom:24px;}
.profile_form .input-group-text {color:#122d4b; background:#fff; border-radius:0;}
.notofication_list .form-check label.form-check-label {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left; margin-bottom:16px; color:#1D2026;}
.notofication_list .form-check-input[type=checkbox] {width:18px; height:18px; border-radius:0;}
.instructor-profile-upload {background:#F5F7FA; padding:32px;}
.createtabs-list ul li {border:none !important;}
.createtabs-list ul li button.active {border-bottom-color:#FF6636 !important;}
.createtabs-list ul li button {font-family:Inter; font-size:16px; font-weight:500; line-height:22px; text-align:left; display:flex; gap:6px; padding:21px 20px; width:100%; border-bottom:2px solid transparent !important; border-width:0 0 3px 0 !important; color:#1D2026;}
.createtabs-list ul li, .createtabs-list ul {margin:0; padding:0;}
.createtabs-list ul {gap:24px; line-height:normal;}
.createtabs-list ul li {flex:1; line-height:normal; margin:0; padding:0 !important; display:flex;}
.createtabs-list ul li button span.check-icon {display:none;}
.createtabs-list ul li button span.tabs_text {flex:1 1; display:flex; gap:8px; color:#1D2026; align-items:center;}
.createtabs-list ul li button span.cnt-info {font-family:Inter; font-size:12px; font-weight:500; line-height:16px; text-align:right; color:#23BD33;}
.tab_head {box-shadow:0px -1px 0px 0px #E9EAF0 inset; padding:32px 40px; align-items:center;}
.step-area:first-child {padding-top:0;}
.inforow h4 {margin-bottom:16px;}
.tab_head h2 {font-family:Inter; font-size:24px; font-weight:600; line-height:32px; letter-spacing:-0.01em; text-align:left; margin:0;}
.createtabs-list ul li button span.tabs_text img {width:24px; height:24px;}
.form-select {height:48px; border-radius:0;}
a.btn.btn-light {padding:0 32px; display:flex; align-items:center; color:#6E7485; width:max-content; background:#fff; height:48px; border:1px solid #E9EAF0; justify-content:center; border-radius:0;}
.head-btn {align-items:center; gap:20px;}
.head-btn a {text-decoration:none;}
.head-btn a.btn-light.save-preview-btn {color:#122d4b;}
.upload-btn-wrapper {position:relative; overflow:hidden; display:inline-block; width:192px; height:48px;}
.upload-btn-wrapper button.btn {background:#FFEEE8; border-radius:0; padding:11px 25px; display:flex; gap:8px; font-family:Inter; font-size:16px; font-weight:600; letter-spacing:-0.008em; text-align:left; cursor:pointer;}
.upload-btn-wrapper input[type=file] {font-size:unset; position:absolute; left:0; top:0; opacity:0; width:100%; height:100%;}
.step-area {border-bottom:1px solid #E9EAF0; padding:32px 0;}
.step-area h4 {font-family:Inter; font-size:18px; font-weight:500; line-height:24px; text-align:left; margin:0; flex:1;}
.step-heading {padding-bottom:16px; display:flex; align-items:center;}
.step-area .step-heading {padding-top:32px;}
.step-area .step-heading {padding-top:32px;}
.step-heading a.add_linkrow {color:#FF6636; text-decoration:none; font-family:Inter; font-size:14px; font-weight:500; line-height:20px; letter-spacing:-0.01em; text-align:left;}
.tab_head {padding:32px 40px; align-items:center; border-bottom:1px solid #E9EAF0;}
.details_tabs .profile_form {padding-top:32px;}
.step-area .title_input {padding-bottom:24px;}
.step-area .title_input:last-child {padding-bottom:0;}
.comon-chart-globe .btn.cs-dropdown:after {display:none;}
.section-block .accordion {background:#F5F7FA; border:none;}
.section-block .accordion .accordion-item {border-radius:0; background:transparent; border:none;}
.section-block .accordion .accordion-item .accordion-button {border-radius:0 !important; padding:25px !important;}
.section-block .accordion .accordion-item .accordion-button:not(.collapsed) {background:none; box-shadow:none; border:none !important;}
.section-block .accordion .accordion-item h2 {margin:0; border:none;}
.section-block .accordion .course-itme-section {width:100%; display:flex; justify-content:space-between; align-items:center;}
.section-block .accordion .accordion button.accordion-button {padding:12px 20px !important; background:#fff !important;}
.course-itme-section .left-item {display:flex; font-family:Inter; font-size:16px; font-weight:500; line-height:22px; text-align:left; gap:12px; align-items:center;}
.section-block .accordion .accordion .right-item img {width:20px; height:20px;}
.course-itme-section .left-item .section_name {font-weight:400; line-height:24px;}
.section-block .accordion .right-item {gap:16px; align-items:center;}
.section-block .accordion .accordion-item button::after {display:none;}
.section-block .accordion .accordion-item .accordion-body {border:none !important; padding:0px 24px 24px;}
.section-block .accordion .accordion .accordion-item:not(:last-of-type) {margin-bottom:16px;}
::file-selector-button {display:none;}
::-webkit-file-upload-button {display:none;}
.form-control[type=file]:not(:disabled):not([readonly]) {cursor:pointer; line-height:34px;}
.right-item .cs-dropdown {font-family:Inter; font-size:14px; font-weight:600; background:#FFEEE8; letter-spacing:-0.004em; text-align:left; border-radius:0; transition:0.5s; color:#122d4b; padding:12px 48px 12px 16px; text-transform:capitalize;}
.right-item .cs-dropdown.btn:after {border:solid black !important; border-width:0 1px 1px 0 !important; padding:4px; content:""; display:block !important; position:absolute; transform:rotate(45deg); -webkit-transform:rotate(45deg); right:23px; top:15px; transition:0.5s;}
.right-item .cs-dropdown {position:relative;}
.section-block .accordion .right-item a:hover img {filter:brightness(0) saturate(100%) invert(14%) sepia(52%) saturate(755%) hue-rotate(169deg) brightness(94%) contrast(95%);}
.section-block .accordion .accordion .right-item img {width:20px; height:20px;}
.section-block .accordion .accordion .accordion-item .accordion-body {display:none;}
ul.dropdown-menu {box-shadow:0px 6px 16px 0px #0000000F; border-radius:0; border:1px solid #E9EAF0;}
ul.dropdown-menu li a {font-family:Inter; font-size:14px; font-weight:400; transition:0.5s; line-height:22px; letter-spacing:-0.01em; text-align:left; padding:5px 18px;}
ul.dropdown-menu li a:hover {color:#fff; background:#122d4b; transition:0.5s;}
.right-item .cs-dropdown.show:after {transform:rotate(-135deg); -webkit-transform:rotate(-135deg); top:20px; transition:0.5s;}
.addnew-section button.button.border-btn {width:100%;}
.modal-backdrop.show {opacity:0.8!important}
.modal .modal-content {border-radius:0;}
.modal .modal-content .modal-header {padding:16px 20px !important; box-shadow:0px -1px 0px 0px #E9EAF0 inset; border:none;}
.modal .modal-content .modal-body {padding:24px 22px;}
form .form-control {height:48px; border-radius:0; font-family:Inter; font-size:16px; font-weight:400; line-height:24px; color:#8C94A3; text-align:left;}
.form-select {border-radius:0;}
.modal-title {font-family:Inter; font-size:16px; font-weight:500; line-height:22px; text-align:left; color:#1D2026;}
label.form-label {font-family:Inter; font-size:14px; font-weight:400; line-height:22px; letter-spacing:-0.01em; text-align:left; color:#1D2026;}
.action-btn {padding-top:24px; display:flex; justify-content:space-between;}
button.btn.submit_btns {padding:0 24px; height:48px;}
.action-btn .btn {border-radius:0; padding:11px 24px; height:48px;}
button.btn.btn-cancel {background:#F5F7FA; transition:0.5s;}
button.btn.btn-cancel:hover {background:#122d4b; transition:0.5s; color:#fff;}
.action-btn button.btn.brows-btn {background:#122d4b; transition:0.5s;}
.input-group-text {background:#E9EAF0; font-family:Inter; font-size:16px; font-weight:600; line-height:48px; letter-spacing:-0.008em; text-align:left; color:#1D2026; border-radius:0;}
.formbold-file-input input {opacity:0; position:absolute; width:100%; height:100%;}
.formbold-mb-5.formbold-file-input {width:100%;}
.formbold-file-input label {position:relative; border-radius:0px; min-height:10px; display:flex; align-items:center; justify-content:center; padding:24px; text-align:center;}
.formbold-drop-file {display:block; color:#1D2026; font-family:Inter; font-size:16px; font-weight:500; line-height:22px; text-align:center;}
.modal-body form textarea {height:150px !important;}
.modal .modal-content .modal-body label {width:100%; margin-bottom:12px;}
.modal .modal-content .modal-body label.input-group-text {height:48px; border-radius:0 !important; width:auto;}
.formbold-or {font-weight:500; font-size:16px; color:#6b7280; display:block; margin-bottom:8px;}
.formbold-browse {font-family:Inter; font-size:14px; font-weight:400; color:#8C94A3; line-height:22px; letter-spacing:-0.01em; text-align:center;}
.formbold-browse span {color:#4E5566;}