/* Card.css */
.card {
    margin: 45px;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.card-icon {
    background-color: #f0f0f0;
    /*color: #FFD700;*/
    font-size: 24px;
    display: flex;
    justify-content: center;
    padding: 16px;
}

.card-content {
    padding: 16px;
    text-align: center;
}

.card-content .heading {
    font-size: 14pt;
}
.time {
    display: inline-block;
    background-color: #f0f0f0;
    padding: 4px 12px;
    border-radius: 20px;
    color: #333;
    font-weight: bold;
    margin: 6px 0;
}

.card-footer {
    background-color: #f0f0f0;
    padding: 12px;
    text-align: center;
}

.view-button {
    width: 100%; /* Makes the button extend to the full width of the card */
    padding: 12px 0; /* Increased padding for a better visual appeal */
    background-color: #122D4B;
    color: white;
    border: none;
    border-radius: 10px; /* Smoother border-radius */
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.view-button:hover {
    background-color: #8396ab;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Adds shadow for depth */
}


.courselist {
    list-style-type: none;
    padding: 0;
    margin-right: 38px !important;
}

.list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f8f8;
    margin: 5px;
    padding: 4px;
    border-radius: 8px;
}

.content {
  flex-grow: 1; /* Allows the content to fill the available space */
}

.header, .body, .footer {
  margin-bottom: 4px;
}

.header {
  font-size: 16px;
  font-weight: bold;
}

.body {
  font-size: 14px;
  margin:15px;
}

.footer {
    font-size: 10px;
    color: #666;
    font-weight: 800;
}

.ellipsis {
    margin-left: 10px;
    font-size: 30px;
    cursor: pointer;
}
.mainbody .success{
    font-size: 13pt;
    color: green;
}
.mainbody .danger{
    font-size: 13pt;
    color: red;
}
.mainbody .warning{
    font-size: 13pt;
    color: green;
}
/* Card.css - Update with following styles */
.photo-circles {
    position: relative;
    height: 40px;
    margin-left: -5px;
}

.circle {
    width: 40px; /* Circle size */
    height: 40px;
    border-radius: 50%;
    background-size: cover;
    position: absolute;
    border: 2px solid white; /* Optional: adds white border around images */
}

.circle:not(:first-child) {
    left: 30px; /* Adjust overlap */
}

.circle:nth-child(2) { left: 20px; } /* Additional overlap adjustment for second circle */
.circle:nth-child(3) { left: 40px; } /* Additional overlap adjustment for third circle */

/* Card.css - Update or add the following styles */
.coursesbody {
    border: 2px dashed #817d7d;
    padding: 0px;
    margin: 5px 0;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
}
